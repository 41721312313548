export const REQUEST_COMPANY_DETAIL = 'REQUEST_COMPANY_DETAIL'
export const SUCCESS_COMPANY_DETAIL = 'SUCCESS_COMPANY_DETAIL'
export const FAILURE_COMPANY_DETAIL = 'FAILURE_COMPANY_DETAIL'

// NOTIFICATION TYPE
export const SET_NOTIFICATION = 'SET_NOTIFICATION'
export const SET_TOKEN_FCM = 'SET_TOKEN_FCM'
export const REPLACE_DATA_NOTIFICATIONS = 'REPLACE_DATA_NOTIFICATIONS'

export const SET_COMPANY_LOGO = 'SET_COMPANY_LOGO'
