import theme from '@qlue/react-component/dist/styles/theme'
import { loginBackgroundQluework, logoQluework, qluedashboard } from 'commons/assets/images'

const ActivationShellProps = ({ queryParams }) => {
  const { device } = queryParams
  const gridStyle = {
    gridTemplateColumns: '1fr 550px',
    height: '100vh',
    backgroundColor: theme.primaryTextColor,
    color: '#000',
    position: 'relative'
  }

  const imgAttrs = {
    background: {
      src: loginBackgroundQluework,
      alt: 'background-login',
      width: '100%',
      height: '100%',
      style: {
        objectFit: 'contain',
        margin: 'auto',
        maxWidth: '500px'
      }
    },
    logo: {
      src: device === 'dashboard' ? qluedashboard : logoQluework,
      alt: 'logo-qlue',
      width: device === 'dashboard' ? '150px' : '80px'
    }
  }

  const divLogoAttrs = {
    className: 'tc',
    style: {
      width: '100%',
      position: 'absolute',
      bottom: '5rem'
    }
  }

  const mainAttrs = {
    style: {
      paddingLeft: '100px',
      paddingRight: '100px',
      backgroundColor: theme.primaryTextColor,
      margin: 'auto 0'
    },
    className: 'pa4'
  }

  return {
    gridStyle,
    imgAttrs,
    divLogoAttrs,
    mainAttrs
  }
}

export default ActivationShellProps
