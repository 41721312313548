import theme from '@qlue/react-component/dist/styles/theme'
import { loginBackground, qluedashboardHorizontalLogo, IconHome } from 'commons/assets/images'

const QLUEWORK_WEBSITE_URL = process.env.REACT_APP_QLUEWORK_BASE_URL

const LoginShellProps = () => {
  const gridStyle = {
    gridTemplateColumns: '550px 1fr',
    height: '100vh'
  }

  const imgAttrs = {
    background: {
      src: loginBackground,
      alt: 'background-login',
      width: '100%',
      height: '100%',
      style: {
        objectFit: 'cover'
      }
    },
    logo: {
      src: qluedashboardHorizontalLogo,
      alt: 'logo-qlue',
      width: '100%',
      height: '100%'
    },
    home: {
      src: IconHome,
      alt: 'logo-home',
      width: '100%',
      height: '100%'
    }
  }

  const divLogoAttrs = {
    logo: {
      style: {
        width: '250px',
        height: '40px'
      }
    },
    home: {
      style: {
        width: '40px',
        height: '40px'
      },
      href: QLUEWORK_WEBSITE_URL,
      target: '_blank',
      rel: 'noopener noreferrer'
    }
  }

  const mainAttrs = {
    style: {
      backgroundColor: theme.primaryBackground
    },
    className: 'pv4'
  }

  const childrenAttrs = {
    style: {
      paddingLeft: '100px',
      paddingRight: '100px',
      paddingTop: '100px'
    }
  }

  const flexAttrs = {
    style: {
      padding: '0 40px'
    },
    justifyContent: 'space-between'
  }

  return {
    gridStyle,
    imgAttrs,
    divLogoAttrs,
    mainAttrs,
    childrenAttrs,
    flexAttrs
  }
}

export default LoginShellProps
