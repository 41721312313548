import theme from '@qlue/react-component/dist/styles/theme'
import styled from 'styled-components'

export default {
  Wrapper: styled.div`
    position: fixed;
    z-index: 999999;
    bottom: 10px;
    right: 10px;
    border-radius: 4px;
    border: solid 2px #404040;
    width: 250px;
    opacity: 0.5;

    :hover {
      opacity: 1;
    }

    > .header {
      padding: ${props => (props.hide ? '5px 17px' : '17px')};
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: ${theme.primaryBackground};

      > .icons {
        display: flex;
        align-items: center;

        > .arrow-bottom {
          transform: ${props => (props.hide ? 'rotate(-90deg)' : 'rotate(90deg)')};
          width: 12px;
          height: 12px;
          cursor: pointer;
          margin-right: 5px;
        }

        > .icon-close {
          width: 9px;
          height: 12px;
          cursor: pointer;
        }
      }
    }

    > .list-request {
      background-color: ${theme.primaryTextColor};
    }
  `,
  Item: styled.div`
    padding: 17px;
    cursor: pointer;
    color: #000000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${theme.primaryTextColor};

    :hover {
      color: ${theme.primaryTextColor};
      background-color: ${theme.primaryBackgroundLightest};
    }

    > .item-name {
      width: ${props => (props.isLoading ? '100px' : '200px')};
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    > .progress-container {
      width: ${props => (props.isLoading ? '90px' : 'inherit')};
      > div:first-child {
        > div {
          max-width: 100%;
        }
      }

      > .icon-check {
        width: 14px;
        height: 14px;
      }
    }
  `
}
