const API = process.env.REACT_APP_API_URL_BASE_API

const LoginURL = `${API}/auth/login/dashboard`
const RegisterUser = `${API}/user`
const CheckEmailToken = `${API}/auth/email?token=`
const VerifyEmail = `${API}/user/verify-email`
const ForgotPassword = `${API}/user/password/forget/email`
const GetTokenFromRefreshToken = `${API}/auth/get-token/dashboard`

export default {
  LoginURL,
  RegisterUser,
  CheckEmailToken,
  VerifyEmail,
  ForgotPassword,
  GetTokenFromRefreshToken
}
