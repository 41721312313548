import axios from 'axios'

import stores from 'stores'

const state = stores.getState()
const instance = axios.create({
  timeout: -1
})

let isRefreshing = false
let refreshSubscribers = []

instance.interceptors.request.use(config => {
  if (state.login.token?.token) {
    config.headers.authorization = state.login.token.token
  }

  return config
})

instance.interceptors.response.use(
  response => response,
  error => {
    const { config, response } = error
    const originalRequest = config

    if (response && response.status === 498) {
      if (!isRefreshing) {
        // fetch token api
        isRefreshing = true
        fetch().then(newToken => {
          isRefreshing = false
          onRefreshed(newToken)
        })
      }
      const retryOrigReq = new Promise((resolve, reject) => {
        subscribeTokenRefresh(token => {
          // replace the expired token and retry
          originalRequest.headers['Authorization'] = 'Bearer ' + token
          resolve(axios(originalRequest))
        })
      })
      return retryOrigReq
    } else {
      return Promise.reject(error)
    }
  }
)

function subscribeTokenRefresh(cb) {
  refreshSubscribers.push(cb)
}

function onRefreshed(token) {
  refreshSubscribers.map(cb => cb(token))
}

export default instance
