import { TabShell } from 'shells'
import RenderPage from 'pages'
import Map from 'pages/Map'

export const publicRoutes = [
  {
    path: 'session',
    component: RenderPage
  },
  {
    path: 'login',
    component: RenderPage
  },
  {
    path: 'forgot-password',
    component: RenderPage
  },
  {
    path: 'activation-account',
    isPrivateToo: true,
    component: RenderPage
  },
  {
    path: 'activation-email',
    isPrivateToo: true,
    component: RenderPage
  }
]

export const privateRoutes = [
  {
    path: 'analytic',
    useTitle: 'Analytic',
    useTab: 'Analytic',
    component: TabShell,
    fetchTab: true,
    children: [
      {
        path: 'qluework',
        component: RenderPage,
        children: [
          {
            path: 'workgroup/:workgroupId',
            useTitle: 'Workgroup Detail',
            hasBackNavigation: true,
            component: RenderPage,
            liftingUp: 2
          }
        ]
      },
      {
        path: 'leaderboard',
        component: RenderPage
      },
      {
        path: 'Intrusion',
        component: RenderPage
      },
      {
        path: 'IP',
        component: RenderPage
      },
      {
        path: 'LPR',
        component: RenderPage
      },
      {
        path: 'FR',
        component: RenderPage
      },
      {
        path: 'PC',
        component: RenderPage
      },
      {
        path: 'VCC',
        component: RenderPage
      },
      {
        path: 'FMD',
        component: RenderPage
      },
      {
        path: 'VAD',
        component: RenderPage
      },
      {
        path: 'PPE',
        component: RenderPage
      },
      {
        path: 'PZC',
        component: RenderPage
      },
      {
        path: 'thermal',
        component: RenderPage
      },
      {
        path: 'thermal-camera',
        component: RenderPage
      },
      {
        path: 'power-monitoring',
        component: RenderPage
      },
      {
        path: 'bioniqa',
        component: RenderPage
      },
      {
        path: 'attendance',
        component: RenderPage,
        children: [
          {
            path: 'info',
            component: TabShell,
            useTab: 'AnalyticAttendance',
            useTitle: 'Attendance',
            liftingUp: 2,
            hasBackNavigation: true,
            children: [
              {
                path: 'detail',
                component: RenderPage
              },
              {
                path: 'status',
                component: RenderPage
              }
            ]
          },
          {
            path: 'line',
            component: TabShell,
            useTab: 'AnalyticAttendanceLine',
            useTitle: 'Attendance',
            liftingUp: 2,
            hasBackNavigation: true,
            children: [
              {
                path: 'attendance-status',
                component: RenderPage
              },
              {
                path: 'intime-statistic',
                component: RenderPage
              },
              {
                path: 'average-work-duration',
                component: RenderPage
              },
              {
                path: 'most-late-comers',
                component: RenderPage
              },
              {
                path: 'most-on-timers',
                component: RenderPage
              },
              {
                path: 'most-absence',
                component: RenderPage
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: 'workgroup',
    component: TabShell,
    useTab: 'Workgroup',
    useTitle: 'Workgroup',
    children: [
      {
        path: 'qluework',
        component: RenderPage
      },
      {
        path: 'qluework/create',
        component: RenderPage,
        useTitle: 'Create New Workgroup',
        hasBackNavigation: true,
        liftingUp: 1
      },
      {
        path: 'qluework/:detailId',
        useTab: 'WorkgroupDetail',
        component: TabShell,
        liftingUp: 1,
        children: [
          {
            path: 'detail',
            component: RenderPage
          },
          {
            path: 'task-list',
            component: RenderPage
          },
          {
            path: 'task-cctv',
            component: RenderPage
          },
          {
            path: 'list-of-participants',
            component: RenderPage
          },
          {
            path: 'cctv-rules',
            component: RenderPage
          },
          {
            path: 'report-citizen',
            component: RenderPage
          }
        ]
      },
      {
        path: 'qluework/:detailId/list-of-participants/add',
        useTitle: 'Assign Officers',
        hasBackNavigation: true,
        component: RenderPage,
        liftingUp: 1
      },
      {
        path: 'qluework/:detailId/cctv-rules/detail',
        hasBackNavigation: true,
        useTitle: 'CCTV Alert Detail',
        component: RenderPage,
        liftingUp: 1
      },
      {
        path: 'qluework/:detailId/cctv-rules/form',
        hasBackNavigation: true,
        useTitle: 'Create New CCTV Alert',
        component: RenderPage,
        liftingUp: 1
      },
      {
        path: 'task-list',
        component: RenderPage
      },
      {
        path: 'task-cctv',
        component: RenderPage
      },
      {
        path: 'task-citizen',
        component: RenderPage
      }
    ]
  },
  {
    path: 'map',
    component: Map,
    children: [
      {
        path: 'task',
        component: RenderPage
      },
      {
        path: 'task/:detailId',
        component: RenderPage
      },
      {
        path: 'tracking',
        component: RenderPage
      },
      {
        path: 'layer',
        component: RenderPage
      }
    ]
  },

  {
    path: 'settings',
    useTitle: 'Settings',
    useTab: 'Settings',
    fetchTab: true,
    component: TabShell,
    children: [
      {
        path: 'company-profile',
        component: RenderPage,
        children: [
          {
            path: 'info',
            liftingUp: 2,
            useTitle: 'Settings - Company Info',
            hasBackNavigation: true,
            component: RenderPage
          },
          {
            path: 'credential',
            liftingUp: 2,
            useTitle: 'Settings - Credential',
            hasBackNavigation: true,
            component: RenderPage
          },
          {
            path: 'plan',
            liftingUp: 2,
            useTitle: 'Settings - Plan',
            hasBackNavigation: true,
            component: RenderPage
          }
        ]
      },
      {
        path: 'category',
        component: RenderPage
      },
      {
        path: 'user',
        component: RenderPage,
        children: [
          {
            path: 'create',
            liftingUp: 2,
            useTitle: 'Create A New User',
            hasBackNavigation: true,
            component: RenderPage
          }
        ]
      },
      {
        path: 'layer',
        component: RenderPage
      },
      {
        path: 'smart-cctv',
        component: RenderPage
      },
      {
        path: 'smart-cctv/create',
        liftingUp: 1,
        useTitle: 'Add New CCTV',
        hasBackNavigation: true,
        component: RenderPage
      },
      {
        path: 'enrollment',
        component: RenderPage
      },
      {
        path: 'enrollment/:typeSite',
        liftingUp: 1,
        useTitle: ' ',
        hasBackNavigation: true,
        component: RenderPage
      },
      {
        path: 'enrollment/:typeSite/form',
        liftingUp: 1,
        useTitle: 'Add New Enrollment',
        hasBackNavigation: true,
        component: RenderPage
      },
      {
        path: 'smart-device',
        component: RenderPage,
        children: [
          {
            path: 'thermal',
            component: TabShell,
            useTab: 'SettingsThermal',
            useTitle: 'Settings - Thermal',
            liftingUp: 2,
            hasBackNavigation: true,
            children: [
              {
                path: 'device-list',
                component: RenderPage,
                children: [
                  {
                    path: 'request',
                    useTitle: 'Request Device',
                    hasBackNavigation: true,
                    liftingUp: 3,
                    component: RenderPage
                  }
                ]
              },
              {
                path: 'enrollment',
                component: RenderPage,
                children: [
                  {
                    path: 'create',
                    useTitle: 'Add New Enrollment',
                    hasBackNavigation: true,
                    liftingUp: 3,
                    component: RenderPage
                  }
                ]
              }
            ]
          },
          {
            path: 'dtc',
            component: TabShell,
            useTab: 'SettingsDTC',
            useTitle: 'Settings - DTC',
            liftingUp: 2,
            hasBackNavigation: true,
            children: [
              {
                path: 'device-list',
                component: RenderPage,
                children: [
                  {
                    path: 'request',
                    useTitle: 'Request Site',
                    hasBackNavigation: true,
                    liftingUp: 3,
                    component: RenderPage
                  }
                ]
              }
            ]
          },
          {
            path: 'bioniqa',
            component: TabShell,
            useTab: 'SettingsBioniqa',
            useTitle: 'Settings - Bioniqa',
            liftingUp: 2,
            hasBackNavigation: true,
            children: [
              {
                path: 'device-list',
                component: RenderPage,
                children: [
                  {
                    path: 'add',
                    useTitle: 'Add Device',
                    hasBackNavigation: true,
                    liftingUp: 3,
                    component: RenderPage
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        path: 'attendance',
        component: RenderPage,
        children: [
          {
            path: 'configuration',
            liftingUp: 2,
            useTitle: 'Attendance - Configuration',
            hasBackNavigation: true,
            component: RenderPage
          },
          {
            path: 'employee',
            liftingUp: 2,
            useTitle: 'Attendance - Employee',
            hasBackNavigation: true,
            component: RenderPage,
            children: [
              {
                path: 'create',
                useTitle: 'Add New Employee',
                hasBackNavigation: true,
                liftingUp: 3,
                component: RenderPage
              },
              {
                path: 'connect',
                useTitle: 'Connect Employee',
                hasBackNavigation: true,
                liftingUp: 3,
                component: RenderPage
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: 'livefeeds',
    component: RenderPage,
    noNavigate: true,
    children: [
      {
        path: '/',
        component: RenderPage
      },
      {
        path: ':type',
        liftingUp: 1,
        useTitle: ' ',
        hasBackNavigation: true,
        component: RenderPage,
        children: [
          {
            path: 'history',
            useTitle: ' ',
            liftingUp: 2,
            ignoreQueryParamsFile: true,
            hasBackNavigation: true,
            component: RenderPage,
            children: [
              {
                path: 'detail',
                usetitle: ' ',
                ignoreQueryParamsFile: true,
                hasBackNavigation: true,
                component: RenderPage
              }
            ]
          },
          {
            path: 'heatmap',
            useTitle: 'Heatmap Result',
            liftingUp: 2,
            ignoreQueryParamsFile: true,
            hasBackNavigation: true,
            component: RenderPage
          }
        ]
      }
    ]
  },
  {
    path: 'smartdevices',
    component: RenderPage,
    noNavigate: true,
    children: [
      {
        path: '/',
        component: RenderPage
      },
      {
        path: ':type',
        liftingUp: 1,
        useTitle: ' ',
        hasBackNavigation: true,
        component: RenderPage,
        children: [
          {
            path: 'history',
            useTitle: 'History',
            liftingUp: 2,
            hasBackNavigation: true,
            component: RenderPage
          }
        ]
      }
    ]
  },
  {
    path: 'attendance',
    useTitle: 'Attendance',
    useTab: 'Attendance',
    component: TabShell,
    children: [
      {
        path: 'realtime',
        component: RenderPage
      },
      {
        path: 'list',
        component: RenderPage,
        children: [
          {
            path: ':id',
            liftingUp: 1,
            useTitle: 'Recap Attendance | ',
            hasBackNavigation: true,
            component: RenderPage,
            children: [
              {
                path: 'recap',
                useTitle: 'Recap Attendance | ',
                liftingUp: 2,
                hasBackNavigation: true,
                component: RenderPage,
                tabWidth: '0%'
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: 'profile',
    useTitle: 'Profile',
    useTab: 'Profile',
    component: TabShell,
    children: [
      {
        path: 'info',
        component: RenderPage
      },
      {
        path: 'credential',
        component: RenderPage
      },
      {
        path: 'plan',
        component: RenderPage,
        children: [
          {
            path: 'change-plan',
            useTitle: 'Change Plan',
            hasBackNavigation: true,
            liftingUp: 2,
            component: RenderPage
          }
        ]
      }
    ]
  },
  {
    path: 'guide',
    component: RenderPage
  },
  {
    path: 'events',
    useTitle: 'Events',
    useTab: 'Events',
    component: TabShell,
    children: [
      {
        path: 'realtime',
        component: RenderPage
      },
      {
        path: 'alert-rules',
        component: RenderPage,
        children: [
          {
            path: 'create',
            liftingUp: 2,
            useTitle: 'Create Alert',
            hasBackNavigation: true,
            component: RenderPage
          },
          {
            path: 'detail',
            liftingUp: 2,
            useTitle: 'Detail',
            hasBackNavigation: true,
            component: RenderPage
          }
        ]
      }
    ]
  }
]
