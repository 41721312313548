const API = process.env.REACT_APP_API_URL_BASE_API

const GENERAL = `${API}/cctv-stream`
const THRESHOLD = id => `${GENERAL}/${id}/threshold`
const ANOMALY_TRAINING_DAYS = id => `${GENERAL}/${id}/anomaly-training-days`

export default {
  GENERAL,
  THRESHOLD,
  ANOMALY_TRAINING_DAYS
}
