export default (timestamp, to) => {
  const timestampStr = String(Math.round(timestamp))
  switch (to) {
    case 'seconds':
      return Number(timestampStr.slice(0, 10))
    case 'miliseconds': {
      if (timestampStr.length === 10) return Number(`${timestampStr}000`)
      else if (timestampStr.length > 13) return Number(timestampStr.slice(0, 13))
      return timestamp
    }
    default:
      return timestamp
  }
}
