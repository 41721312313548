/**
 * This file for initialize Firebase Cloud Messaging,
 * and this file side by side with public/firebase-messaging-sw.js
 */
import 'firebase/messaging'
import firebase from 'utils/firebase'
import store from 'stores'
import { SET_NOTIFICATION, SET_TOKEN_FCM } from 'stores/constants'

let messaging
if (firebase.messaging.isSupported()) messaging = firebase.messaging()

export function getTokenFCM() {
  return new Promise((resolve, reject) => {
    messaging
      .getToken()
      .then(token => resolve(token))
      .catch(err => reject(err))
  })
}

export async function setTokenFCM() {
  if (firebase.messaging.isSupported()) {
    const token = await getTokenFCM()
    store.dispatch({ type: SET_TOKEN_FCM, payload: token })
  }
}

;(async function setupNotification() {
  if (!('Notification' in window)) console.log('This browser not support for notification')
  else {
    if (Notification.permission === 'granted') setTokenFCM()
    // this method just worked as well in chrome
    if (Notification.permission !== 'denied' && firebase.messaging.isSupported()) {
      Notification.requestPermission().then(response => {
        if (response === 'granted') setTokenFCM()
      })
    }
  }
})()

if (firebase.messaging.isSupported()) {
  messaging.onMessage(payload => {
    const parseDetailData = JSON.parse(payload.data.data)
    const constructPayload = {
      ...payload.data,
      ...parseDetailData,
      readByWorkgroup: false,
      readByTab: false
    }

    store.dispatch({ type: SET_NOTIFICATION, payload: constructPayload })
  })

  // just in case when token is refresh, but its a rare case
  messaging.onTokenRefresh(() => messaging.getToken())
}
