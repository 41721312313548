/**
 * Helper function to round up decimal number to a certain length
 * @param {string | number} number The number that will be rounded up
 * @param {string} decimalLength Lenth of the returned value
 * @returns A number that have been rounded up
 *
 * @see https://stackoverflow.com/questions/11832914/how-to-round-to-at-most-2-decimal-places-if-necessary/19722641#19722641
 */
export default function roundNumber(number, decimalLength = '2') {
  return +(Math.round(number + `e+${decimalLength}`) + `e-${decimalLength}`)
}
