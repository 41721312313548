import React, {
  Suspense,
  lazy,
  useState,
  useEffect,
  useContext,
  useRef,
  useMemo,
  useCallback
} from 'react'
import DisplayGrid from '@qlue/react-component/dist/DisplayGrid'
import Carto from './Layer/Carto'
import {
  BaseMap,
  ZoomControl,
  MarkerCluster,
  Layers,
  FeatureGroup
} from '@qlue/react-component/dist/Map'
import { Conditional, ArrayMap } from '@qlue/react-component/dist/utils'
import MapsContainer from './styles'
import theme from '@qlue/react-component/dist/styles/theme'
import { usePrevious } from '@qlue/react-component/dist/hooks'
import useFetch from 'commons/hooks/useFetch'

import { useStore as useAppStore } from 'shells/AppShell'
import { LayersURL, ModuleURL } from 'commons/API'

import { GRID2, GRID3, position, layers } from './constants'
import { getLayersByOrderBatasAdmin } from './Layer/hardcode'
import '@qlue/react-component/dist/Map/styles.css'
import { checkLocationPromise } from './utils'
import PopUpLocation from './PopupLocation'
const MapContext = React.createContext()

const ToggleButton = lazy(() => import('@qlue/react-component/dist/ToggleButton'))
const BlockContainer = lazy(() => import('@qlue/react-component/dist/BlockContainer'))

const getTabId = {
  task: 1,
  tracking: 2,
  layer: 3
}

const MapView = ({ children, navigate, location: { pathname } }) => {
  const mapRef = useRef(null)
  const groupRef = useRef(null)
  const initActiveLayer = JSON.parse(localStorage.getItem('active_layer')) || {}

  const [detailIsExpanded, setdetailIsExpanded] = useState(false)
  const [rightbarIsExpanded, setRightbarIsExpanded] = useState(true)
  const [updateMap, setUpdateMap] = useState(false)
  const [markers, setMarkers] = useState([])
  const [activeLayers, setActiveLayers] = useState(initActiveLayer)
  const [mapMovesFlag, setMapMovesFlag] = useState(0)
  const [checkLocation, setCheckLocation] = useState(false)

  const {
    isLoading: isLoadingModule,
    data: { maps: mapModule }
  } = useFetch({
    url: ModuleURL.GetMapModule,
    initialFetch: true,
    initialState: { maps: [] }
  })

  const tabs = useMemo(
    () => mapModule.map((x, i) => ({ ...x, name: x.displayName, id: getTabId[x.alias] })),
    [mapModule]
  )

  useEffect(() => {
    if (!isLoadingModule && pathname === '/map') {
      const [firstTab = { alias: '' }] = tabs
      navigate(firstTab.alias)
    }
    // eslint-disable-next-line
  }, [isLoadingModule, pathname])

  const {
    isLoading: isLoadingLayer,
    data: { layers: listLayer }
  } = useFetch({
    url: LayersURL.GetListMap,
    initialState: { layers: [] }
  })

  const notSmartCCTVLayers = useMemo(
    () => listLayer.filter(l => l.name !== 'Smart CCTV' && l.name !== 'Smart Device'),
    [listLayer]
  )

  useEffect(() => {
    if (!isLoadingLayer) {
      const objActiveLayer = {}
      for (let i = 0; i < notSmartCCTVLayers.length; i++) {
        const { id } = notSmartCCTVLayers[i]
        objActiveLayer[id] = []
      }
      setActiveLayers(p => ({ ...p, objActiveLayer }))
    }
  }, [isLoadingLayer, listLayer, notSmartCCTVLayers])

  const { activeGrid } = useAppStore()
  const prevActiveGrid = usePrevious(activeGrid)
  const leftbarTriggered = prevActiveGrid !== activeGrid
  const leftbarIsExpanded = { [GRID2]: false, [GRID3]: true }[activeGrid]

  useEffect(() => {
    if (!leftbarIsExpanded || !rightbarIsExpanded) {
      setTimeout(() => mapRef.current.leafletElement.invalidateSize(), 1000)
    }
  }, [leftbarIsExpanded, rightbarIsExpanded, detailIsExpanded])

  const setNewBounds = useCallback(() => {
    if (updateMap) {
      setMapMovesFlag(state => state + 1)
    }
  }, [updateMap])

  const setTabMenu = label => {
    return navigate(label.alias)
  }

  const fitBounds = useCallback(() => {
    const map = mapRef.current.leafletElement
    const group = groupRef.current.leafletElement
    if (Object.keys(group.getBounds()).length > 0) {
      map.fitBounds(group.getBounds())
    }
  }, [])

  useEffect(() => {
    if (markers.length > 0 && !updateMap) {
      fitBounds()
    }
  }, [fitBounds, markers.length, updateMap])

  useEffect(() => {
    const data = checkLocationPromise()
    data.then(value => setCheckLocation(value))
  }, [])

  const blockContainerStyle = useMemo(
    () => ({
      background: theme.primaryBackground,
      position: 'relative',
      width: detailIsExpanded ? 505 : rightbarIsExpanded ? 260 : 0
    }),
    [detailIsExpanded, rightbarIsExpanded]
  )
  const handleLocation = () => {
    function success() {
      setCheckLocation(true)
    }
    function error(err) {
      setCheckLocation(false)
      alert({
        type: 'failed',
        msg: 'Your browser have denied location. Please allow location first to access Map'
      })
    }
    navigator.geolocation.getCurrentPosition(success, error)
  }
  return (
    <DisplayGrid gridTemplateColumns="1fr 1fr" overflow="hidden">
      <MapContext.Provider
        value={{
          fitBounds,
          setTabMenu,
          setdetailIsExpanded,
          updateMap,
          setUpdateMap,
          mapRef,
          activeLayers,
          setActiveLayers,
          listLayer,
          isLoadingLayer,
          isLoadingModule,
          mapModule,
          setMarkers,
          tabs,
          mapMovesFlag
        }}
      >
        {!checkLocation && <PopUpLocation handleLocation={handleLocation} />}
        <MapsContainer
          leftbarIsExpanded={leftbarIsExpanded}
          rightbarIsExpanded={rightbarIsExpanded}
          leftbarTriggered={leftbarTriggered}
          detailIsExpanded={detailIsExpanded}
        >
          <BaseMap
            ref={mapRef}
            onViewportChanged={setNewBounds}
            height="calc(100vh - 51px)"
            position={position}
            zoom={5}
            maxZoom={20}
            className="base-map"
          >
            <ArrayMap data={getLayersByOrderBatasAdmin(notSmartCCTVLayers)}>
              {({ id, name, subLayers }) => (
                <Carto
                  map={mapRef && mapRef.current}
                  name={name}
                  activeLayers={activeLayers[id] || []}
                  layers={subLayers}
                />
              )}
            </ArrayMap>
            <Layers position="bottomright" layers={layers} />
            <ZoomControl position="bottomright" />
            <FeatureGroup ref={groupRef}>
              <MarkerCluster markers={markers} />
            </FeatureGroup>
          </BaseMap>
        </MapsContainer>
        <Suspense fallback={<div />}>
          <BlockContainer style={blockContainerStyle}>
            <ToggleButton
              position="right"
              status={rightbarIsExpanded}
              onClick={() => setRightbarIsExpanded(state => !state)}
            />
            <Conditional if={rightbarIsExpanded}>{children}</Conditional>
          </BlockContainer>
        </Suspense>
      </MapContext.Provider>
    </DisplayGrid>
  )
}

export function useStore() {
  const store = useContext(MapContext)
  if (!store) throw new Error('Cannot using this store')

  return store
}

export default MapView
