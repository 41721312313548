import React from 'react'
import Styled from './styles'
import { ArrayMap } from '@qlue/react-component/dist/utils'
import { Text } from '@qlue/react-component/dist/QlueTypography'
import { dummyData } from './hardcode'
import { contactUs, contactUsEmail } from 'commons/assets/images'
import theme from '@qlue/react-component/dist/styles/theme'

function GetHelp() {
  function onClick(link) {
    return () => {
      window.open(link, '_blank')
    }
  }

  return (
    <Styled.Wrapper>
      <ArrayMap data={dummyData}>
        {({ id, name, imgUrl, content, link }) => (
          <Styled.MenuItem key={id} onClick={onClick(link)}>
            <div className="title mt2">
              <div className="first-section">
                <div className="logo-menu">
                  <img src={imgUrl} alt="logo" width="35px" height="35px" />
                </div>
                <div className="text-menu">
                  <Text className="fw5" color="#ffffff">
                    {name}
                  </Text>
                  <Text className="title-content ttc" color="#a0a1a2">
                    {content}
                  </Text>
                </div>
              </div>
            </div>
          </Styled.MenuItem>
        )}
      </ArrayMap>
      <Styled.MenuItem preventClick>
        <div className="title mt2">
          <div className="first-section">
            <div className="logo-menu">
              <img src={contactUs} alt="logo" width="35px" height="35px" />
            </div>
            <div className="text-menu">
              <Text className="fw5" color="#ffffff">
                Contact Us
              </Text>
              <div className="d-flex">
                <div className="d-flex mt2 ml3">
                  <div className="logo-detail mr1">
                    <img alt="logo" src={contactUsEmail} />
                  </div>
                  <Text className="underline" color="#ffffff">
                    beraniberubah@qlue.id
                  </Text>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Styled.MenuItem>
      <Text className="version db" color={theme.primaryTextColorTrans}>
        {`v${global.appVersion}`}
      </Text>
    </Styled.Wrapper>
  )
}

export default GetHelp
