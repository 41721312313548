import React, { useState, useMemo, useRef, useEffect } from 'react'
import { useLocation } from '@reach/router'
import { qluedashboard, qluedashboardLogo, help } from 'commons/assets/images'
import Styled from './styles'
import { IconNotification } from '@qlue/react-component/dist/QlueIcon'
import { Conditional } from '@qlue/react-component/dist/utils'
import Help from './Help'
import Notification from './Notification'
import GettingStarted from './GettingStarted'
import ExpiryNotification from './ExpiryNotification'
import useFetch from 'commons/hooks/useFetch'
import useEventSource from 'commons/hooks/useEventSource'
import { CompanyURL, EventURL, TrackingURL } from 'commons/API'
import { differenceInDays, differenceInMinutes, differenceInSeconds } from 'date-fns'
import { formatDigitEpoch } from 'utils'
import { generateElementID, MENU_PAGE_ID_PREFIX } from 'utils/generateElementID'
import { useStore } from 'shells/AppShell'
import { useSelector } from 'react-redux'

const GRID2 = 'SECOND_GRID'

function HeaderApp({
  grid,
  isAdmin,
  navigate,
  triggerHelp,
  setTriggerHelp,
  helpData,
  primaryButton,
  setPrimaryButton
}) {
  const { data: dataCompany, isLoading: isLoadingCompany } = useSelector(
    ({ companyDetail }) => companyDetail
  )
  const { token, companyId } = useSelector(state => state.login.token)
  const location = useLocation()
  const onlyIcon = grid === GRID2
  const [detailMenu, setDetailMenu] = useState('')
  const [detailData, setDetailData] = useState({})
  const [isAllRead, setIsAllRead] = useState(false)
  const buttonRef = useRef(null)
  const {
    showcase: { currentShowcase, setBounding }
  } = useStore()
  const isGetStartedShow = useMemo(() => currentShowcase?.id === 'get-started', [currentShowcase])
  const isExpired = useMemo(() => isLoadingCompany || +new Date() > dataCompany?.expiryDate, [
    dataCompany,
    isLoadingCompany
  ])

  // SSE
  const url = `${TrackingURL.GetLiveUser}?token=${token}`
  const { eventData } = useEventSource(url, `event_alert_${companyId}`)

  const {
    data: {
      expiry: { active }
    },
    isLoading
  } = useFetch({
    url: CompanyURL.TotalRequest,
    initialState: { expiry: { active: null } },
    throwError: false
  })

  const { data: notifData } = useFetch({
    url: EventURL.GetNotificationList,
    method: 'post',
    initialState: { data: {} },
    initialBody: { per_page: 10 }
  })

  useEffect(() => {
    const node = buttonRef.current
    if (node && isGetStartedShow) {
      setBounding({
        x: node.getBoundingClientRect().right - node.getBoundingClientRect().left + 20,
        y: node.getBoundingClientRect().y + node.getBoundingClientRect().height + 20
      })
    }
  }, [isGetStartedShow, setBounding])

  useEffect(() => {
    if (triggerHelp) {
      setTriggerHelp(false)
      setDetailMenu('help')
      setDetailData(helpData)
    }
  }, [triggerHelp, helpData, setTriggerHelp])

  // useEffect for handling flag notification
  useEffect(() => {
    if (notifData?.event_alerts?.length > 0) {
      const check = notifData.event_alerts.every(item => item.is_read === true)
      setIsAllRead(check ? false : true)
    }
  }, [notifData.event_alerts])

  // SSE
  useEffect(() => {
    if (Object.keys(eventData).length) {
      setIsAllRead(true)
    }
  }, [eventData, setIsAllRead])

  const { days, minutes, seconds } = useMemo(() => {
    if (!isLoading && active) {
      return {
        days: differenceInDays(
          formatDigitEpoch(active, 'miliseconds'),
          new Date().setHours(0, 0, 0, 0)
        ),
        minutes: differenceInMinutes(formatDigitEpoch(active, 'miliseconds'), new Date()),
        seconds: differenceInSeconds(formatDigitEpoch(active, 'miliseconds'), new Date())
      }
    }
    return { days: null, minutes: null, seconds: null }
  }, [isLoading, active])

  function onClose() {
    setDetailMenu('')
    setDetailData({})
    navigate(`${location.pathname}${location.search}`, { replace: true })
  }

  function onClickGuide() {
    setPrimaryButton(true)
    navigate('guide')
  }

  return (
    <>
      <Styled.FirstHeader onlyIcon={onlyIcon}>
        <img
          src={onlyIcon ? qluedashboardLogo : qluedashboard}
          alt="logo"
          id={generateElementID(MENU_PAGE_ID_PREFIX, 'logoQD')}
        />
      </Styled.FirstHeader>
      <Styled.MainHeader highlight={isGetStartedShow}>
        <Conditional if={detailMenu === 'help'}>
          <Help onClose={onClose} data={detailData} />
        </Conditional>
        <Conditional if={detailMenu === 'notification'}>
          <Notification onClose={onClose} setIsAllRead={setIsAllRead} />
        </Conditional>
        <Conditional if={detailMenu === 'gettingStarted'}>
          <GettingStarted isAdmin={isAdmin} onClose={onClose} />
        </Conditional>
        <ExpiryNotification
          dayLeft={days}
          minuteLeft={minutes}
          secondLeft={seconds}
          navigate={navigate}
        />
        <div className="right-side">
          <Styled.Button
            hide={!!isExpired}
            height="50px"
            primary={primaryButton}
            onClick={onClickGuide}
          >
            Guide
          </Styled.Button>
          <div
            ref={buttonRef}
            className="icon-button-help"
            role="presentation"
            onClick={() => setDetailMenu('help')}
          >
            <img src={help} alt="help" />
            <div className="text">Help</div>
          </div>
          <div
            className="notification"
            role="presentation"
            onClick={() => setDetailMenu('notification')}
          >
            <div className="icon-button-notif">
              <IconNotification />
            </div>
            <Conditional if={notifData.event_alerts && notifData.event_alerts.length && isAllRead}>
              <div className="dink" />
            </Conditional>
          </div>
        </div>
      </Styled.MainHeader>
    </>
  )
}

export default HeaderApp
