const API = process.env.REACT_APP_API_URL_BASE_API

const GENERAL = `${API}/cctv-agent`
const LIST = `${GENERAL}/list`
const CHECK_TOTAL = `${GENERAL}/check-total`

export default {
  GENERAL,
  LIST,
  CHECK_TOTAL
}
