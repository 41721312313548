import React from 'react'
import { useSelector } from 'react-redux'
import { BaseModal } from '@qlue/react-component/dist/Modal'
import BlockContainer from '@qlue/react-component/dist/BlockContainer'
import FlexContainer from '@qlue/react-component/dist/FlexContainer'
import { Text } from '@qlue/react-component/dist/QlueTypography'
import { expiredTime } from 'commons/assets/images'

function ModalExpired({ onClose }) {
  const { data: dataCompany } = useSelector(({ companyDetail }) => companyDetail)
  return (
    <BaseModal visible width="400px" height="auto" closeIcon onClose={onClose} padding="50px 20px">
      <FlexContainer flexDirection="column" alignItems="center" justifyContent="center">
        <BlockContainer className="mb3">
          <img src={expiredTime} alt="expired-time" />
        </BlockContainer>
        <Text className="fw5 fs-16px tc">
          {dataCompany?.createdFromSaaS
            ? 'Sorry! Your subscription plan has been expired. Please update your plan.'
            : 'Sorry! Your company has been expired. Please contact us'}
        </Text>
      </FlexContainer>
    </BaseModal>
  )
}

export default ModalExpired
