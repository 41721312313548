import { navigate } from '@reach/router'
import { useState, useMemo, useEffect } from 'react'
import { updateGuide } from 'utils'

export default ({ modalExpired }) => {
  const [hidden, setHidden] = useState(false)
  const [isLeft, setIsLeft] = useState(false)
  const [data, setData] = useState([[]])
  const [dataOrder, setDataOrder] = useState(0)
  const [subDataOrder, setSubDataOrder] = useState(0)
  const [bounding, setBounding] = useState(null)

  const current = useMemo(() => data[dataOrder][subDataOrder] || null, [
    data,
    dataOrder,
    subDataOrder
  ])
  const currentPage = useMemo(() => subDataOrder + 1, [subDataOrder])
  const lastPage = useMemo(() => data[dataOrder].length || null, [data, dataOrder])
  const isLast = useMemo(
    () => dataOrder === data.length - 1 && subDataOrder === data[data.length - 1].length - 1,
    [data, dataOrder, subDataOrder]
  )

  useEffect(() => {
    const mainNode = document.getElementById('main')
    if (!hidden && (current?.isScrollBottom || current?.isScrollTop)) {
      let mainHeight = current?.isScrollTop ? 0 : mainNode.offsetHeight
      mainNode.scrollTo(0, mainHeight)
    }
  }, [current, hidden])

  useEffect(() => {
    if (modalExpired) exit()
    // eslint-disable-next-line
  }, [modalExpired])

  function prev() {
    if (subDataOrder > 0) {
      setSubDataOrder(prev => prev - 1)
    } else if (subDataOrder === 0 && dataOrder > 0) {
      const newDataOrder = dataOrder - 1
      setDataOrder(newDataOrder)
      setSubDataOrder(data[newDataOrder].length - 1)
    }
  }

  function next() {
    if (subDataOrder !== data[dataOrder].length - 1) {
      setSubDataOrder(prev => prev + 1)
    } else {
      setDataOrder(prev => prev + 1)
      setSubDataOrder(0)
      if (current.navigateTo) {
        navigate(current.navigateTo)
        setHidden(false)
      }
    }
  }

  function exit(payload) {
    if (payload?.isTemporaryExit) {
      setHidden(true)
      next()
    } else if (!payload?.completed && dataOrder < data.length - 1) {
      setHidden(true)
      setDataOrder(dataOrder + 1)
      setSubDataOrder(0)
    } else {
      if (current?.completeAfterComplete) {
        updateGuide()
      }
      setHidden(false)
      setData([[]])
      setDataOrder(0)
      setSubDataOrder(0)
      setIsLeft(false)
      sessionStorage.removeItem('guide_steps')
    }
  }

  return {
    hidden,
    setHidden,
    current,
    bounding,
    setBounding,
    currentPage,
    lastPage,
    isLast,
    prev,
    next,
    exit,
    data,
    setData,
    isLeft,
    setIsLeft
  }
}
