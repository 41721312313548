const API = process.env.REACT_APP_API_URL_BASE_API

const GetAnalytics = `${API}/face/analytics`
const GetDetailAnalytics = `${API}/face/analytics/detail`
const GetNumSnapshots = `${API}/face/count/`
const GetEnrolledImages = id => `${API}/face/images/${id}`
const GetListHistory = `${API}/face/history`
const DownloadHistory = `${API}/face/history/download`

export default {
  GetAnalytics,
  GetDetailAnalytics,
  GetNumSnapshots,
  GetEnrolledImages,
  GetListHistory,
  DownloadHistory
}
