import { REQUEST_MODULE, SUCCESS_MODULE, FAILURE_MODULE } from './constant'
import API, { ModuleURL } from 'commons/API'
import { SET_MODULE_FEATURES } from './constant'

const getAdditionalFeatures = (name = '', roleId = '') => {
  const isAdmin = roleId === 'd2e7ae6a-37b5-4130-8c86-9ec3507d7071'
  switch (name) {
    case 'Workgroup':
      return {
        qluework: {
          button: {
            new: isAdmin
          },
          children: {
            detail: {
              button: {
                edit: isAdmin,
                destroy: isAdmin
              }
            },
            listOfParticipants: {
              button: {
                add: isAdmin
              },
              table: {
                isCheckbox: isAdmin
              }
            },
            taskList: {
              selectOption: !isAdmin,
              detail: {
                changeAssignee: isAdmin
              }
            }
          }
        }
      }
    case 'Analytic':
      return {
        qluework: {
          filterMyTask: !isAdmin
        }
      }
    default:
      return {}
  }
}

export default ({ roleId }) => {
  return {
    types: {
      request: REQUEST_MODULE,
      success: SUCCESS_MODULE,
      failure: FAILURE_MODULE
    },
    call: (dispatchSuccess, dispatch) =>
      API.get(ModuleURL.GetModule).then(async ({ data: { module } }) => {
        let dataModule = module
        try {
          const {
            data: { settings }
          } = await API.get(ModuleURL.GetSettingModule)
          dataModule = module.map(m => {
            if (settings.length) {
              if (m.name.toLowerCase() === 'settings') {
                return { ...m, path: `settings/${settings[0].alias}` }
              }
              if (m.name.toLowerCase() === 'events') {
                return { ...m, path: 'events/realtime' }
              }
            }
            return m
          })
        } catch (e) {
          // do nothing
        }
        const moduleFeatures = {}
        for (let i = 0; i < dataModule.length; i += 1) {
          const d = dataModule[i]
          moduleFeatures[d.name] = getAdditionalFeatures(d.name, roleId)
        }
        dispatch({ type: SET_MODULE_FEATURES, moduleFeatures })
        dispatchSuccess({ module: dataModule })
      })
  }
}
