const API = process.env.REACT_APP_API_URL_BASE_API

const GetAnalytics = `${API}/face-mask/analytics`
const GetDetailAnalytics = `${API}/face-mask/analytics/detail`
const GetNumSnapshots = `${API}/face-mask/count/`
const GetEnrolledImages = id => `${API}/face-mask/images/${id}`
const GetHistoryList = `${API}/face-mask/history`
const GetDetailHistory = `${GetHistoryList}/detail`

export default {
  GetAnalytics,
  GetDetailAnalytics,
  GetDetailHistory,
  GetNumSnapshots,
  GetEnrolledImages,
  GetHistoryList
}
