import {
  getInitReducerState as init,
  getFailedReducerState as fail,
  getSuccessReducerState as success,
  getRequestReducerState as request
} from 'utils/reducerUtils'
import { REQUEST_MODULE, SET_MODULE_FEATURES, SUCCESS_MODULE, FAILURE_MODULE } from './constant'

const initialState = {
  module: init({ data: [] }),
  moduleFeatures: {}
}

const loginReducer = (state = initialState, { type, ...payload }) => {
  switch (type) {
    case REQUEST_MODULE: {
      return {
        ...state,
        module: {
          isLoseConnection: false,
          ...state.module,
          ...request()
        }
      }
    }
    case SUCCESS_MODULE: {
      const { module } = payload.payload
      return {
        ...state,
        module: {
          ...state.module,
          ...success({ data: module })
        }
      }
    }
    case FAILURE_MODULE: {
      const { response, isLoseConnection } = payload
      return {
        ...state,
        module: {
          ...state.module,
          ...fail(response.data.message),
          errorCode: response.data.errorCode,
          isLoseConnection
        }
      }
    }
    case SET_MODULE_FEATURES: {
      const { moduleFeatures } = payload
      return { ...state, moduleFeatures }
    }
    default: {
      return state
    }
  }
}

export default loginReducer
