import {
  getInitReducerState as init,
  getFailedReducerState as fail,
  getSuccessReducerState as success,
  getRequestReducerState as request
} from 'utils/reducerUtils'
import {
  REQUEST_LOGIN,
  SET_TOKEN_LOGIN,
  SUCCESS_LOGIN,
  FAILURE_LOGIN,
  RESET_LOGIN
} from './constant'
import { getToken } from 'utils/auth'

const initialState = {
  auth: init({ user: null }),
  token: Object.keys(getToken()).length ? getToken() : null
}

const loginReducer = (state = initialState, { type, ...payload }) => {
  switch (type) {
    case REQUEST_LOGIN: {
      return {
        ...state,
        auth: {
          isLoseConnection: false,
          ...state.auth,
          ...request()
        }
      }
    }
    case SUCCESS_LOGIN: {
      const { user } = payload.payload
      document.cookie = `access-token-dashboard=${state.token.token}; path=/; domain=qlue.ai`
      document.cookie = `refresh-token-dashboard=${state.token.refreshToken}; path=/; domain=qlue.ai`
      return {
        ...state,
        auth: {
          ...state.auth,
          ...success({ user })
        }
      }
    }
    case FAILURE_LOGIN: {
      const { response, isLoseConnection } = payload
      return {
        ...state,
        auth: {
          ...state.auth,
          ...fail(response.data.message),
          isLoseConnection
        }
      }
    }
    case SET_TOKEN_LOGIN: {
      const { token } = payload
      return { ...state, token }
    }
    case RESET_LOGIN: {
      return {
        auth: init({ user: null }),
        token: null
      }
    }
    default: {
      return state
    }
  }
}

export default loginReducer
