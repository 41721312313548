import styled, { css } from 'styled-components'
import { BaseModal } from '@qlue/react-component/dist/Modal'
import theme from '@qlue/react-component/dist/styles/theme'

export default {
  Modal: styled(BaseModal)`
    > div:first-child {
      ${props =>
        props.bounding &&
        css`
          top: ${props => `${props.bounding.y}px`};
          ${props.isLeft
            ? css`
                left: ${props => `${props.bounding.x}px`};
              `
            : css`
                right: ${props => `${props.bounding.x}px`};
              `};
        `}
      ${props =>
        !props.bounding &&
        css`
          visibility: hidden;
        `};
      border: none;
      padding: 28px;
      z-index: 1000;
    }
  `,
  Dot: styled.div`
    width: 12px;
    height: 12px;
    border-radius: 100%;
    margin-right: 8px;
    background-color: ${props => (props.active ? theme.primaryColor : '#bbc5cb')};
  `
}
