import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { BaseButton } from '@qlue/react-component/dist/QlueButton'
import { Text } from '@qlue/react-component/dist/QlueTypography'
import theme from '@qlue/react-component/dist/styles/theme'
import Styled from '../styles'
import { CompanyURL } from 'commons/API'
import useFetch from 'commons/hooks/useFetch'

const ExpiryNotification = ({ dayLeft, minuteLeft, secondLeft }) => {
  const [{ isPending, invoiceUrl }, setPending] = useState({ isPending: false, invoiceUrl: '' })
  const auth = useSelector(({ login }) => login.token)
  const { data: dataCompany } = useSelector(({ companyDetail }) => companyDetail)

  const {
    isLoading: isLoadingBillingDetails,
    data: { billingDetails },
    refetch
  } = useFetch({
    url: CompanyURL.BillingDetails,
    initialState: { billingDetails: { billingHistory: [] } },
    initialFetch: false
  })

  useEffect(() => {
    if (dataCompany?.createdFromSaaS) refetch(true)
  }, [dataCompany, refetch])

  useEffect(() => {
    if (!isLoadingBillingDetails && !!billingDetails?.billingHistory?.length) {
      const { status, invoice_url } = billingDetails.billingHistory[0]
      if (status === 'Pending') setPending({ isPending: true, invoiceUrl: invoice_url })
      else setPending({ isPending: false, invoiceUrl: '' })
    }
  }, [isLoadingBillingDetails, billingDetails])

  const btnData = useFetch({
    url: CompanyURL.GetRenewSubscriptionBtn,
    initialState: {
      title: '',
      url: ''
    }
  })

  const button = () => {
    const data = () => {
      const payload = {
        redirectUrl: btnData?.data?.url || '',
        title: btnData?.data?.title || ''
      }
      if (isPending) {
        payload.redirectUrl = invoiceUrl
        payload.title = 'Pay Now'
      } else if (btnData.data.title === 'Choose Package') {
        payload.redirectUrl = `${btnData.data.url}?t=${auth.token}`
      } else payload.redirectUrl = btnData.data.url
      return payload
    }

    const { redirectUrl, title } = data()

    if (!btnData.isLoading && dataCompany?.createdFromSaaS && !isLoadingBillingDetails) {
      return (
        <a href={redirectUrl} target="_blank" rel="noopener noreferrer">
          <BaseButton primary height="32px">
            {title}
          </BaseButton>
        </a>
      )
    }

    return null
  }

  if (((dayLeft || dayLeft === 0) && dayLeft < 15) || isPending) {
    const isExpired = secondLeft <= 0
    const hour = Math.floor(minuteLeft / 60)
    const minute = minuteLeft % 60

    const textEnd = dayLeft
      ? `${dayLeft} day${dayLeft > 1 ? 's' : ''}`
      : `${hour} hour${hour > 1 ? 's' : ''} and ${minute} minute${minute > 1 ? 's' : ''}`

    const textExpired = dataCompany?.createdFromSaaS
      ? 'Your subscription plan has been expired'
      : 'Your company has been expired'

    const mainText = dataCompany?.packagePlan === 'Trial' ? 'trial' : 'subscription'
    const getText = () => {
      if (isPending) return 'Your payment is still pending'
      if (isExpired) return textExpired
      return `Your ${mainText} will end in ${textEnd}`
    }

    const getColor = () => {
      if (isPending) return theme.processStatusColor
      if (isExpired) return theme.waitStatusColor
      return theme.primaryTextColor
    }

    return (
      <Styled.ExpiryWrapper>
        <div className="ball" />
        <Text className="db text-expiry" color={getColor()}>
          {getText()}
        </Text>
        {button()}
      </Styled.ExpiryWrapper>
    )
  }

  return <div />
}

export default ExpiryNotification
