import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  span {
    margin: 3px 0px;
  }
  button {
    margin-top: 10px;
  }
`
export const Title = styled.div`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
`
