const API = process.env.REACT_APP_API_URL_BASE_API

const General = `${API}/project`
const QlueApp = `${API}/project/qlueapp`
const GetWorkgroupName = `${API}/project/name`
const WorkgroupParticipants = `${API}/project/participants`
const GetWorkgroupTask = id => `${API}/project/${id}/new-task`
const GetWorkgroupTaskCctv = id => `${API}/project/${id}/new-task-cctv`
const GetWorkgroupReportCitizen = id => `${API}/project/${id}/new-task/qlueapp`
const GetWorkgroupParticipants = id => `${API}/project/${id}/participants`
const GetUnassignedParticipants = id => `${API}/project/${id}/unassigned-participants`
const GetDownloadTask = id => `${API}/project/${id}/new-task/download`
const GetDownloadTaskCctv = id => `${API}/project/${id}/new-task-cctv/download`
const GetDownloadReportCitizen = id => `${API}/project/${id}/new-task-qlueapp/download`
const GetListCCTVRules = id => `${API}/project/${id}/cctv-rules`
const GetWorkgroupTaskList = `${API}/project/new-task/history`
const DownloadTaskHistory = `${API}/project/new-task/history/download`

export default {
  General,
  QlueApp,
  GetWorkgroupName,
  WorkgroupParticipants,
  GetWorkgroupTask,
  GetWorkgroupTaskCctv,
  GetWorkgroupReportCitizen,
  GetWorkgroupParticipants,
  GetUnassignedParticipants,
  GetDownloadTask,
  GetDownloadTaskCctv,
  GetDownloadReportCitizen,
  GetListCCTVRules,
  GetWorkgroupTaskList,
  DownloadTaskHistory
}
