import React, { useState, useEffect, useCallback, useContext, useReducer } from 'react'
import PropTypes from 'prop-types'
import { Link } from '@reach/router'
import { useSelector } from 'react-redux'
import { usePrevious } from '@qlue/react-component/dist/hooks'

import { UIShell } from '@qlue/react-component/dist/UIShell'
import MenuItem from '@qlue/react-component/dist/MenuItem'
import { IconNotification } from '@qlue/react-component/dist/QlueIcon'
import Sidebar from 'utils/Sidebar'
import { getCookie, getPath } from 'utils'
import { generateElementID, MENU_PAGE_ID_PREFIX } from 'utils/generateElementID'
import { dataSavedGettingStarted, getLinkId } from './utils'
import getModule from './stores/action'
import { ContainerMenu, Main } from './styles'
import useDispatch from 'commons/hooks/useDispatch'
import HeaderApp from './Components/Header'
import ModalStartTour from './Components/Showcase/ModalStartTour'
import ModalShowcase from './Components/Showcase/ModalShowcase'
import useShowcase from './hooks/useShowcase'
import ModalExpired from './Components/Main/ModalExpired'
import useGuide from './hooks/useGuide'
import ModalGuide from './Components/Guide/ModalGuide'
import { initialState, reducer } from './locals/reducer'
import RequestProgress from './Components/RequestProgress'
import useSSE from './hooks/useSSE'
import RightBar from 'utils/Rightbar'
import { setAuthStorage } from 'utils/auth'
import { bali } from 'commons/assets/images'
import { IS_BALI_COMPANY } from './hardcode'

const AppShellContext = React.createContext()

const GRID2 = 'SECOND_GRID'
const GRID3 = 'THIRD_GRID'

function useNotification(menuActive) {
  const notification = useSelector(({ notification }) => notification)
  const [isHidden, setHide] = useState(false)
  const prevLength = usePrevious(notification.data.length)
  const isWorkgroupMenu = menuActive.search(/workgroup/gi) !== -1

  useEffect(() => {
    if (isWorkgroupMenu) setHide(true)
    else {
      if (prevLength !== notification.data.length) setHide(false)
    }
  }, [isWorkgroupMenu, notification.data.length, prevLength])

  return {
    show: !isHidden ? notification.data.length > 0 : false
  }
}

const hardcode = {
  isAdmin: roleId => roleId === 'd2e7ae6a-37b5-4130-8c86-9ec3507d7071'
}

const AppShell = ({ children, location, navigate }) => {
  const { pathname } = location
  const checkPath = useCallback(() => {
    const gp = getPath(pathname)
    return gp
  }, [pathname])

  const getDispatchModule = useDispatch(getModule)
  const roleId = useSelector(({ login }) => login.token.roleId)
  const companyId = useSelector(({ login }) => login.token.companyId)
  const { data: module } = useSelector(({ module }) => module.module)

  const [modalExpired, setModalExpired] = useState(false)

  const [menuActive, setMenuActive] = useState(checkPath())
  const [activeGrid, setActiveGrid] = useState(GRID3)
  const [triggerHelp, setTriggerHelp] = useState(false)
  const [helpData, setHelpData] = useState(null)
  const [primaryButton, setPrimaryButton] = useState(false)

  const [rightBarOpen, setRightBarOpen] = useState(false)
  const [rightBarState, setRightBarState] = useState({})
  const [rightBarType, setRightBarType] = useState('')

  const notification = useNotification(menuActive)
  const guide = useGuide({ modalExpired })
  const {
    isLeft,
    currentShowcase,
    menusRef,
    startShowcase,
    replayShowcase,
    bounding,
    setBounding,
    currentPage,
    lastPage,
    isFirst,
    isLast,
    onClickStartTour,
    prevShowcase,
    nextShowcase,
    exitShowcase
  } = useShowcase({ navigate, module, roleId, modalExpired, setPrimaryButton })
  const [state, dispatch] = useReducer(reducer, initialState)
  const [additionalModule, setAdditionalModule] = useState({ qlueApp: false })
  useSSE({ dispatch, id: 'rpd-form-video', type: 'video', eventType: 'progress' })
  useSSE({ dispatch, id: 'rpd-form-geojson', type: 'geojson', eventType: 'progress' })
  useSSE({ dispatch, id: 'rpd-pothole-snapshot', eventType: 'rpd-snapshot', state })
  useSSE({ dispatch, id: 'rpd-validation', eventType: 'rpd-validation', state })

  /**
   * This effect is triggering when admin login page detail account menu on dashboard CRM qlue
   */
  useEffect(() => {
    const triggerStorage = getCookie(process.env.REACT_APP_TRIGGER_STORAGE)
    if (!!triggerStorage) {
      setAuthStorage({
        defaultPath: '',
        ...JSON.parse(triggerStorage)
      })
      window.location.reload()
    }
  }, [])

  useEffect(() => {
    const gettingStartedVersion = localStorage.getItem('gettingStartedVersion')

    if (gettingStartedVersion !== dataSavedGettingStarted.gettingStartedVersion) {
      localStorage.setItem('gettingStartedVersion', dataSavedGettingStarted.gettingStartedVersion)
      localStorage.setItem('savedGettingStarted', JSON.stringify(dataSavedGettingStarted))
    }
  }, [])

  function onCloseModalExpired() {
    setModalExpired(false)
    navigate('profile/plan')
  }

  function onTriggerHelp(payload) {
    setTriggerHelp(true)
    setHelpData(payload)
  }

  function onCloseRightBar(noCloseRightbar) {
    setRightBarState({})
    setRightBarType('')
    if (typeof noCloseRightbar === 'boolean' && !!noCloseRightbar) setRightBarType(true)
    else setRightBarOpen(false)
  }

  useEffect(() => {
    getDispatchModule({ roleId })
  }, [getDispatchModule, roleId])

  useEffect(() => {
    setMenuActive(checkPath())
  }, [checkPath])

  useEffect(() => {
    onCloseRightBar()
  }, [menuActive])

  const tree = module.map(({ path, name, image_url }) => {
    const isWorkgroupMenu = name.search(/workgroup/gi) !== -1
    return {
      component: ({ grid }) => {
        const onlyIcon = grid === GRID2
        const menuClassName = onlyIcon ? 'justify-center' : ''
        const text = grid === GRID3 ? name : null
        const menuName = name.toLowerCase().replace(/\s/g, '')
        const active =
          (menuActive === 'livefeeds' && menuName === 'smartcctv') ||
          menuActive.toLowerCase() === menuName
        return (
          <Link
            key={path + text}
            to={path}
            replace
            id={generateElementID(MENU_PAGE_ID_PREFIX, getLinkId(name))}
          >
            <ContainerMenu>
              <MenuItem
                ref={menusRef[name]}
                className={menuClassName}
                active={active}
                name={text}
                icon={image_url}
                rightIcon={notification.show && isWorkgroupMenu && <IconNotification hasNotif />}
                onClick={() => setMenuActive(name)}
                onlyIcon={onlyIcon}
              />
            </ContainerMenu>
          </Link>
        )
      }
    }
  })

  return (
    <AppShellContext.Provider
      value={{
        ...state,
        dispatch,
        activeGrid,
        menuActive,
        setModalExpired,
        onTriggerHelp,
        onLeaveGuide: () => setPrimaryButton(false),
        onEnterGuide: () => setPrimaryButton(true),
        isActiveGuide: primaryButton,
        showcase: {
          currentShowcase,
          currentPage,
          lastPage,
          isFirst,
          isLast,
          prev: prevShowcase,
          next: nextShowcase,
          exit: exitShowcase,
          setBounding,
          replayShowcase
        },
        guide,
        setActiveGrid,
        rightBarOpen,
        setRightBarOpen,
        setRightBarState,
        setRightBarType,
        additionalModule,
        setAdditionalModule,
        onCloseRightBar
      }}
    >
      {startShowcase && <ModalStartTour onClick={onClickStartTour} />}
      {modalExpired && <ModalExpired onClose={onCloseModalExpired} />}
      <UIShell
        onChangeGrid={grid => setActiveGrid(grid)}
        tree={tree}
        grid={activeGrid}
        className="h-90"
        AsideComponent={({ defaultClassName, RenderTree, tree: t, grid, changeGrid }) => {
          return (
            <Sidebar
              className={defaultClassName}
              RenderTree={RenderTree}
              tree={t}
              grid={grid}
              changeGrid={changeGrid}
              navigate={navigate}
              path={checkPath()}
            />
          )
        }}
        HeaderComponent={props => {
          return (
            <HeaderApp
              grid={props?.grid || ''}
              navigate={navigate}
              isAdmin={hardcode.isAdmin(roleId)}
              triggerHelp={triggerHelp}
              setTriggerHelp={setTriggerHelp}
              helpData={helpData}
              primaryButton={primaryButton}
              setPrimaryButton={setPrimaryButton}
            />
          )
        }}
      >
        {({ defaultClassName }) => (
          <>
            {currentShowcase && !currentShowcase.useTab && (
              <ModalShowcase bounding={bounding} isLeft={isLeft} />
            )}
            <RequestProgress requests={state.requests} dispatch={dispatch} />
            <Main
              id="main"
              className={`${defaultClassName} relative h-100 overflow-y-auto overflow-x-hidden`}
              rightBarOpen={rightBarOpen}
              // hotfix for modal detail events intrusion
              isEventsPage={window.location.pathname === '/events/realtime'}
            >
              {children}
              {IS_BALI_COMPANY(companyId) && (
                <img className="bali-accent" src={bali} alt="bali-accent" />
              )}
              <RightBar
                open={rightBarOpen}
                data={rightBarState}
                type={rightBarType}
                onClose={onCloseRightBar}
              />
            </Main>
            {guide.current && !guide.hidden && sessionStorage.getItem('current_guide') && (
              <ModalGuide />
            )}
          </>
        )}
      </UIShell>
    </AppShellContext.Provider>
  )
}

AppShell.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired
}

function useStore() {
  const store = useContext(AppShellContext)
  if (!store) throw new Error('Cannot using this store')

  return store
}

export { AppShell, AppShellContext, useStore }
