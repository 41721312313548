const API = process.env.REACT_APP_API_URL_BASE_API

const GENERAL = `${API}/road-pothole`
const UPLOAD_VIDEO = `${GENERAL}/upload/video`
const UPLOAD_GEOJSON = `${GENERAL}/upload/file`
const SUBMIT = `${GENERAL}/submit`
const HISTORY = `${GENERAL}/history`
const GET_UPLOAD_URL = `${GENERAL}/get-upload-url`

export default {
  GENERAL,
  UPLOAD_VIDEO,
  UPLOAD_GEOJSON,
  SUBMIT,
  HISTORY,
  GET_UPLOAD_URL
}
