/**
 * Function to compare passed string to 'People Counting', if matched return 'People Line Counting', else return itself
 * This is temporary function to change People Counting to People Line Counting with hardcoded way.
 *
 * @param {string} comparedString string to be compared to 'People Counting'.
 * @param {boolean} isAlias whether comparedString is engine alias or engine full name
 * @returns {string} 'People Line Counting' or comparedString
 */

const changePCToPLC = (comparedString, isAlias = false) => {
  let result = ''

  if (isAlias) {
    result = comparedString === 'PC' ? 'PLC' : comparedString
    return result
  }

  result = comparedString === 'People Counting' ? 'People Line Counting' : comparedString
  return result
}

export default changePCToPLC
