// dependency libraries
import React, { memo, forwardRef } from 'react'
import { IconDownload } from '@qlue/react-component/dist/QlueIcon'
import { Text } from '@qlue/react-component/dist/QlueTypography'
import theme from '@qlue/react-component/dist/styles/theme'

// root modules
import { IconQlueVisionAgent } from 'commons/assets/images'
import { getOS } from 'utils'
import { generateElementID, SETTING_SMART_CCTV_PREFIX } from 'utils/generateElementID'

// sibling modules
import {
  MAC_FILENAME_STG,
  WIN_FILENAME_STG,
  LNX_FILENAME_STG,
  MAC_FILENAME_PRD,
  WIN_FILENAME_PRD,
  LNX_FILENAME_PRD,
  MAC_HREF_STG,
  WIN_HREF_STG,
  LNX_HREF_STG,
  MAC_HREF_PRD,
  WIN_HREF_PRD,
  LNX_HREF_PRD
} from './constant'
import { ButtonWrapper } from './styles'

const isProduction = process.env.REACT_APP_NODE_ENV === 'production'

const generateQVADownloadText = OS => {
  return `Download QVA for ${OS}`
}

const defaultOS = {
  filename: isProduction ? WIN_FILENAME_PRD : WIN_FILENAME_STG,
  href: isProduction ? WIN_HREF_PRD : WIN_HREF_STG,
  text: generateQVADownloadText('Windows')
}

export const OS = {
  windows: {
    filename: isProduction ? WIN_FILENAME_PRD : WIN_FILENAME_STG,
    href: isProduction ? WIN_HREF_PRD : WIN_HREF_STG,
    text: generateQVADownloadText('Windows')
  },
  mac: {
    filename: isProduction ? MAC_FILENAME_PRD : MAC_FILENAME_STG,
    href: isProduction ? MAC_HREF_PRD : MAC_HREF_STG,
    text: generateQVADownloadText('Mac')
  },
  linux: {
    filename: isProduction ? LNX_FILENAME_PRD : LNX_FILENAME_STG,
    href: isProduction ? LNX_HREF_PRD : LNX_HREF_STG,
    text: generateQVADownloadText('Linux')
  }
}

const ButtonDownloadQlueVision = forwardRef(({ className = '', ...props }, ref) => {
  const key = getOS()
  if (!OS[key])
    return (
      <a
        download={defaultOS.filename}
        href={defaultOS.href}
        className={`${className} mr2`}
        ref={ref}
        {...props}
      >
        <ButtonWrapper onClick={() => null} width="200px" rounded>
          <IconDownload color={theme.primaryColor} />{' '}
          <Text id={generateElementID(SETTING_SMART_CCTV_PREFIX, 'buttondownloadqva')}>
            {defaultOS.text}
          </Text>
        </ButtonWrapper>
      </a>
    )
  return (
    <a
      download={OS[key].filename}
      href={OS[key].href}
      className={`${className} mr2`}
      ref={ref}
      {...props}
    >
      <ButtonWrapper onClick={() => null} width="200px" rounded src={IconQlueVisionAgent}>
        <Text id={generateElementID(SETTING_SMART_CCTV_PREFIX, 'buttondownloadqva')}>
          {OS[key].text}
        </Text>
      </ButtonWrapper>
    </a>
  )
})

export default memo(ButtonDownloadQlueVision)
