import { createStore, applyMiddleware, compose } from 'redux'
import { createLogger } from 'redux-logger'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import thunk from './middlewares/thunk'
import apiMiddleware from './middlewares/apiMiddleware'
import rootReducers from './reducers'

let middlewares = [thunk, apiMiddleware]
const enhancers = []
const logger = createLogger({ collapsed: true })
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['unhandled']
}

if (process.env.NODE_ENV !== 'production') {
  middlewares.push(logger)

  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__

  if (typeof devToolsExtension === 'function') enhancers.push(devToolsExtension())
}

const composedEnhancers = compose(applyMiddleware(...middlewares), ...enhancers)
const persistedReducer = persistReducer(persistConfig, rootReducers)
const store = createStore(persistedReducer, {}, composedEnhancers)

export const persistor = persistStore(store)
export default store
