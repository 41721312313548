const validateAction = action => {
  const { types, call } = action
  const expectedTypes = ['request', 'success', 'failure']

  if (
    Object.values(types).length !== 3 ||
    !Object.keys(types).every(type => expectedTypes.includes(type)) ||
    !Object.values(types).every(type => typeof type === 'string')
  ) {
    throw new Error(
      'Expected action.types to be an object/dict with three keys' +
        '(request, success and failure), and the values should be strings.'
    )
  }

  if (typeof call !== 'function') {
    throw new Error('Expected `call` to be a function.')
  }
}

function apiMiddleware({ dispatch, getState }) {
  return next => action => {
    const { types, call, shouldCall = () => true, extraData = {} } = action

    if (!types) {
      // if this is a normal use of redux, we just pass on the action
      return next(action)
    }

    // here, we validate the dependencies for the middleware
    validateAction(action)

    // then, to prevent accidental api calls,
    // we can check the state before calling
    if (!shouldCall(getState(), action)) {
      return new Promise(() => {}) // so we can still call .then when we dispatch
    }

    // we dispatch the request action, so the interface can react to it
    dispatch({ extraData, type: types.request })

    // at last, we return a promise with the proper api call
    const dispatchSuccess = payload => dispatch({ type: types.success, payload })
    call(dispatchSuccess, dispatch).catch(error => {
      const { response } = error
      if (!navigator.onLine) {
        dispatch({
          type: types.failure,
          isLoseConnection: true,
          error,
          response: { data: { message: 'connection lose' } }
        })
      } else if (response.data && response.data.errorCode === 8) {
        const newResponse = {
          ...response,
          data: {
            ...response.data,
            message: `Sorry, you don't have permission to access this page`
          }
        }

        dispatch({ extraData, error, response: newResponse, type: types.failure })
        // check if company is expired auto logout
      } else dispatch({ extraData, error, response, type: types.failure })
    })
  }
}

export default apiMiddleware
