import { logo } from 'commons/assets/images'

export const dummyData = [
  {
    id: '9',
    name: 'Thermal CCTV Analytics',
    subName: 'April 14, 2021 - Version 2.4.1',
    imgUrl: logo,
    data: [
      {
        title: 'Features',
        contents: ['Thermal CCTV analytics implementation']
      },
      {
        title: 'Fixed Issues',
        contents: ['Currency in review change plan', 'Date filter on each engine history list']
      }
    ]
  },
  {
    id: '8',
    name: 'FR & Thermal History Enhancement',
    subName: 'April 09, 2021 - Version 2.4.0',
    imgUrl: logo,
    data: [
      {
        title: 'Features',
        contents: ['Grid view implementation on FR history']
      },
      {
        title: 'Improvements',
        contents: [
          'FR history list with review status',
          'Thermal history list with similarity score'
        ]
      }
    ]
  },
  {
    id: '7',
    name: 'Upgrade/Change Plan',
    subName: 'April 09, 2021 - Version 2.3.0',
    imgUrl: logo,
    data: [
      {
        title: 'Features',
        contents: [
          'Update or Change Plan Implementation',
          'Showing current & selected plan that user subscribes',
          'Review change plan, confirmation page about the engine changes & the prices'
        ]
      },
      {
        title: 'Fixed Issues',
        contents: ['Date filter on task from cctv']
      }
    ]
  },
  {
    id: '6',
    name: 'Thermal Enrollment Bugfixed',
    subName: 'March 24, 2021 - Version 2.2.5',
    imgUrl: logo,
    data: [
      {
        title: 'Fixed Issues',
        contents: ['Add and edit Thermal Enrollment', 'Setting tab menu based on user plan']
      }
    ]
  },
  {
    id: '5',
    name: 'Thermal Enrollment Improvement',
    subName: 'March 24, 2021 - Version 2.2.4',
    imgUrl: logo,
    data: [
      {
        title: 'Improvements',
        contents: [
          'Capable to select up to ten enrollment photos',
          'Add type filter based on detection image'
        ]
      }
    ]
  },
  {
    id: '4',
    name: 'Introducing QlueVision Agent',
    subName: 'January 22, 2021 - Version 2.2.2',
    imgUrl: logo,
    data: [
      {
        title: 'Features',
        contents: ['Download QlueVision Agent']
      },
      {
        title: 'Improvements',
        contents: [
          'Add CCTV Enhancement',
          'Moved Enrollment',
          'Publish Documentation or User Manual'
        ]
      }
    ]
  },
  {
    id: '3',
    name: 'Setup your Plan',
    subName: 'December 16, 2020 - Version 2.2.1',
    imgUrl: logo,
    data: [
      {
        title: 'Features',
        contents: ['Plan', 'Billing']
      },
      {
        title: 'Improvements',
        contents: ['Plan based on your subscribtion', 'Billing Detail']
      }
    ]
  },
  {
    id: '2',
    name: 'Ready to Onboard!',
    subName: 'November 09, 2020 - Version 2.2.0',
    imgUrl: logo,
    data: [
      {
        title: 'Features',
        contents: ['Onboarding User']
      },
      {
        title: 'Improvements',
        contents: [`First time login & let's start the tour!`, 'Get Started', 'Help Center']
      }
    ]
  },
  {
    id: '1',
    name: 'Face Recognition for Thermal',
    subName: 'October 26, 2020 - Version 2.1.05',
    imgUrl: logo,
    data: [
      {
        title: 'Features',
        contents: [
          'Real time Thermal data Implementation based on devices engine (FR/Non-FR)',
          'Engine mode (FR/Non-FR) Filter Implementation for Thermal History'
        ]
      },
      {
        title: 'Improvements',
        contents: ['Add new enrollment from thermal history', 'Setting & List of Enrollment']
      },
      {
        title: 'Fixed Issues',
        contents: [
          'Time zone for Thermal Real Time Data & Analytics',
          'Temperature detected hover on thermal analytics'
        ]
      }
    ]
  }
]
