import useResilientDOMMutation from 'commons/hooks/useResilientDOMMutation'
import React, { useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import './google-translate.css'
import { generateElementID } from 'utils/generateElementID'

function MultiLanguage() {
  useResilientDOMMutation()
  const authIsLoaded = useSelector(({ login }) => login.token?.token)
  const pathName = window.location.pathname
  const [isGTranslateCreated, setIsGTranslateCreated] = useState(false)

  const getClassName = useCallback(() => {
    if (pathName.search(/activation-/i) !== -1 || !authIsLoaded) return 'bottom-left'
    return ''
  }, [authIsLoaded, pathName])

  useEffect(() => {
    if (!isGTranslateCreated) {
      const googleTranslateElementInit = () =>
        new window.google.translate.TranslateElement(
          {
            pageLanguange: 'en',
            includedLanguages: 'ja,en',
            layout: window.google.translate.TranslateElement.FloatPosition.TOP_LEFT,
            autoDisplay: false
          },
          'google_translate_element'
        )
      setIsGTranslateCreated(true)
      var addScript = document.createElement('script')
      addScript.setAttribute(
        'src',
        '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
      )

      addScript.async = true
      document.body.appendChild(addScript)
      window.googleTranslateElementInit = googleTranslateElementInit
    }
  }, [isGTranslateCreated])

  const resetLang = () => {
    var iframe = document.getElementsByClassName('goog-te-banner-frame')[0]
    if (!iframe) return

    var innerDoc = iframe.contentDocument || iframe.contentWindow.document
    var restore_el = innerDoc.getElementsByTagName('button')

    for (var i = 0; i < restore_el.length; i++) {
      if (restore_el[i].id.indexOf('restore') >= 0) {
        restore_el[i].click()
        var close_el = innerDoc.getElementsByClassName('goog-close-link')
        close_el[0].click()
        return
      }
    }
  }

  useEffect(() => {
    // route change, we should add timeout for wait the GTranslate Element
    if (document.readyState === 'complete') {
      setTimeout(() => {
        const gTranslateSelect = document.getElementsByClassName('goog-te-combo')[0]

        if (gTranslateSelect) {
          gTranslateSelect.addEventListener('change', () => getSelectedLang())

          const getSelectedLang = () => {
            const selectedOption = document.getElementsByClassName('goog-te-combo')[0].value
            if (selectedOption === 'en') {
              resetLang()
              window.location.reload()
            }
          }
        }
      }, 2000)
    }

    // hard refresh
    else {
      window.addEventListener('load', function() {
        const gTranslateSelect = document.getElementsByClassName('goog-te-combo')[0]
        if (!gTranslateSelect) return

        setTimeout(() => {
          const gTranslateSelectChildren = gTranslateSelect.children
          for (let i = 0; i < gTranslateSelectChildren.length; i++) {
            const option = gTranslateSelectChildren[i]
            let idLabel = 'pilihbahasa'

            if (option.value.length > 0) {
              idLabel += `-${option.value}`
            }

            option.id = generateElementID(idLabel)
          }
        }, 2000)

        gTranslateSelect.addEventListener('change', () => getSelectedLang())

        const getSelectedLang = () => {
          const selectedOption = document.getElementsByClassName('goog-te-combo')[0].value
          if (selectedOption === 'en') resetLang()
        }
      })
    }
  }, [])

  return <div id="google_translate_element" className={getClassName()} />
}

export default MultiLanguage
