import React, { useState } from 'react'
import Img from '@qlue/react-component/dist/Img'
import { Text } from '@qlue/react-component/dist/QlueTypography'
import { BaseModal } from '@qlue/react-component/dist/Modal'
import MapEnrollment from '@qlue/react-component/dist/MapEnrollment'
import { formatDate } from 'utils'
import { noEnrolledPhoto } from 'commons/assets/images'
import Styled from './style'

export default function FR({ detail }) {
  const [showMap, setShowMap] = useState(false)

  // compute user label
  const userLabel = () => {
    if (detail.enrolled_face.user_labels) {
      const mapUserLabel = detail.enrolled_face.user_labels.map(item => {
        return item.name
      })
      const join = mapUserLabel.join(', ')
      return join
    }
  }

  return (
    <>
      <Styled.MediaWrapper>
        <Styled.MediaFR>
          <Img
            src={
              detail.enrolled_face.images.length > 0
                ? detail.enrolled_face.images[0].image_url
                : noEnrolledPhoto
            }
            alt="Enrollment"
          />
          <div>Enrollment Photo</div>
        </Styled.MediaFR>
        <Styled.MediaFR>
          <Img src={detail.snapshot_image} alt="Last Match" />
          <div>Last Match</div>
        </Styled.MediaFR>
      </Styled.MediaWrapper>
      <Styled.ContentWrapper>
        <Styled.Content>
          <Text>Alert Name</Text>
          <Text>:</Text>
          <Text>{detail.name}</Text>
        </Styled.Content>
        <Styled.Content>
          <Text>Report Message</Text>
          <Text>:</Text>
          <Text>{detail.message}</Text>
        </Styled.Content>
        <Styled.Content>
          <Text>Time Detected</Text>
          <Text>:</Text>
          <Text>{detail.created_at ? formatDate(detail.created_at, { withTime: true }) : ''}</Text>
        </Styled.Content>
        <Styled.Content>
          <Text>CCTV Name</Text>
          <Text>:</Text>
          <Text>{detail.feed.name}</Text>
        </Styled.Content>
        <Styled.Content>
          <Text>Engine</Text>
          <Text>:</Text>
          <Text>{detail.event_source.alias}</Text>
        </Styled.Content>
        <Styled.Content>
          <Text>Enrollment Name</Text>
          <Text>:</Text>
          <Text>{detail.enrolled_face.name}</Text>
        </Styled.Content>
        <Styled.Content>
          <Text>Enrollment Type</Text>
          <Text>:</Text>
          <Text>{detail.enrolled_face.type}</Text>
        </Styled.Content>
        <Styled.Content>
          <Text>User Label</Text>
          <Text>:</Text>
          <Text>{userLabel()}</Text>
        </Styled.Content>
        <Styled.Content>
          <Text>Location</Text>
          <Text>:</Text>
          <Text>
            {detail.feed.address}{' '}
            {detail.feed.lat && detail.feed.lng && (
              <Text className="map-link" onClick={() => setShowMap(true)}>
                (show on map)
              </Text>
            )}
          </Text>
        </Styled.Content>
      </Styled.ContentWrapper>
      {showMap && (
        <BaseModal
          visible
          width="70vw"
          height="70vh"
          closeIcon
          border
          onClose={() => setShowMap(false)}
        >
          <MapEnrollment
            width="100%"
            height="100%"
            withSearch={false}
            center={[detail.feed.lat, detail.feed.lng]}
            attributionControl={false}
            isPreventClick
            onChange={() => null}
          />
        </BaseModal>
      )}
    </>
  )
}
