import React from 'react'
import { AnalyticURL, ModuleURL } from 'commons/API'
import useAPIData from 'commons/hooks/useAPIData'
import qlueworkQueryParams from 'pages/Analytic/Qluework/queryParams'
import leaderboardQueryParams from 'pages/Analytic/Leaderboard/queryParams'

const getModule = {
  Analytic: {
    url: AnalyticURL.getTabMenu,
    initialData: { tabMenu: [] },
    key: 'tabMenu'
  },
  Settings: {
    url: ModuleURL.GetSettingModule,
    initialData: { settings: [] },
    key: 'settings'
  }
}
//WIP: hardcoded for queryParams, later we need refactor queryParams issue at onHandleTab func in TabShell
const queryParams = {
  leaderboard: leaderboardQueryParams,
  qluework: qlueworkQueryParams
}

const useFilterTab = (tabList, key, filter, pathname, navigate) => {
  const defaultTabs = React.useMemo(() => tabList[key], [tabList, key])
  const [tabs, setTabs] = React.useState(defaultTabs)

  const { data, isLoading, fetchAPI } = useAPIData({
    url: getModule[key]?.url || AnalyticURL.getTabMenu,
    initialData: getModule[key]?.initialData || { tabMenu: [] },
    manual: true
  })

  React.useEffect(() => {
    setTabs(defaultTabs)
    // eslint-disable-next-line
  }, [defaultTabs.length])

  React.useEffect(() => {
    if (filter && !isLoading) {
      let name = ''

      const tabsWithPath = data[getModule[key]?.key || 'tabMenu'].map((x, i) => {
        switch (x.displayName) {
          case 'Thermal Camera':
            name = 'Thermal CCTV'
            break
          case 'People Counting':
            name = 'People Line Counting'
            break

          default:
            name = x.displayName
            break
        }

        let routeObject = {
          id: i + 1,
          name,
          path: x.alias
        }

        if (key === 'Analytic') {
          routeObject.queryParams = queryParams[x.alias]
        }

        return routeObject
      })
      let mergeTabs = [...defaultTabs, ...tabsWithPath]
      if (key === 'Settings') {
        //hardcode: temporraly hide QlueApp tab at Settings bcs we don't have that page yet
        mergeTabs = mergeTabs.filter(element => element.name !== 'QlueApp')
      }

      setTabs(mergeTabs)

      // Use below if pathname only renders TabShell and navigate to first tab path
      const [firstTab = { path: '' }] = mergeTabs
      if (pathname === '/analytic') {
        navigate(firstTab.path)
      }
    }
    // eslint-disable-next-line
  }, [isLoading, pathname])

  // make sure API was calling when filter is true
  React.useEffect(() => {
    if (filter) fetchAPI()
  }, [fetchAPI, filter])

  return { tabs, setTabs, isLoading: filter && isLoading }
}

export default useFilterTab
