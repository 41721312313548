import React, { useRef, useState } from 'react'
import ReactCrop from 'react-image-crop'
import FlexContainer from '@qlue/react-component/dist/FlexContainer'
import { BaseButton as Button } from '@qlue/react-component/dist/QlueButton'
import { BaseModal as Modal } from '@qlue/react-component/dist/Modal'
import Styled from './styles'
import 'react-image-crop/dist/ReactCrop.css'

let fileUrl = null

function ModalCrop({ fileSrc, onClose, onSubmit, loading, ...props }) {
  const canvasRef = useRef(null)
  const imageRef = useRef(null)
  const [crop, setCrop] = useState({
    unit: '%',
    width: 100,
    aspect: 1 / 1
  })
  const [croppedImageUrl, setCroppedImageUrl] = useState('')
  const [dataSend, setDataSend] = useState(null)

  function onImageLoaded(image) {
    imageRef.current = image
  }

  function onCropComplete(crop) {
    makeClientCrop(crop)
  }

  function onCropChange(crop) {
    setCrop(crop)
  }

  async function makeClientCrop(crop) {
    if (imageRef.current && crop.width && crop.height) {
      const { fileUrl: newCroppedImageUrl, file } = await getCroppedImg(
        imageRef.current,
        crop,
        'newFile.png'
      )
      setCroppedImageUrl(newCroppedImageUrl)
      setDataSend(file)
    }
  }

  function getCroppedImg(image, crop, fileName) {
    const canvas = canvasRef.current
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    canvas.width = crop.width
    canvas.height = crop.height
    const ctx = canvas.getContext('2d')

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    )

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error('Canvas is empty')
          return
        }
        blob.name = fileName
        window.URL.revokeObjectURL(fileUrl)
        fileUrl = window.URL.createObjectURL(blob)
        resolve({ fileUrl, file: blob })
      }, 'image/png')
    })
  }

  return (
    <Modal
      visible
      width="auto"
      height="auto"
      maxHeight="80vh"
      overflow="auto"
      closeIcon={false}
      onClose={onClose}
      {...props}
    >
      <canvas ref={canvasRef} style={{ display: 'none' }} />
      <Styled.CropWrapper>
        <ReactCrop
          src={fileSrc}
          crop={crop}
          ruleOfThirds
          onImageLoaded={onImageLoaded}
          onComplete={onCropComplete}
          onChange={onCropChange}
          circularCrop
        />
        <FlexContainer justifyContent="center" alignItems="center" className="mv2">
          <Button
            className="mr2"
            primary
            isLoading={loading}
            onClick={() => onSubmit({ dataSend, imgUrl: croppedImageUrl })}
          >
            Submit
          </Button>
          <div className="pointer" onClick={onClose}>
            Cancel
          </div>
        </FlexContainer>
      </Styled.CropWrapper>
    </Modal>
  )
}

export default ModalCrop
