import theme from '@qlue/react-component/dist/styles/theme'
import ProgressBar from '@qlue/react-component/dist/ProgressBar'
import styled from 'styled-components'

export default {
  Wrapper: styled.div`
    > .progress {
      padding: 0 26px;
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 15px;
    }

    > .title {
      padding: 0 26px;
    }
  `,
  ProgressBar: styled(ProgressBar)`
    width: 85%;
  `,
  Progress: styled.div`
    display: flex;
    align-items: center;
    padding: 15px 26px;
    cursor: pointer;

    :hover {
      background-color: ${theme.primaryBackgroundDarker};
    }

    > .check {
      margin-right: 26px;

      > img {
        width: 25px;
      }
    }

    > .body {
      display: flex;
      flex-direction: column;
      width: 300px;

      > span:first-child {
        margin-bottom: 2px;
      }

      > span:last-child {
        text-align: justify;
      }
    }
  `
}
