export default function base64ToFile(dataurl, filename) {
  const regex = new RegExp('([a-z]+:/+)([^/s]*)([a-z0-9-@^=%&;/~+]*)[?]?([^ #]*)#?([^ #]*)')

  if (!regex.test(dataurl)) {
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n)

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }

    return new File([u8arr], filename, { type: mime })
  }

  return dataurl
}
