import React from 'react'
import {
  IconAnalytics,
  IconSetting,
  IconTicketQlue,
  IconMaps,
  IconReturn
} from '@qlue/react-component/dist/QlueIcon/index'

export default label => {
  if (label === 'Analytic') return <IconAnalytics />
  if (label === 'Workgroup') return <IconTicketQlue />
  if (label === 'Settings') return <IconSetting />
  if (label === 'Map') return <IconMaps />
  if (label === 'Logout') return <IconReturn />
  return <IconAnalytics />
}
