const API = process.env.REACT_APP_API_URL_BASE_API

const GetEventList = `${API}/event-alert/list`
const GetEventDetail = id => `${API}/event-alert/list/${id}`
const Feedback = id => `${API}/event-alert/list/${id}/feedback`
const ListRule = `${API}/event-alert/rule/list`
const Create = `${API}/event-alert/rule`
const Rule = id => `${API}/event-alert/rule/${id}`
const RuleEnrollment = id => `${API}/event-alert/rule/${id}/enrollments`
const DeleteRule = `${API}/event-alert/rule/delete`
const Edit = id => `${API}/event-alert/rule/${id}`
const GetNotificationList = `${API}/event-alert/notification/list`
const GetDetailNotification = id => `${API}/event-alert/notification/${id}`
const ReadNotification = `${API}/event-alert/notification/mark-is-read`

export default {
  GetEventList,
  GetEventDetail,
  Feedback,
  Create,
  Rule,
  ListRule,
  RuleEnrollment,
  DeleteRule,
  Edit,
  GetNotificationList,
  GetDetailNotification,
  ReadNotification
}
