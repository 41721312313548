import styled, { css } from 'styled-components'

export default {
  Wrapper: styled.div`
    > .version {
      bottom: 10px;
      right: 200px;
      position: absolute;
    }
  `,
  MenuItem: styled.div`
    overflow: hidden;
    padding: 20px 3px;
    transition: 0.3s;
    border-bottom: solid 1px rgba(174, 176, 179, 0.2);
    ${props =>
      !props.preventClick &&
      css`
        cursor: pointer;

        :hover {
          background-color: #353942;
        }
      `};

    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;

      > .first-section {
        display: flex;

        > .logo-menu {
          width: 40px;
          height: 40px;
          margin-right: 0.5rem;
          display: flex;
          justify-content: center;

          > img {
            object-fit: contain;
          }
        }

        > .text-menu {
          > .title-content {
            width: 300px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }

          > span {
            display: block;

            :fist-child {
              margin-bottom: 2rem;
            }
          }
        }
      }
    }

    .logo-detail {
      width: 20px;
    }
  `
}
