/* eslint-disable */
import React from 'react'
import qs from 'query-string'
import PropTypes from 'prop-types'
import DisplayGrid from '@qlue/react-component/dist/DisplayGrid'
import Props from './props'
import { useWindowWidth } from '@qlue/react-component/dist/hooks'

const ActivationShell = ({ children, location }) => {
  const { gridStyle, imgAttrs, divLogoAttrs, mainAttrs } = Props({
    queryParams: qs.parse(location.search)
  })
  const { name } = useWindowWidth()
  return (
    <DisplayGrid {...gridStyle}>
      <img {...imgAttrs['background']} />
      {name !== 'small' && (
        <div {...divLogoAttrs}>
          <img {...imgAttrs['logo']} />
        </div>
      )}
      <main {...mainAttrs}>{children}</main>
    </DisplayGrid>
  )
}

ActivationShell.propTypes = {
  children: PropTypes.node.isRequired
}

export default ActivationShell
