const API = process.env.REACT_APP_API_URL_BASE_API

const GetAttendances = `${API}/attendance`
const GetAttendanceEmployee = id => `${API}/attendance/employee/${id}`
const GetSource = `${API}/attendance/source`
const GetStatusClockIn = `${API}/attendance-status/clock-in`
const GetStatusClockOut = `${API}/attendance-status/clock-out`
const GetShifts = `${API}/attendance/shifts`
const Download = `${API}/attendance/download`
const DownloadEmployee = id => `${API}/attendance/employee/download/${id}`

const GetAttendancesRealtime = `${API}/attendance/real-time`
const GetAttendancesRealtimeBySearch = `${API}/real-time-attendance/attendance`
const GetReadersRealtime = `${API}/real-time-attendance/readers`

export default {
  GetAttendances,
  GetAttendanceEmployee,
  GetShifts,
  GetSource,
  Download,
  DownloadEmployee,
  GetAttendancesRealtime,
  GetAttendancesRealtimeBySearch,
  GetReadersRealtime,
  GetStatusClockIn,
  GetStatusClockOut
}
