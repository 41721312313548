import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'

import { H6, Paragraph, Text } from '@qlue/react-component/dist/QlueTypography'
import { IconMarker } from '@qlue/react-component/dist/QlueIcon'
import Img from '@qlue/react-component/dist/Img'

import { LayerContainer, LayerContent } from './style'

const TaskItem = ({ className, onClick, imgUrl, title, location, color }) => {
  const [cardHovered, setCardHovered] = useState(false)

  return (
    <LayerContainer
      className={className}
      onClick={onClick}
      cardHovered={cardHovered}
      onMouseEnter={() => setCardHovered(true)}
      onMouseLeave={() => setCardHovered(false)}
    >
      <LayerContent>
        {imgUrl && (
          <div className="mr3">
            <div className="video-thumbnail">
              <Img
                className="content-img"
                src={imgUrl}
                alt="cctv preview"
                width="100%"
                height="100%"
              />
            </div>
          </div>
        )}
        <div>
          <H6 className="ma0 mb2 truncate" style={{ width: '8rem' }}>
            {title}
          </H6>
          <Paragraph className="flex items-center truncate fw4" style={{ fontSize: 11 }}>
            <IconMarker color={color} style={{ width: 12 }} className="mr2 mb2" />
            <Text style={{ width: '7rem' }} className="truncate">
              {location}
            </Text>
          </Paragraph>
        </div>
      </LayerContent>
    </LayerContainer>
  )
}

TaskItem.defaultProps = {
  className: null,
  imgUrl: '',
  onClick: () => null,
  title: '',
  location: ''
}

TaskItem.propTypes = {
  className: PropTypes.string,
  imgUrl: PropTypes.string,
  onClick: PropTypes.func,
  title: PropTypes.string,
  location: PropTypes.string
}

export default memo(TaskItem)
