const API = process.env.REACT_APP_API_URL_BASE_API

const getTabMenu = `${API}/analytic/tab-menu`
const getHasCctv = `${API}/analytic/has-cctv`

const getTaskAverageTime = `${API}/analytic/task-average/dashboard`
const getTaskSummaries = `${API}/analytic/task-status`
const getListAnalyticsOfWorkgroup = `${API}/analytic/task-project`
const getTaskCategory = `${API}/analytic/task-category`
const getTaskPriority = `${API}/analytic/task-priority`
const getTaskCompletion = `${API}/analytic/task-completion`

const getLineChartFR = `${API}/analytic/face/line`
const getPieChartFR = `${API}/analytic/face/pie`
const getTableFR = `${API}/analytic/face/table`

const getLineChartLPR = `${API}/analytic/plate/line`
const getPieChartLPR = `${API}/analytic/plate/pie`
const getTableLPR = `${API}/analytic/plate/table`

const getLineChartPC = `${API}/analytic/people-counting/line`
const getCountPC = `${API}/analytic/people-counting/total-count`
const getTablePC = `${API}/analytic/people-counting/table`

const getLineChartIP = `${API}/analytic/illegal-parking/line`
const getCountIP = `${API}/analytic/illegal-parking/count-per-type`
const getTableIP = `${API}/analytic/illegal-parking/table`

const getLineChartVCC = `${API}/analytic/vehicle/line`
const getCountVCC = `${API}/analytic/vehicle/count-per-type`
const getTableVCC = `${API}/analytic/vehicle/table`

const getLineChartIntrusion = `${API}/analytic/intrusion/line`
const getCountIntrusion = `${API}/analytic/intrusion/total-count`
const getTableIntrusion = `${API}/analytic/intrusion/table`

const getLineChartFM = `${API}/analytic/face-mask/line`
const getCountFM = `${API}/analytic/face-mask/total-count`
const getTableFM = `${API}/analytic/face-mask/table`

const getLineChartPZC = `${API}/analytic/pzc/line`
const getPieChartPZC = `${API}/analytic/pzc/pie`
const getTablePZC = `${API}/analytic/pzc/table`

const getLineChartThermal = `${API}/analytic/thermal/line`
const getPieChartThermal = `${API}/analytic/thermal/pie`
const getTableThermal = `${API}/analytic/thermal/table`
const getCountThermal = `${API}/analytic/thermal/total-count`
const getAverageThermal = `${API}/analytic/thermal/average`
const getBarThermal = `${API}/analytic/thermal/bar`

const getLineChartThermalCamera = `${API}/analytic/thermal-camera/line`
const getPieChartThermalCamera = `${API}/analytic/thermal-camera/pie`
const getTableThermalCamera = `${API}/analytic/thermal-camera/table`
const getCountThermalCamera = `${API}/analytic/thermal-camera/total-count`
const getAverageThermalCamera = `${API}/analytic/thermal-camera/average`
const getBarThermalCamera = `${API}/analytic/thermal-camera/bar`

const getLineChartPowerMonitoring = `${API}/analytic/power-monitoring/line`
const getTablePowerMonitoring = `${API}/analytic/power-monitoring/table`
const getTotalPowerMonitoring = `${API}/analytic/power-monitoring/total-kwh`

const getLineChartAnomaly = `${API}/analytic/anomaly/line`
const getCountAnomaly = `${API}/analytic/anomaly/total-count`
const getTableAnomaly = `${API}/analytic/anomaly/table`

export default {
  getTabMenu,
  getHasCctv,

  getTaskAverageTime,
  getTaskSummaries,
  getListAnalyticsOfWorkgroup,
  getTaskCategory,
  getTaskPriority,
  getTaskCompletion,

  getLineChartFR,
  getPieChartFR,
  getTableFR,

  getLineChartLPR,
  getPieChartLPR,
  getTableLPR,

  getLineChartPC,
  getCountPC,
  getTablePC,

  getLineChartIP,
  getCountIP,
  getTableIP,

  getLineChartVCC,
  getCountVCC,
  getTableVCC,

  getLineChartIntrusion,
  getCountIntrusion,
  getTableIntrusion,

  getLineChartFM,
  getCountFM,
  getTableFM,

  getLineChartPZC,
  getPieChartPZC,
  getTablePZC,

  getLineChartThermal,
  getPieChartThermal,
  getTableThermal,
  getCountThermal,
  getAverageThermal,
  getBarThermal,

  getLineChartThermalCamera,
  getPieChartThermalCamera,
  getTableThermalCamera,
  getCountThermalCamera,
  getAverageThermalCamera,
  getBarThermalCamera,

  getLineChartPowerMonitoring,
  getTablePowerMonitoring,
  getTotalPowerMonitoring,

  getLineChartAnomaly,
  getCountAnomaly,
  getTableAnomaly
}
