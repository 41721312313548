const API = process.env.REACT_APP_API_URL_BASE_API

const CompanyDetailURL = `${API}/company/detail`
const TotalRequest = `${API}/user/plan/total-request`
const UpdateCompany = `${API}/company/dashboard`
const CheckPassword = `${API}/user/check-password`
const ChangeEmail = `${API}/user/email`
const ChangeBillingEmail = `${API}/user/billing-email`
const RequestExpiry = `${API}/company/request`
const CompanyPlan = `${API}/company/plan`
const CancelPlan = `${API}/company/cancel-plan`
const BillingDetails = `${API}/company/billing-details`
const Package = `${API}/company/plan/package`
const Currency = `${API}/company/plan/currency`
const PaymentPeriod = `${API}/company/plan/payment-period`
const PlaceOrder = `${API}/company/plan/place-order`
const CustomPlan = `${API}/company/plan/custom`
const DetailCustomPlan = `${API}/company/plan/detail-custom`
const DetailPlan = id => `${API}/company/plan/${id}`
const GetRenewSubscriptionBtn = `${API}/company/renew-subscription-button`

export default {
  CompanyDetailURL,
  TotalRequest,
  UpdateCompany,
  CheckPassword,
  ChangeEmail,
  ChangeBillingEmail,
  RequestExpiry,
  CompanyPlan,
  CancelPlan,
  BillingDetails,
  Package,
  Currency,
  PaymentPeriod,
  PlaceOrder,
  DetailPlan,
  CustomPlan,
  DetailCustomPlan,
  GetRenewSubscriptionBtn
}
