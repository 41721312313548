import {
  DELETE_ARRAY_VALUE,
  RESET_VALUE,
  RESET_SPECIFIC_VALUE,
  UPDATE_ARRAY_WITH_PREVIOUS,
  UPDATE_ARRAY_OF_ARRAY_WITH_PREVIOUS,
  UPDATE_SPECIFIC_OBJECT_IN_ARRAY,
  UPDATE_VALUE,
  UNSHOW_REQUESTS_VALUE
} from './constants'

export const initialState = {
  requests: [
    {
      id: 'rpd-form-video',
      name: '',
      url: '',
      loading: false,
      progress: 0,
      finish: false,
      error: false,
      show: true
    },
    {
      id: 'rpd-form-geojson',
      name: '',
      url: '',
      loading: false,
      progress: 0,
      finish: false,
      error: false,
      show: true
    },
    {
      id: 'rpd-validation',
      potholeId: '',
      name: '',
      additionalName: '(Verify)',
      loading: false,
      progress: 0,
      finish: false,
      error: false,
      errorMsg: '',
      show: true
    },
    {
      id: 'rpd-pothole-snapshot',
      potholeId: '',
      name: '',
      realtimeSnapshots: [],
      loading: false,
      progress: 0,
      finish: false,
      error: false,
      errorMsg: '',
      show: true
    }
  ]
}

export function reducer(state, { type, ...payload }) {
  switch (type) {
    case UPDATE_VALUE: {
      const { key, value } = payload
      return {
        ...state,
        [key]: value
      }
    }
    case UPDATE_ARRAY_WITH_PREVIOUS: {
      const { key, value } = payload
      return {
        ...state,
        [key]: [...state[key], value]
      }
    }
    case UPDATE_ARRAY_OF_ARRAY_WITH_PREVIOUS: {
      const { key, id, childKey, value } = payload
      const newValue = state[key].map(el => {
        if (el.id === id) {
          return {
            ...el,
            [childKey]: [...el[childKey], value]
          }
        }
        return el
      })
      return {
        ...state,
        [key]: newValue
      }
    }
    case UPDATE_SPECIFIC_OBJECT_IN_ARRAY: {
      const { key, value } = payload
      return {
        ...state,
        [key]: state[key].map(el => (el.id === value.id ? { ...el, ...value } : el))
      }
    }
    case DELETE_ARRAY_VALUE: {
      const { key, value } = payload
      return {
        ...state,
        [key]: state[key].filter(({ id }) => id !== value)
      }
    }
    case UNSHOW_REQUESTS_VALUE: {
      return {
        ...state,
        requests: state.requests.map(el => ({ ...el, show: false }))
      }
    }
    case RESET_SPECIFIC_VALUE: {
      const { key, id } = payload
      return {
        ...state,
        [key]: state[key].map(el => (el.id === id ? initialState[key].find(x => x.id === id) : el))
      }
    }
    case RESET_VALUE: {
      return { ...initialState }
    }
    default:
      return { ...state }
  }
}
