import formatDigitEpoch from './formatDigitEpoch'

export default (timestamp, optionParams = { withTime: false, replaceOptions: null }) => {
  if (!timestamp) return ''

  const additionalOpt = optionParams.withTime
    ? {
        hour: 'numeric',
        minute: 'numeric',
        hour12: false
      }
    : optionParams
  const date = new Date(formatDigitEpoch(timestamp, 'miliseconds'))
  const options = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    ...additionalOpt
  }
  return new Intl.DateTimeFormat('en-GB', optionParams.replaceOptions || options).format(date)
}
