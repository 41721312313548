// dependency libraries
import React, { memo, Suspense, useState, useCallback, useContext, createContext } from 'react'
import PropTypes from 'prop-types'
import BlockContainer from '@qlue/react-component/dist/BlockContainer'
import TabContainer from '@qlue/react-component/dist/TabContainer'

// root modules
import useQueryParams from 'commons/hooks/useQueryParams'
import useImportPage from 'commons/hooks/useImportPage'
import {
  generateElementID,
  CREATE_ALERT_ID_PREFIX,
  ALERT_RULES_ID_PREFIX,
  SETTING_USER_POPUP_CREATENEW_PREFIX,
  SETTING_SMART_CCTV_ADD_NEW_PREFIX,
  SETTING_SMART_CCTV_ACTION_EDIT_PAGE_PREFIX,
  SETTING_ENROLLMENT_PREFIX,
  SETTING_ENROLLMENT_ADD_NEW_PREFIX
} from 'utils/generateElementID'

const ChildTabContext = createContext()

const Content = props => {
  const {
    location: { search, pathname },
    useTitle,
    hasBackNavigation,
    navigate,
    liftingUp,
    children,
    uri,
    returnPath,
    tabWidth,
    ...dynamicSegments
  } = props

  const [title, setTitle] = useState(useTitle)

  const { Component, queryParams: defaultQuery } = useImportPage(pathname, dynamicSegments)
  const queryParams = useQueryParams({ search, defaultQuery })
  const propsWithQueryParams = { ...props, queryParams, setTitle }

  const suspenseComponent = (
    <Suspense fallback={<div />}>{Component && <Component {...propsWithQueryParams} />}</Suspense>
  )

  const handleReturn = useCallback(() => {
    if (returnPath) {
      return navigate(returnPath)
    }
    window.history.back()
  }, [navigate, returnPath])

  const handleComponentId = title => {
    let id = ''
    const currentPathName = window.location.pathname
    switch (title) {
      case 'Detail':
        switch (currentPathName) {
          case '/events/alert-rules/detail':
          default:
            id = generateElementID(ALERT_RULES_ID_PREFIX, 'teksdetail')
            break
        }
        break
      case 'Create Alert':
        id = generateElementID(CREATE_ALERT_ID_PREFIX, 'tekscreatealert')
        break
      case 'Create A New User':
        id = generateElementID(SETTING_USER_POPUP_CREATENEW_PREFIX, 'tekscreatenewuser')
        break
      case 'Add New CCTV':
        id = generateElementID(SETTING_SMART_CCTV_ADD_NEW_PREFIX, 'teksaddnewcctv')
        break
      case 'Edit CCTV':
        id = generateElementID(SETTING_SMART_CCTV_ACTION_EDIT_PAGE_PREFIX, 'tekseditcctv')
        break
      case 'Face Recognition':
        id = generateElementID(SETTING_ENROLLMENT_PREFIX, 'teksfacerecognition')
        break
      case 'Add New Enrollment':
      default:
        id = generateElementID(SETTING_ENROLLMENT_ADD_NEW_PREFIX, 'teksaddnewenrollment')
        break
    }

    return id
  }

  if (useTitle) {
    return (
      <ChildTabContext.Provider value={{ title, setTitle }}>
        <BlockContainer className="ph4">
          <TabContainer
            title={title}
            isChild={!!hasBackNavigation}
            handleReturn={handleReturn}
            width={tabWidth}
            id={handleComponentId(title)}
          />
          <BlockContainer className="mv3">{suspenseComponent}</BlockContainer>
        </BlockContainer>
      </ChildTabContext.Provider>
    )
  } else return suspenseComponent
}

export function useStore() {
  const store = useContext(ChildTabContext)
  if (!store) throw new Error('Cannot using this store')

  return store
}

Content.defaultProps = {
  totalPathReturn: 1,
  tabWidth: '60%'
}

Content.propTypes = {
  totalPathReturn: PropTypes.number,
  tabWidth: PropTypes.string
}

export default memo(Content)
