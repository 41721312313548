import styled from 'styled-components'

export default {
  WrapperList: styled.div`
    padding-top: 13px;

    > .header-list {
      display: flex;
      justify-content: space-between;
      margin: 0 26px 16px;
      padding-bottom: 11px;
      border-bottom: 1px solid white;
    }

    > .title-list {
      margin: 0 26px 16px;
    }

    > .content-list {
      height: calc(100vh - 147px);
      overflow-y: auto;
    }
  `,

  ObserverTrigger: styled.div`
    height: 16px;
  `
}
