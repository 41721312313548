export default rect => {
  let DEFAULT_BOUND = { x: 0, y: 0, isLeft: true }
  if (rect) {
    const x =
      window.innerWidth < rect.x + 350
        ? rect.x - 271
        : rect.x -
          (window.innerWidth - rect.x) +
          (window.innerWidth - rect.x - 300) +
          rect.width / 2
    const y = rect.y + rect.height + 20
    DEFAULT_BOUND = { x, y, isLeft: true }
  }
  return DEFAULT_BOUND
}
