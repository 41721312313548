import styled, { css } from 'styled-components'
import theme from '@qlue/react-component/dist/styles/theme'

export default {
  Wrapper: styled.div`
    position: relative;
    padding: 0 26px;
    font-size: 12px;
    /* disable subscription notification temp */
    display: none;

    :hover {
      background-color: #303747;
    }

    ${props =>
      props.isUnread &&
      css`
        background-color: #303747;
      `};

    > div.icon-close {
      position: absolute;
      top: 10px;
      right: 15px;
      width: 10px;
      height: 10px;
      cursor: pointer;
    }

    > .mark-as-read {
      position: absolute;
      bottom: 8px;
      right: 15px;
      cursor: pointer;
      display: none;

      :hover {
        text-decoration: underline;
        color: ${theme.primaryColor};
      }
    }

    ${props =>
      props.isUnread &&
      css`
        :hover {
          .mark-as-read {
            display: block;
          }
        }
      `};
  `,
  Content: styled.div`
    height: 90px;
    cursor: pointer;
    display: flex;
    align-items: center;

    :hover {
      background-color: #303747;
    }

    > div.avatar {
      width: 42px;
      height: 42px;
      border-radius: 100%;
      overflow: hidden;
      margin-right: 8px;
      background: #fff;

      > img {
        object-fit: cover;
      }
    }

    > div.content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 320px;
    }
  `
}
