/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import DisplayGrid from '@qlue/react-component/dist/DisplayGrid'
import FlexContainer from '@qlue/react-component/dist/FlexContainer'
import BackgroundSlider from './Components/BackgroundSlider'
import Props from './props'
import { generateElementID, LOGIN_PAGE_ID_PREFIX } from 'utils/generateElementID'

const LoginShell = ({ children }) => {
  const { gridStyle, imgAttrs, divLogoAttrs, mainAttrs, childrenAttrs, flexAttrs } = Props()
  return (
    <DisplayGrid {...gridStyle}>
      <main {...mainAttrs}>
        <FlexContainer {...flexAttrs}>
          <div {...divLogoAttrs['logo']}>
            <img
              id={generateElementID(LOGIN_PAGE_ID_PREFIX, 'logoqluedash')}
              {...imgAttrs['logo']}
            />
          </div>
          <a {...divLogoAttrs['home']}>
            <img id={generateElementID(LOGIN_PAGE_ID_PREFIX, 'logohome')} {...imgAttrs['home']} />
          </a>
        </FlexContainer>
        <div {...childrenAttrs}>{children}</div>
      </main>
      <BackgroundSlider />
    </DisplayGrid>
  )
}

LoginShell.propTypes = {
  children: PropTypes.node.isRequired
}

export default LoginShell
