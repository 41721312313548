import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import Styled from './styles'
import { Text } from '@qlue/react-component/dist/QlueTypography'
import theme from '@qlue/react-component/dist/styles/theme'
import { IconClose, IconCctv, IconTime } from '@qlue/react-component/dist/QlueIcon'
import { formatDate } from 'utils'
import NotifModal from '../NotifModal'

function ItemNotification({
  id,
  name,
  message,
  snapshotImage,
  feed,
  eventSource,
  isRead,
  createdAt,
  updatedAt,
  onMarkRead,
  task
}) {
  const [showDetailModal, setShowDetailModal] = useState(false)

  const onCloseModal = useCallback(() => {
    setShowDetailModal(false)
  }, [])

  return (
    <>
      {showDetailModal && (
        <NotifModal
          onCloseModal={onCloseModal}
          engine={eventSource.alias}
          task={task}
          detailId={id}
        />
      )}
      <Styled.Wrapper isRead={isRead} key={id}>
        <div className="icon-close" onClick={() => {}}>
          <IconClose />
        </div>
        {!isRead && (
          <Text className="mark-as-read" onClick={() => onMarkRead(id)}>
            mark as read
          </Text>
        )}
        <Styled.Content
          onClick={() => {
            setShowDetailModal(true)
            onMarkRead(id)
          }}
        >
          <div className="avatar">
            <img alt="avatar" src={snapshotImage} />
          </div>
          <div className="content">
            <Text className="fw5">{name}</Text>
            <Text>{message}</Text>
            <div>
              <IconCctv className="icon-cctv" bgColor="transparent" />
              <Text>{feed.name}</Text>
            </div>
            <div>
              <IconTime className="icon-time" bgColor="transparent" />
              <Text color={theme.primaryTextColorTrans}>
                {formatDate(createdAt, { withTime: true })}
              </Text>
            </div>
          </div>
        </Styled.Content>
      </Styled.Wrapper>
    </>
  )
}

ItemNotification.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  message: PropTypes.string,
  snapshotImage: PropTypes.string,
  feed: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    alias: PropTypes.string,
    deleted_at: PropTypes.number
  }),
  eventSource: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    icon: PropTypes.string,
    alias: PropTypes.string
  }),
  isRead: PropTypes.bool,
  createdAt: PropTypes.number,
  updatedAt: PropTypes.number,
  onMarkRead: PropTypes.func,
  task: PropTypes.object
}

export default ItemNotification
