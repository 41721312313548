import styled, { css } from 'styled-components'

export default {
  Wrapper: styled.div``,
  MenuItem: styled.div`
    ${props =>
      props.isHidden &&
      css`
        display: none;
      `};
    height: ${props => (props.active ? props.height : '55px')};
    overflow: hidden;
    padding: 0 3px;
    padding-bottom: 5px;
    transition: 0.3s;
    margin-top: 20px;
    border-bottom: solid 1px rgba(174, 176, 179, 0.2);

    > .title {
      display: flex;
      justify-content: space-between;
      align-items: center;

      > .first-section {
        display: flex;

        > .logo-menu {
          width: 40px;
          height: 40px;
          margin-right: 0.5rem;
          display: flex;
          justify-content: center;

          > img {
            object-fit: contain;
          }
        }

        > .text-menu {
          > .title-content {
            width: 300px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }

          > span {
            display: block;

            :fist-child {
              margin-bottom: 2rem;
            }
          }
        }
      }

      > .arrow {
        align-self: flex-start;
        width: 8px;
        ${props =>
          props.active
            ? css`
                transform: rotate(90deg);
              `
            : css`
                transform: rotate(-90deg);
              `};
      }
    }
  `,
  Item: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 15px 0;
    text-align: justify;
    padding-left: 3rem;
    padding-right: 1rem;

    .content {
      > span {
        display: block;
      }

      > ul {
        margin: 0;
        padding: 0;
        padding-left: 1.5rem;
        margin: 4px 0;

        p {
          color: #a0a1a2;
        }
      }
    }
  `
}
