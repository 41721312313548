export const GRID2 = 'SECOND_GRID'
export const GRID3 = 'THIRD_GRID'

export const INITIAL_VIEWPORT = { neLat: -6.1, neLng: 106.1, swLat: -6, swLng: 106 }
export const PAGE_SIZE = {
  1: 20,
  2: 1000
}

export const TASK_TABS = [
  {
    id: 1,
    name: 'All'
  },
  {
    id: 2,
    name: 'Personnel'
  },
  {
    id: 3,
    name: 'CCTV'
  }
]

export const TRACKING_TABS = [
  {
    id: 1,
    name: 'All'
  },
  {
    id: 2,
    name: 'Online'
  },
  {
    id: 3,
    name: 'Offline'
  }
]

export const initialSelect = {
  status: [
    {
      id: 1,
      label: 'Waiting',
      value: false,
      type: 'status'
    },
    {
      id: 2,
      label: 'Process',
      value: false,
      type: 'status'
    },
    {
      id: 3,
      label: 'Complete',
      value: false,
      type: 'status'
    }
  ],
  category: [], // Get from API GetAvailableCategory
  task: [
    {
      id: 4,
      label: 'All Task',
      value: false,
      type: 'task'
    },
    {
      id: 5,
      label: 'My Task',
      value: false,
      type: 'task'
    }
  ],
  workgroup: []
}

export const position = [-0.696865, 118.2665979]
export const layers = [
  {
    type: 'googleLayer',
    name: 'Styled Google Maps',
    data: {
      type: 'roadmap',
      styles: [
        {
          featureType: 'all',
          elementType: 'labels.text.fill',
          stylers: [{ color: '#a2aab7' }]
        },
        {
          featureType: 'all',
          elementType: 'labels.text.stroke',
          stylers: [{ visibility: 'on' }, { color: '#000000' }, { weight: '1.00' }]
        },
        {
          featureType: 'all',
          elementType: 'labels.icon',
          stylers: [{ visibility: 'off' }]
        },
        {
          featureType: 'administrative',
          elementType: 'geometry.fill',
          stylers: [{ color: '#66676b' }]
        },
        {
          featureType: 'administrative',
          elementType: 'geometry.stroke',
          stylers: [{ weight: 1.2 }]
        },
        {
          featureType: 'landscape',
          elementType: 'geometry',
          stylers: [{ color: '#131b26' }]
        },
        {
          featureType: 'poi',
          elementType: 'geometry',
          stylers: [{ color: '#0b2327' }]
        },
        {
          featureType: 'poi',
          elementType: 'labels.icon',
          stylers: [{ weight: '0.01' }, { gamma: '0.00' }, { lightness: '-40' }]
        },
        {
          featureType: 'road',
          elementType: 'all',
          stylers: [{ visibility: 'simplified' }]
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry.fill',
          stylers: [{ lightness: 17 }, { color: '#405776' }]
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry.stroke',
          stylers: [{ color: '#131b26' }, { lightness: '0' }, { weight: '1.50' }, { gamma: '0.00' }]
        },
        {
          featureType: 'road.arterial',
          elementType: 'geometry.fill',
          stylers: [{ visibility: 'simplified' }, { color: '#303c4a' }]
        },
        {
          featureType: 'road.arterial',
          elementType: 'geometry.stroke',
          stylers: [{ weight: '1.00' }, { color: '#131b26' }]
        },
        {
          featureType: 'road.local',
          elementType: 'geometry',
          stylers: [{ color: '#303c4a' }, { visibility: 'simplified' }, { lightness: '-20' }]
        },
        {
          featureType: 'transit',
          elementType: 'geometry',
          stylers: [{ color: '#07080c' }, { visibility: 'simplified' }]
        },
        {
          featureType: 'transit.station',
          elementType: 'labels.icon',
          stylers: [
            { weight: '0.01' },
            { visibility: 'simplified' },
            { hue: '#0072ff' },
            { gamma: '0.00' },
            { lightness: '-40' }
          ]
        },
        {
          featureType: 'water',
          elementType: 'geometry',
          stylers: [{ color: '#07080c' }]
        }
      ]
    }
  },
  {
    type: 'other',
    name: 'MapBox Street',
    url:
      'https://api.mapbox.com/styles/v1/qluesmartcity/cl50grv7s000414piv99z7ky4/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoicWx1ZXNtYXJ0Y2l0eSIsImEiOiJjbDUwZ244cncwa3J6M2RvNzBzc2J1Mml0In0.WhcPhNdIgUvYwErR7sYBMw',
    checked: true
  },
  {
    type: 'other',
    name: 'Open Street Map',
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    attribution: "&amp;copy <a href='http://osm.org/copyright'>OpenStreetMap</a> contributors"
  },
  {
    type: 'other',
    name: 'MapBox Satellite',
    url:
      'https://api.mapbox.com/styles/v1/qluesmartcity/cl50ha3wi000i15o168y27pxy/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoicWx1ZXNtYXJ0Y2l0eSIsImEiOiJjbDUwZ244cncwa3J6M2RvNzBzc2J1Mml0In0.WhcPhNdIgUvYwErR7sYBMw'
  }
]
