const API = process.env.REACT_APP_API_URL_BASE_API

const GetSite = `${API}/site`
const RequestSite = `${API}/site`
const CheckEngineLimit = `${API}/site/check-engine-limit`
const CreateCctv = `${API}/cctv`
const RequestExtendDate = `${API}/site/request-extend`
const GetStatusSite = id => `${API}/site/${id}/status`
const GetDetailSiteInfo = id => `${API}/site/${id}/info`
const GetDetailSiteSubs = id => `${API}/site/${id}/subscription`
const GetDetailSiteCctv = id => `${API}/site/${id}/cctv`
const EditCctv = (siteId, cctvId) => `${API}/site/${siteId}/cctv/${cctvId}`
const GetDetailCctv = id => `${API}/cctv/${id}`
const DeleteCctv = id => `${API}/cctv/${id}`

export default {
  GetSite,
  RequestSite,
  GetStatusSite,
  GetDetailSiteInfo,
  GetDetailSiteSubs,
  GetDetailSiteCctv,
  EditCctv,
  CheckEngineLimit,
  CreateCctv,
  GetDetailCctv,
  DeleteCctv,
  RequestExtendDate
}
