import React from 'react'
import { Redirect } from '@reach/router'
import { useSelector } from 'react-redux'

/**
 * This component wraps every public page.
 */
const PublicPage = ({
  component: Component,
  children,
  path,
  isPrivateToo = false,
  ...restProps
}) => {
  const authIsLoaded = useSelector(({ login }) => login.token.token)
  if (authIsLoaded && !isPrivateToo) return <Redirect to="analytic" noThrow />
  return <Component {...restProps}>{children}</Component>
}

/**
 * This component wraps every private page.
 */
const PrivatePage = ({ component: Component, children, path, ...restProps }) => {
  const authIsLoaded = useSelector(({ login }) => login.token.token)
  if (!authIsLoaded) return <Redirect to="/login" noThrow />
  return <Component {...restProps}>{children}</Component>
}

/**
 * A utility function to render pageRoutes defined at route.js.
 * Use recursive to map all children inside routes
 *
 * @param {Array} routes
 */
const getRoutes = (routes, access) => {
  return routes.map(({ path, children = [], ...restProps }) => {
    const commonProps = { key: path, path, ...restProps }
    const recursive = children.length > 0 ? getRoutes(children, access) : null
    if (access === 'public') {
      return <PublicPage {...commonProps}>{recursive}</PublicPage>
    }
    return <PrivatePage {...commonProps}>{recursive}</PrivatePage>
  })
}

/**
 * this function for move an object routes
 * to above, for instance, from this array
 * [
 *  {
 *    path: 'something',
 *    children: [
 *      {
 *        path: 'sip',
 *        liftingUp: 1
 *      }
 *    ]
 *  }
 * ]
 *
 * to this
 * [
 *  {
 *    path: 'sip',
 *    liftingUp: 0
 *  },
 *  {
 *    path: 'something',
 *    children: []
 *  }
 * ]
 *
 * `liftingUp` is a sign for how much u want to move
 *  the object move to above
 *
 * @param {Array} routes
 * @param {Array} prevRoutes
 */
export function reMapRoutes(routes, prevRoutes = []) {
  for (let i = 0; i < routes.length; i++) {
    const route = routes[i]
    const children = route.children || []

    children.forEach((child, childKey) => {
      const haveChildToo = child.children && child.children.length > 0

      if (child.liftingUp > 0) {
        const newRoute = {
          ...child,
          path: `${route.path}/${child.path}`
        }

        routes.push(newRoute)
        newRoute.liftingUp -= 1
        children.splice(childKey, 1)

        if (newRoute.liftingUp > 0) reMapRoutes(prevRoutes)
      }

      if (haveChildToo) reMapRoutes(children, routes)
    })
  }

  return routes
}

export default getRoutes
