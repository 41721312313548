import React from 'react'
import FR from './FR'
import FMD from './FMD'
import IN from './IN'
import IP from './IP'

export default function Detail({ detail, engine, task }) {
  function renderItem() {
    switch (engine) {
      case 'FR':
        return <FR detail={detail} />
      case 'FMD':
        return <FMD detail={detail} />
      case 'Intrusion':
        return <IN detail={detail} />
      case 'IP':
        return <IP detail={detail} />

      default:
        break
    }
  }

  return (
    <>
      <div>{renderItem()}</div>
    </>
  )
}
