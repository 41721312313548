import React from 'react'
import BlockContainer from '@qlue/react-component/dist/BlockContainer'
import FlexContainer from '@qlue/react-component/dist/FlexContainer'
import { H4, Paragraph, Text } from '@qlue/react-component/dist/QlueTypography'
import { BaseButton } from '@qlue/react-component/dist/QlueButton'
import { useStore } from 'shells/AppShell'

import Styled from './styles'

function ModalShowcase({ bounding, isLeft }) {
  const {
    showcase: { currentShowcase, currentPage, lastPage, prev, next, exit, isFirst, isLast }
  } = useStore()
  return (
    <Styled.Modal
      visible
      border
      width="350px"
      height="auto"
      closeIcon={false}
      bounding={bounding}
      onClose={() => exit()}
      arrow={currentShowcase.modalArrow}
      isLeft={isLeft}
      bgColor="#303747"
    >
      <H4 className="tc">{currentShowcase.title}</H4>
      <BlockContainer className="mv3">
        {currentShowcase.body.map((b, i) => (
          <Paragraph key={i.toString()} className="tc fs-16px">
            {b}
          </Paragraph>
        ))}
      </BlockContainer>
      <FlexContainer alignItems="center" justifyContent="space-around">
        {isFirst ? (
          <div />
        ) : (
          <Text className="pointer" onClick={prev}>
            Prev
          </Text>
        )}
        <Text color="#979797">{`${currentPage} / ${lastPage}`}</Text>
        {isLast ? (
          <BaseButton primary onClick={() => exit()}>
            Finish
          </BaseButton>
        ) : (
          <BaseButton primary onClick={next}>
            Next
          </BaseButton>
        )}
      </FlexContainer>
    </Styled.Modal>
  )
}

export default ModalShowcase
