const API = process.env.REACT_APP_API_URL_BASE_API

const getListCCTV = `${API}/cctv/all`
const getCCTVSource = `${API}/cctv/source`
const getCCTVPrivateCloud = `${API}/cctv/private-cloud`
const getListCCTVWithEngine = `${API}/cctv/engine`
const getListParticipants = `${API}/cctv/rule/participants`
const getCctvImage = `${API}/cctv/mjpeg`
const CCTVRuleGeneral = id => `${API}/cctv/${id}/rule` // this API for create, add, and delete cctv rule
const GetListEnrollment = id => `${API}/cctv/${id}/rule-enrollment` // this API for get enrollment in detail cctv rules
const GetProblemHandlers = id => `${API}/cctv/${id}/problem-handlers`
const UpdateCCTVRule = id => `${API}/cctv/${id}/rule/`

export default {
  getListCCTV,
  getCCTVSource,
  getCCTVPrivateCloud,
  UpdateCCTVRule,
  getListCCTVWithEngine,
  getListParticipants,
  getCctvImage,
  CCTVRuleGeneral,
  GetListEnrollment,
  GetProblemHandlers
}
