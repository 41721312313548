import styled from 'styled-components'

const defineSize = props => {
  switch (props.size) {
    case 'mini':
      return '16px'
    case 'tiny':
      return '24px'
    case 'small':
      return '32px'
    case 'medium':
      return '48px'
    case 'large':
      return '64px'
    case 'big':
      return '96px'
    case 'huge':
      return '128px'
    default:
      return props.size
  }
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  > .avatar-wrapper {
    width: ${defineSize};
    height: ${defineSize};
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    > div:first-child {
      width: 100%;
      height: 100%;

      > div {
        width: 100%;
        height: 100%;
        :first-child {
          background-color: transparent;

          > img {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  .name {
    color: ${props => props.customStyle?.color || 'inherit'};
    font-size: 24px;
    font-weight: 500;
    width: 100%;
    text-align: center;

    &.shimmer {
      height: 20px;
    }
  }

  .package-plan {
    font-size: 12px;
    padding: 4px 8px;
    width: fit-content;
    margin: 0 auto;
    background: rgba(1, 134, 213, 0.3);
    border-radius: 2px;
    margin-top: 4px;
    letter-spacing: 0.25px;
  }

  > .line {
    height: 2px;
    width: 100px;
    border: 0.5px solid rgba(255, 255, 255, 0.3);
    background: unset;
  }

  > .greeting {
    font-size: 16px;
    line-height: 20px;
    width: 100%;
    text-align: center;

    > .greeting--name {
      font-weight: bold;
      width: 200px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  > .greeting-shimmer {
    height: 40px;
    width: 64%;
    margin: 0 auto;
  }
`

export default Container
