import React, { useState, useEffect } from 'react'
import useDispatch from 'commons/hooks/useDispatch'
import getDetailCompany from 'stores/action/getDetailCompany'
import PropTypes from 'prop-types'
import Avatar from '@qlue/react-component/dist/Avatar'
import { IconDefaultImage } from '@qlue/react-component/dist/QlueIcon'
import { Conditional } from '@qlue/react-component/dist/utils'
import ImageUpload from '@qlue/react-component/dist/ImageUpload'
import formDataHandler from 'utils/formDataHandler'
import API, { CompanyURL } from 'commons/API'
import { updateGuide } from 'utils'
import { generateElementID, MENU_PAGE_ID_PREFIX } from 'utils/generateElementID'
import ModalCrop from '../ModalCrop'
import Container from './styles'

const AsideProfile = ({
  avatar: { src, alt, size, bgColor, isSquare, className },
  companyName,
  greetingText,
  greetingName,
  onlyPicture,
  isLoading,
  customStyle,
  ...restProps
}) => {
  const fetchDetailCompany = useDispatch(getDetailCompany)
  const [modal, setModal] = useState(false)
  const [fileSrc, setFileSrc] = useState('')
  const [loading, setLoading] = useState(false)
  const [removeInput, setRemoveInput] = useState(false)

  useEffect(() => {
    if (removeInput) setRemoveInput(false)
  }, [removeInput])

  function onChangeImage({ imgSrc }) {
    setFileSrc(imgSrc)
    setModal(true)
  }

  function onClose() {
    setFileSrc(src)
    setModal(false)
    setRemoveInput(true)
  }

  async function onSubmit({ imgUrl, dataSend: file }) {
    if (file) {
      try {
        setLoading(true)
        const dataSend = {
          logo: file
        }
        await API.put(CompanyURL.UpdateCompany, formDataHandler(dataSend))
        setLoading(false)
        alert({ type: 'success', msg: 'Success! Your company`s logo has been updated' })
        setRemoveInput(true)
        setFileSrc(imgUrl)
        setModal(false)
        updateGuide()
        fetchDetailCompany()
      } catch ({
        response: {
          data: { errorCode, message },
          status
        }
      }) {
        setLoading(false)
        if (errorCode) alert({ type: 'failed', msg: message })
        else if (status >= 500) alert({ type: 'failed', msg: 'Something went wrong in our server' })
      }
    }
  }

  const avatarSrc = isLoading ? (
    <div className="shimmer w-100 h-100" />
  ) : (
    src || <IconDefaultImage />
  )
  return (
    <>
      {modal && (
        <ModalCrop fileSrc={fileSrc} onClose={onClose} loading={loading} onSubmit={onSubmit} />
      )}
      <Container customStyle={customStyle} size={size} {...restProps}>
        <div className="avatar-wrapper" id={generateElementID(MENU_PAGE_ID_PREFIX, 'changeava')}>
          {size === 'small' ? (
            <Avatar
              src={avatarSrc}
              alt={alt}
              size={size}
              bgColor={bgColor}
              isSquare={isSquare}
              className={className}
              style={{ transition: '0.3s' }}
            />
          ) : (
            <ImageUpload
              defaultLogo={avatarSrc}
              changedImage={fileSrc}
              width="80px"
              options={{
                maxSizeMB: 1,
                maxWidthOrHeight: 1920
              }}
              accept="image/png"
              removeInput={removeInput}
              onChange={onChangeImage}
            />
          )}
        </div>
        <Conditional if={!onlyPicture}>
          <Conditional if={isLoading}>
            <div className="name shimmer" />
            <div className="line" />
            <div className="greeting-shimmer shimmer" />
          </Conditional>
          <Conditional if={!isLoading}>
            <div>
              <div className="name">{companyName}</div>
              {greetingText && <div className="package-plan">{greetingText}</div>}
            </div>
            <div className="greeting">
              <div className="greeting--name">{greetingName}</div>
            </div>
          </Conditional>
        </Conditional>
      </Container>
    </>
  )
}

AsideProfile.defaultProps = {
  avatar: {
    src: '',
    alt: 'alternate',
    size: 'large',
    bgColor: '',
    square: false,
    className: ''
  },
  onlyPicture: false,
  isLoading: false,
  greetingText: 'Welcome Back,',
  customStyle: {
    color: ''
  }
}

AsideProfile.propTypes = {
  companyName: PropTypes.string.isRequired,
  greetingName: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  avatar: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
    bgColor: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.oneOf(['wait', 'process', 'complete', 'profile', 'officer'])
    ]),
    isSquare: PropTypes.bool,
    className: PropTypes.string,
    size: PropTypes.oneOf(['mini', 'tiny', 'small', 'large', 'big', 'huge'])
  }),
  greetingText: PropTypes.string,
  onlyPicture: PropTypes.bool,
  isLoading: PropTypes.bool,
  customStyle: PropTypes.shape({
    color: PropTypes.string
  })
}

export default AsideProfile
