import qs from 'query-string'

const API = process.env.REACT_APP_API_URL_BASE_API

const GetLocation = `${API}/geom/location`
const GetCountries = `${API}/geom/countries`
const GetProvinces = countryCode => `${API}/geom/provinces?countryCode=${countryCode}`
const GetCities = ({ countryCode, provinceCode }) =>
  `${API}/geom/cities?${qs.stringify({ countryCode, provinceCode })}`
const GetDistricts = cityCode => `${API}/geom/districts?cityCode=${cityCode}`
const GetSubdistricts = districtCode => `${API}/geom/subdistricts?districtCode=${districtCode}`

export default { GetLocation, GetCountries, GetProvinces, GetCities, GetDistricts, GetSubdistricts }
