const API = process.env.REACT_APP_API_URL_BASE_API

const GetInitialCounting = `${API}/ppe/count/`
const GetAnalyticsLivefeed = `${API}/ppe/analytics`
const GetAnalyticsLivefeedDetail = `${API}/ppe/analytics/detail`
const GetHistoryList = `${API}/ppe/history`
const GetAnalyticLine = `${API}/analytic/ppe/line`
const GetAnalyticTable = `${API}/analytic/ppe/table`
const GetAnalyticPieChart = `${API}/analytic/ppe/total-violation`
const DownloadPPEHistory = `${API}/ppe/history/download`

export default {
  GetInitialCounting,
  GetAnalyticsLivefeed,
  GetAnalyticsLivefeedDetail,
  GetHistoryList,
  GetAnalyticLine,
  GetAnalyticTable,
  GetAnalyticPieChart,
  DownloadPPEHistory
}
