const API = process.env.REACT_APP_API_URL_BASE_API

const GetPowerMonitoringHistory = `${API}/smart-device/power-monitoring/history`
const GetPowerMonitoringHistoryDetail = `${API}/smart-device/power-monitoring/history/detail`
const GetPowerMonitoringDetail = id => `${API}/smart-device/power-monitoring/${id}`
const GetPowerMonitoringDetailAnalytics = id =>
  `${API}/smart-device/power-monitoring/${id}/analytics`
const GetPowerMonitoringDetailTable = id => `${API}/smart-device/power-monitoring/${id}/daily`
const GetPowerMonitoringLocation = `${API}/smart-device/power-monitoring/location`

export default {
  GetPowerMonitoringHistory,
  GetPowerMonitoringHistoryDetail,
  GetPowerMonitoringDetail,
  GetPowerMonitoringDetailAnalytics,
  GetPowerMonitoringDetailTable,
  GetPowerMonitoringLocation
}
