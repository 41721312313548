import React from 'react'
import BlockContainer from '@qlue/react-component/dist/BlockContainer'
import FlexContainer from '@qlue/react-component/dist/FlexContainer'
import { H4, Paragraph } from '@qlue/react-component/dist/QlueTypography'
import { BaseButton } from '@qlue/react-component/dist/QlueButton'
import { useStore } from 'shells/AppShell'

import Styled from './styles'
import { ArrayMap } from '@qlue/react-component/dist/utils'

function ModalGuide() {
  const {
    guide: { bounding, current, currentPage, lastPage, next, exit, isLast, isLeft }
  } = useStore()

  function renderAction() {
    if (isLast)
      return (
        <BaseButton primary onClick={() => exit({ completed: true })}>
          Complete
        </BaseButton>
      )
    if (current.isTemporaryExit) return ''
    return (
      <BaseButton primary onClick={next}>
        Next
      </BaseButton>
    )
  }

  return (
    <Styled.Modal
      visible
      border
      width="350px"
      height="auto"
      closeIcon
      bounding={bounding}
      isLeft={isLeft}
      onClose={() => exit({ isTemporaryExit: !!current.isTemporaryExit })}
      arrow={current.modalArrow}
      bgColor="#303747"
    >
      <H4 className="tc">{current.title}</H4>
      <BlockContainer className="mv3">
        <Paragraph className="tc fs-16px">{current.subTitle}</Paragraph>
      </BlockContainer>
      {lastPage > 1 && (
        <FlexContainer alignItems="center" justifyContent="space-between">
          <FlexContainer alignItems="center">
            <ArrayMap data={new Array(lastPage).fill().map(() => '')}>
              {(_, i) => <Styled.Dot key={String(i)} active={i <= currentPage - 1} />}
            </ArrayMap>
          </FlexContainer>
          {renderAction()}
        </FlexContainer>
      )}
    </Styled.Modal>
  )
}

export default ModalGuide
