const isProduction = process.env.REACT_APP_NODE_ENV === 'production'

export const settingRoutes = isProduction
  ? []
  : [
      { id: 6, name: 'Smart Device', path: 'settings/smart-device' },
      { id: 7, name: 'Attendance', path: 'settings/attendance' }
    ]

export const IS_BALI_COMPANY = companyId => companyId === 'bbc641d8-0731-11ed-90a4-0ae178bd1794'
