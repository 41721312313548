const API = process.env.REACT_APP_API_URL_BASE_API

const GetInitialData = `${API}/illegal-parking/count/`
const GetAnalytics = `${API}/illegal-parking/analytics`
const GetDetailOfAnalytics = `${GetAnalytics}/detail`
const GetHistoryData = `${API}/illegal-parking/history`
const GetDetailHistory = `${GetHistoryData}/detail`

export default {
  GetInitialData,
  GetAnalytics,
  GetDetailOfAnalytics,
  GetHistoryData,
  GetDetailHistory
}
