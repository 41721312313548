import firebase from 'firebase/app'

const config = {
  apiKey: 'AIzaSyDjWrmaJ_-nMLdNcyGS7-2QN7Eq_5uUNS8',
  authDomain: 'qluework2-staging.firebaseapp.com',
  databaseURL: 'https://qluework2-staging.firebaseio.com',
  projectId: 'qluework2-staging',
  storageBucket: 'qluework2-staging.appspot.com',
  messagingSenderId: '167332444490',
  appId: '1:167332444490:web:fba3a9a61a931d643569b3',
  measurementId: 'G-JSCBZ0KY8G'
}

if (!firebase.apps.length) firebase.initializeApp(config)

export default firebase
