import { terms, privacyPolicy, faq } from 'commons/assets/images'

const QLUEWORK_WEBSITE_URL = process.env.REACT_APP_QLUEWORK_BASE_URL

export const dummyData = [
  {
    id: '1',
    name: 'Terms of Services',
    imgUrl: terms,
    content: 'More about our agreement',
    link: `${QLUEWORK_WEBSITE_URL}/term-service`
  },
  {
    id: '2',
    name: 'Privacy Policy',
    imgUrl: privacyPolicy,
    content: 'Knowing our statement',
    link: `${QLUEWORK_WEBSITE_URL}/privacy-policy`
  },
  {
    id: '3',
    name: 'FAQ',
    imgUrl: faq,
    content: 'Most popular topics asked',
    link: `${QLUEWORK_WEBSITE_URL}/faq`
  }
]
