const API = process.env.REACT_APP_API_URL_BASE_API

const GetInitialData = `${API}/anomaly/count/`
const Analytics = `${API}/anomaly/analytics`
const GetDetailOfDetailAnalytics = `${API}/anomaly/analytics/detail`
const GetDetailSnapshot = `${API}/vad/video/detail/`
const GetListHistory = `${API}/anomaly/history/`
const GetDetailHistory = `${GetListHistory}detail`
const DownloadHistory = `${API}/anomaly/history/download`

export default {
  GetInitialData,
  GetDetailHistory,
  Analytics,
  GetDetailOfDetailAnalytics,
  GetDetailSnapshot,
  GetListHistory,
  DownloadHistory
}
