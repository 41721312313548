import React from 'react'
import theme from '@qlue/react-component/dist/styles/theme'
import { addSeconds, format } from 'date-fns'
import { StyledText } from './styles'

const analyticContents = m => [
  {
    ...m,
    id: 'analytic-1',
    isSidebar: true,
    title: 'Analyze Your Tasks Easily',
    body: [
      'This dashboard can help you monitor your task status, total tasks, and average completion time.'
    ],
    modalArrow: 'left-top'
  }
]

const mapContents = m => [
  {
    ...m,
    id: 'map-1',
    isSidebar: true,
    title: 'Filter Your Tasks By Map',
    body: ['You can filter your tasks by location in map'],
    modalArrow: 'left-top'
  }
]

const settingsContents = m => [
  {
    ...m,
    id: 'settings-1',
    isSidebar: true,
    title: 'Access to Settings',
    body: [
      `
      Manage your company profile, categories,
      layers, users, and CCTVs
      by clicking Settings menu
    `
    ],
    modalArrow: 'left-bottom',
    additionalY: -200
  },
  {
    ...m,
    id: 'settings-2',
    useTab: 'Settings',
    title: 'Quick Access to Setting Features',
    body: [
      'Here are the features of your setting where you can:',
      <br />,
      <StyledText className="db" size={13} color={theme.secondaryTextColor}>
        Change your company info, credential, and plan
      </StyledText>,
      <StyledText className="db" size={13} color={theme.secondaryTextColor}>
        Edit your categories
      </StyledText>,
      <StyledText className="db" size={13} color={theme.secondaryTextColor}>
        Check and edit your layers
      </StyledText>,
      <StyledText className="db" size={13} color={theme.secondaryTextColor}>
        Monitor your smart CCTV
      </StyledText>
    ],
    modalArrow: 'top-right'
  }
]

export const getDataShowCase = ({ module, isAdmin }) => {
  const firstShowcase = isAdmin
    ? [
        {
          id: 'profile-1',
          path: '/profile/info',
          useTab: 'Profile',
          title: 'Manage Your Company Profile',
          body: ['Company profile consists of company info, credential, and plan.'],
          modalArrow: 'top-right'
        }
      ]
    : []

  let moduleShowcase = [...firstShowcase]

  const objGetStarted = {
    id: 'get-started',
    path: '',
    title: 'Getting Started To Be An Expert',
    body: [
      'You can access this menu to help you getting started in becoming an expert in Qlue Dashboard.',
      'Here user can ‘Replay Showcase’ again.'
    ],
    modalArrow: 'top-right'
  }

  module
    .filter(m => m.name === 'Analytic' || m.name === 'Map' || m.name === 'Settings')
    .forEach(m => {
      if (m.name === 'Analytic') moduleShowcase = [...moduleShowcase, ...analyticContents(m)]
      if (m.name === 'Map') moduleShowcase = [...moduleShowcase, ...mapContents(m)]
      if (m.name === 'Settings') moduleShowcase = [...moduleShowcase, ...settingsContents(m)]
    })

  moduleShowcase = [...moduleShowcase, objGetStarted]

  return moduleShowcase
}

export const getInitialShowcase = ({ module, isAdmin, isInitialShowcase }) =>
  isInitialShowcase ? [] : getDataShowCase({ module, isAdmin })

export const dataSavedGettingStarted = {
  // if you change this data dont forget to update gettingStartedVersion then user can get new update of data changes
  gettingStartedVersion: '0.0',
  Analytic: [
    {
      id: 'analytic-gs-1',
      isChecked: false,
      title: 'About Analytic',
      content: 'Visualize strategic data for your organization at a glance.'
    },
    {
      id: 'analytic-gs-2',
      isChecked: false,
      title: 'QlueWork Analysis',
      content:
        'All workgroup highlight per month for total task, task status, & average completion time.'
    },
    {
      id: 'analytic-gs-3',
      isChecked: false,
      title: 'Workgroup Summary',
      content:
        'Highlight per workgroup, with additional info like task priority & most reported categories by chart.'
    },
    {
      id: 'analytic-gs-4',
      isChecked: false,
      title: 'Thermal Analysis',
      content: 'Highlight for thermal devices.'
    }
  ],
  Map: [
    {
      id: 'map-gs-1',
      isChecked: false,
      title: 'About Map',
      content: 'Interact with task or device based on location.'
    },
    {
      id: 'map-gs-2',
      isChecked: false,
      title: 'Go to Layer',
      content: 'A mark on the map that represent a thing. Show or hide to know that.'
    },
    {
      id: 'map-gs-3',
      isChecked: false,
      title: 'Show / Hide Smart CCTV',
      content: 'Know where your cctv location on map.'
    },
    {
      id: 'map-gs-4',
      isChecked: false,
      title: 'Show / Hide Smart Devices',
      content: 'Know where your devices location on map.'
    }
  ],
  Profile: [
    {
      id: 'profile-gs-1',
      isChecked: false,
      title: 'Manage Your Profile',
      content: 'Consist of profile information, credential, plan, and billing.'
    },
    {
      id: 'profile-gs-2',
      isChecked: false,
      title: 'Profile Information',
      content: 'Check & edit profile such as name, address, profile picture, etc.'
    },
    {
      id: 'profile-gs-3',
      isChecked: false,
      title: 'Credential',
      content: 'Manage account email (also billing email) and password here.'
    },
    {
      id: 'profile-gs-4',
      isChecked: false,
      title: 'Plan',
      content:
        'Check & manage plan detail such us plan status, feature subscription, billing periode, & invoice.'
    }
  ],
  Workgroup: [
    {
      id: 'workgroup-gs-1',
      isChecked: false,
      title: 'Will avaible soon',
      content: 'We know you are excited. Thankyou :)'
    }
  ],
  Attendance: [
    {
      id: 'workgroup-gs-1',
      isChecked: false,
      title: 'Will avaible soon',
      content: 'We know you are excited. Thankyou :)'
    }
  ],
  'Live Feeds': [
    {
      id: 'workgroup-gs-1',
      isChecked: false,
      title: 'Will avaible soon',
      content: 'We know you are excited. Thankyou :)'
    }
  ],
  'Smart Devices': [
    {
      id: 'workgroup-gs-1',
      isChecked: false,
      title: 'Will avaible soon',
      content: 'We know you are excited. Thankyou :)'
    }
  ],
  Settings: [
    {
      id: 'workgroup-gs-1',
      isChecked: false,
      title: 'Will avaible soon',
      content: 'We know you are excited. Thankyou :)'
    }
  ]
}

export function formattedTime(seconds) {
  var helperDate = addSeconds(new Date(0), seconds)
  return format(helperDate, 'mm:ss')
}

// add more case if new id are provided from qa
export function getLinkId(name) {
  switch (name) {
    case 'Events':
      return 'events'
    case 'Analytic':
      return 'analytic'
    case 'Smart CCTV':
      return 'smartcctv'
    case 'Workgroup':
      return 'workgroup'
    case 'Map':
      return 'map'
    case 'Settings':
      return 'setting'

    default:
      return ''
  }
}
