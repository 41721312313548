import { IconClose } from '@qlue/react-component/dist/QlueIcon'
import theme from '@qlue/react-component/dist/styles/theme'
import React, { lazy, Suspense, useEffect, useState } from 'react'
import Styled from './styles'

function RightBar(props) {
  const { open, type, onClose } = props

  const [Component, setComponent] = useState()

  useEffect(() => {
    if (type) {
      const getComponent = lazy(() => import(`./templates/${type}`).then(comp => comp))
      setComponent(getComponent)
    }
  }, [type])

  return (
    <Styled.Wrapper open={open}>
      <div className="icon-close" onClick={onClose}>
        <IconClose color={theme.primaryColor} />
      </div>
      {type && Component && (
        <Suspense fallback={<div />}>
          <Component {...props} />
        </Suspense>
      )}
    </Styled.Wrapper>
  )
}

export default RightBar
