const API = process.env.REACT_APP_API_URL_BASE_API

const GetAllEngine = `${API}/engine`
const GetAllSiteEngine = id => `${API}/engine/${id}`
const GetAllCloudEngine = `${API}/engine/cloud`
const RequestEngine = `${API}/engine/request`
const GetAllAvailableEngine = `${API}/engine/live-feeds`
const CheckTotalAssigned = `${API}/engine/check-total`

export default {
  GetAllEngine,
  GetAllSiteEngine,
  GetAllCloudEngine,
  RequestEngine,
  GetAllAvailableEngine,
  CheckTotalAssigned
}
