import styled from 'styled-components'

export default {
  Wrapper: styled.div`
    padding: 40px;
    position: relative;

    > div.icon-close {
      width: 16px;
      height: 16px;
      cursor: pointer;
      position: absolute;
      right: 16px;
      top: 16px;
    }
  `,

  ModalRadius: styled.div`
    > div > div {
      border-radius: 4px;
    }
  `
}
