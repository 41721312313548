import React, { useState } from 'react'
import { Text } from '@qlue/react-component/dist/QlueTypography'
import VideoPlayer from '@qlue/react-component/dist/VideoPlayer'
import { BaseModal } from '@qlue/react-component/dist/Modal'
import MapEnrollment from '@qlue/react-component/dist/MapEnrollment'
import { formatDate } from 'utils'
import { noImage } from 'commons/assets/images'
import Styled from './style'

export default function IN({ detail }) {
  const [showMap, setShowMap] = useState(false)

  return (
    <>
      <Styled.Media>
        <VideoPlayer src={detail.intrusion.video_url ? detail.intrusion.video_url : noImage} />
      </Styled.Media>
      <Styled.ContentWrapper>
        <Styled.Content>
          <Text>Alert Name</Text>
          <Text>:</Text>
          <Text>{detail.name}</Text>
        </Styled.Content>
        <Styled.Content>
          <Text>Report Message</Text>
          <Text>:</Text>
          <Text>{detail.message}</Text>
        </Styled.Content>
        <Styled.Content>
          <Text>Time Detected</Text>
          <Text>:</Text>
          <Text>{detail.created_at ? formatDate(detail.created_at, { withTime: true }) : ''}</Text>
        </Styled.Content>
        <Styled.Content>
          <Text>CCTV Name</Text>
          <Text>:</Text>
          <Text>{detail.feed.name}</Text>
        </Styled.Content>
        <Styled.Content>
          <Text>Engine</Text>
          <Text>:</Text>
          <Text>{detail.event_source.alias}</Text>
        </Styled.Content>
        <Styled.Content>
          <Text>Location</Text>
          <Text>:</Text>
          <Text>
            {detail.feed.address}{' '}
            {detail.feed.lat && detail.feed.lng && (
              <Text className="map-link" onClick={() => setShowMap(true)}>
                (show on map)
              </Text>
            )}
          </Text>
        </Styled.Content>
      </Styled.ContentWrapper>
      {showMap && (
        <BaseModal
          visible
          width="70vw"
          height="70vh"
          closeIcon
          border
          onClose={() => setShowMap(false)}
        >
          <MapEnrollment
            width="100%"
            height="100%"
            withSearch={false}
            center={[detail.feed.lat, detail.feed.lng]}
            attributionControl={false}
            isPreventClick
            onChange={() => null}
          />
        </BaseModal>
      )}
    </>
  )
}
