const API = process.env.REACT_APP_API_URL_BASE_API

const GetTask = `${API}/new-task/map`
const GetDetailTask = id => `${API}/new-task/${id}/map`
const ChangeAssignee = userId => `${API}/new-task/participant/change/dashboard/${userId}`
const GetHistoryTask = id => `${API}/new-task/${id}/log/dashboard`
const GetLeaderBoardList = `${API}/new-task/leaderboard`
const TaskComment = id => `${API}/new-task/comment/dashboard/${id}`

export default {
  GetTask,
  GetDetailTask,
  ChangeAssignee,
  GetHistoryTask,
  GetLeaderBoardList,
  TaskComment
}
