import {
  REQUEST_COMPANY_DETAIL,
  SUCCESS_COMPANY_DETAIL,
  FAILURE_COMPANY_DETAIL
} from '../constants'
import API, { CompanyURL } from 'commons/API'

export default () => {
  return {
    types: {
      request: REQUEST_COMPANY_DETAIL,
      success: SUCCESS_COMPANY_DETAIL,
      failure: FAILURE_COMPANY_DETAIL
    },
    call: dispatchSuccess =>
      API.get(CompanyURL.CompanyDetailURL).then(res => dispatchSuccess(res.data.company))
  }
}
