import theme from '@qlue/react-component/dist/styles/theme'
import styled from 'styled-components'

export default {
  Wrapper: styled.div`
    position: fixed;
    height: calc(100vh - 50px);
    transition-property: all;
    transition-duration: 0.5s;
    transition-timing-function: cubic-bezier(0.43, 0.01, 0.35, 0.99);
    transition-delay: 0s;
    width: 297px;
    overflow: hidden;
    overflow-y: auto;
    padding: 20px;
    background-color: ${theme.primaryBackground};
    right: ${props => (props.open ? 0 : '-297px')};

    > .icon-close {
      position: absolute;
      top: 15px;
      right: 15px;
      width: 17px;
      height: 17px;
      cursor: pointer;
    }
  `
}
