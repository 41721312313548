import styled from 'styled-components'

export default {
  Wrapper: styled.div`
    position: relative;
    background: url(${props => props.backgroundSrc}) no-repeat center center;
    background-size: cover;
    transition: 0.3s ease-out;

    > img {
      display: none;
    }
  `,
  FrameWrapper: styled.div`
    position: absolute;
    bottom: 102px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0.5rem 0;
    background-image: linear-gradient(
      to top,
      rgba(1, 134, 213, 0.2),
      rgba(75, 167, 222),
      rgba(1, 134, 213, 0.2)
    );

    > .frame-title {
      font-size: 40px;
      font-weight: 700;
      text-align: center;
    }

    > .frame-body {
      font-size: 28px;
      font-weight: 500;
      text-align: center;
    }
  `,
  SliderWrapper: styled.div`
    position: absolute;
    bottom: 72px;
    display: flex;
    justify-content: center;
    width: 100%;
  `,
  Slider: styled.div`
    width: 67px;
    height: 6px;
    margin-right: 12px;
    border-radius: 12px;
    box-shadow: 0 0 8px 2px rgba(83, 164, 228, 0.15);
    background-color: ${props => (props.isActive ? '#53a4e4' : '#ffffff')};
    cursor: pointer;
  `
}
