import {
  REQUEST_COMPANY_DETAIL,
  SUCCESS_COMPANY_DETAIL,
  FAILURE_COMPANY_DETAIL,
  SET_COMPANY_LOGO
} from '../constants'
import {
  getInitReducerState as init,
  getSuccessReducerState as success,
  getFailedReducerState as fail,
  getRequestReducerState as request
} from 'utils/reducerUtils'

const initialState = {
  ...init({ data: null })
}

export default function companyDetailReducer(state = initialState, { type, payload }) {
  switch (type) {
    case REQUEST_COMPANY_DETAIL: {
      return {
        ...state,
        ...request()
      }
    }
    case SUCCESS_COMPANY_DETAIL: {
      return {
        ...state,
        ...success({ data: { ...payload } })
      }
    }
    case FAILURE_COMPANY_DETAIL: {
      const { response } = payload
      return {
        ...state,
        ...fail(response.data.message)
      }
    }
    case SET_COMPANY_LOGO: {
      return {
        ...state,
        data: {
          ...state.data,
          logo: payload
        }
      }
    }
    default:
      return state
  }
}
