import { REQUEST_LOGIN, SUCCESS_LOGIN, FAILURE_LOGIN } from './constant'
import API, { AuthURL, ModuleURL } from 'commons/API'
import { saveLogin } from 'utils/auth'
import { SET_TOKEN_LOGIN } from './constant'

const hardcode = {
  isAdmin: roleId => roleId === 'd2e7ae6a-37b5-4130-8c86-9ec3507d7071'
}

export function getDefaultPath(roleId) {
  return new Promise((resolve, reject) => {
    API.get(ModuleURL.GetModule)
      .then(({ data: { module } }) => {
        resolve(module)
      })
      .catch(e => {
        // Company Expired code : 197
        if (e.response?.data?.errorCode === 197) {
          if (hardcode.isAdmin(roleId)) resolve([])
          else {
            alert.error('Your company has been expired')
            reject(e)
          }
        } else {
          reject(e)
        }
      })
  })
}

export default ({ redirectTo, notRememberMe, ...data }) => {
  return {
    types: {
      request: REQUEST_LOGIN,
      success: SUCCESS_LOGIN,
      failure: FAILURE_LOGIN
    },
    call: (dispatchSuccess, dispatch) =>
      API.post(AuthURL.LoginURL, data).then(async res => {
        const { authorization } = res.headers
        const { user } = res.data

        API.interceptors.request.use(config => {
          config.headers.authorization = authorization
          return config
        })

        getDefaultPath(user.roleId).then(module => {
          function getPath() {
            if (!module[0]?.path) return 'profile/plan'
            if (module[0].path === 'events') return 'events/realtime'
            return module[0].path
          }
          const token = {
            token: authorization,
            refreshToken: user.refreshToken,
            companyId: user.companyId,
            roleId: user.roleId,
            userId: user.id,
            defaultPath: getPath()
          }

          dispatch({ type: SET_TOKEN_LOGIN, token })
          dispatchSuccess(res.data)
          saveLogin(token, notRememberMe)

          if (redirectTo) window.location.href = redirectTo
        })
      })
  }
}
