import { useState, useMemo, createRef, useEffect } from 'react'
import { getInitialShowcase } from '../utils'

const hardcode = {
  isAdmin: roleId => roleId === 'd2e7ae6a-37b5-4130-8c86-9ec3507d7071'
}

export default ({ navigate, module, roleId, modalExpired, setPrimaryButton }) => {
  const showCaseInitial = localStorage.getItem('showCaseInitial')
  const [isLeft, setIsLeft] = useState(false)
  const [menusRef, setMenusRef] = useState([])
  const [startShowcase, setStartShowcase] = useState(!showCaseInitial)
  const [showcase, setShowcase] = useState([])
  const [showcaseOrder, setShowcaseOrder] = useState(-1)
  const [bounding, setBounding] = useState(null)

  const currentShowcase = useMemo(() => showcase[showcaseOrder] || null, [showcase, showcaseOrder])
  const currentPage = useMemo(() => showcaseOrder + 1, [showcaseOrder])
  const lastPage = useMemo(() => showcase.length || null, [showcase])
  const isFirst = useMemo(() => showcaseOrder === 0, [showcaseOrder])
  const isLast = useMemo(() => showcaseOrder === showcase.length - 1, [showcase, showcaseOrder])
  const isInitialShowcase = localStorage.getItem('showCaseInitial')

  function onClickStartTour() {
    setStartShowcase(false)
    setShowcaseOrder(0)
    navigate(showcase[0]?.path || '')
  }

  function replayShowcase() {
    localStorage.removeItem('showCaseInitial')
    setStartShowcase(true)
  }

  useEffect(() => {
    setShowcase(
      getInitialShowcase({
        module,
        isAdmin: hardcode.isAdmin(roleId),
        isInitialShowcase: localStorage.getItem('showCaseInitial')
      })
    )
    const objMenuRef = {}
    module.forEach(m => {
      objMenuRef[m.name] = createRef()
    })
    setMenusRef(objMenuRef)
  }, [module, roleId, isInitialShowcase])

  useEffect(() => {
    if (currentShowcase && currentShowcase.isSidebar) {
      const key = showcase[showcaseOrder].name
      const node = menusRef[key].current
      setIsLeft(true)
      setBounding({
        x: node.getBoundingClientRect().right + 20,
        y: node.getBoundingClientRect().y + (currentShowcase.additionalY || 0)
      })
    } else {
      setIsLeft(false)
    }
  }, [showcase, showcaseOrder, currentShowcase, menusRef])

  useEffect(() => {
    if (modalExpired) exitShowcase()
    // eslint-disable-next-line
  }, [modalExpired])

  function prevShowcase() {
    setShowcaseOrder(prevOrder => {
      let newOrder = prevOrder
      if (prevOrder !== 0) newOrder = prevOrder - 1
      navigate(showcase[newOrder].path)
      return newOrder
    })
  }

  function nextShowcase() {
    setShowcaseOrder(prevOrder => {
      let newOrder = prevOrder
      if (prevOrder !== showcase.length - 1) newOrder = prevOrder + 1
      navigate(showcase[newOrder].path)
      return newOrder
    })
  }

  function exitShowcase(preventRedirectToGuide) {
    localStorage.setItem('showCaseInitial', true)
    setShowcaseOrder(-1)
    setStartShowcase(false)
    if (!modalExpired && !preventRedirectToGuide) {
      setPrimaryButton(true)
      navigate('guide')
    }
  }

  return {
    isLeft,
    currentShowcase,
    menusRef,
    startShowcase,
    replayShowcase,
    bounding,
    setBounding,
    currentPage,
    lastPage,
    isFirst,
    isLast,
    onClickStartTour,
    prevShowcase,
    nextShowcase,
    exitShowcase
  }
}
