import styled, { css } from 'styled-components'
import { Text } from '@qlue/react-component/dist/QlueTypography'
import theme from '@qlue/react-component/dist/styles/theme'

export const ContainerMenu = styled.div`
  .sub-menu-item {
    background: ${theme.primaryBackground};
  }
  .sub-menu-item:hover {
    background: ${theme.primaryBackgroundDarker};
  }
`

export const Main = styled.main`
  display: flex;
  > :first-child {
    width: ${props => (props.rightBarOpen ? 'calc(100% - 297px)' : '100%')};
    /* hotfix for modal detail events intrusion */
    z-index: ${props => (props.isEventsPage ? '0' : '1')};
  }

  > img.bali-accent {
    position: fixed;
    bottom: 0;
    right: 0;
  }
`

export const StyledText = styled(Text)`
  ${props =>
    props.size &&
    css`
      font-size: ${`${props.size}px`};
    `};
`
