import { isLocalhost } from 'serviceWorker'
import getCookie from './getCookie'
import removeCookie from './removeCookie'

const STORAGE_KEY = 'auth'
const { localStorage } = window

const TOKEN_NAME = process.env.REACT_APP_TOKEN_NAME || 'sessionDashLocal'
const REFRESH_TOKEN_NAME = process.env.REACT_APP_REFRESH_TOKEN_NAME || 'refreshDashLocal'
const TRIGGER_STORAGE = process.env.REACT_APP_TRIGGER_STORAGE || 'triggerStorageLocal'
const COMPANY_ID_NAME = 'companyId'
const DEFAULT_PATH = 'defaultPath'
const ROLE_ID = 'roleId'
const USER_ID = 'userId'

const encryptToken = token => {
  //1. convert to array
  const tokenArr = token.split('')

  //2. reverse array
  const reverseTokenArr = tokenArr.reverse()

  //3. convert to list of ASCII
  const listASCII = reverseTokenArr.map(letter => letter.charCodeAt(0))

  //4. generate new list ASCII -> newASCII = currentASCII + index%2
  const newListASCII = listASCII.map((code, index) => code + (index % 2))

  //5. convert new list ASCII to list alphabet
  const listAlphabet = newListASCII.map(code => String.fromCharCode(code))

  //6. convert listAlphabet to formatted string token
  const encryptedToken = listAlphabet.join('')

  return encryptedToken
}

const decryptToken = token => {
  //1. convert to array
  const tokenArr = token.split('')

  //2. convert to list of ASCII
  const listASCII = tokenArr.map(alphabet => alphabet.charCodeAt(0))

  //3. generate real list ASCII -> realASCII = currentASCII - index%2
  const realListASCII = listASCII.map((code, index) => code - (index % 2))

  //4. convert real list ASCII to list alphabet
  const listAlphabet = realListASCII.map(code => String.fromCharCode(code))

  //5. reverse array
  const reverseListAlphabet = listAlphabet.reverse()

  //6. convert listAlphabet to formatted string token
  const decryptedToken = reverseListAlphabet.join('')

  return decryptedToken
}

const domain = !isLocalhost ? 'domain=qlue.ai' : ''

export const setAuthStorage = (state, isSessionStorage) => {
  const companyInfo = {
    [COMPANY_ID_NAME]: state.companyId,
    [DEFAULT_PATH]: state.defaultPath,
    [ROLE_ID]: state.roleId,
    [USER_ID]: state.userId
  }
  if (isSessionStorage) {
    sessionStorage.setItem(STORAGE_KEY, JSON.stringify(companyInfo))
  } else {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(companyInfo))
  }
  removeCookie(TRIGGER_STORAGE)
}

export const saveLogin = (state, notRememberMe) => {
  /**
   * Resolved Problems [1] [2]
   * utils/README.md
   * @see https://stackoverflow.com/questions/5671451/creating-a-javascript-cookie-on-a-domain-and-reading-it-across-sub-domains
   * @see https://en.wikipedia.org/wiki/HTTP_cookie#Session_cookie
   * */

  if (notRememberMe) {
    //session cookies
    document.cookie = `${TOKEN_NAME}=${encryptToken(state.token)}; path=/; ${domain}`
    document.cookie = `${REFRESH_TOKEN_NAME}=${encryptToken(state.refreshToken)}; path=/; ${domain}`

    setAuthStorage(state, true)
  } else {
    //persistent cookies
    document.cookie = `${TOKEN_NAME}=${encryptToken(
      state.token
    )}; path=/; expires=Fri, 31 Dec 9999 23:59:59 GMT; ${domain}`
    document.cookie = `${REFRESH_TOKEN_NAME}=${encryptToken(
      state.refreshToken
    )}; path=/; expires=Fri, 31 Dec 9999 23:59:59 GMT; ${domain}`

    setAuthStorage(state, false)
  }
}

export const loadLogin = () => {
  let data = {}
  data.token = getCookie(TOKEN_NAME) && decryptToken(getCookie(TOKEN_NAME))
  data.refreshToken = getCookie(REFRESH_TOKEN_NAME) && decryptToken(getCookie(REFRESH_TOKEN_NAME))

  if (localStorage.getItem('not_rem_me'))
    data = { ...JSON.parse(sessionStorage.getItem(STORAGE_KEY)), ...data }
  else data = { ...JSON.parse(localStorage.getItem(STORAGE_KEY)), ...data }

  return data
}

export const getToken = () => {
  const cred = loadLogin()
  return cred || {}
}

const oldCookies = ['sessionDash', 'refreshDash', 'sessionDashFixed', 'refreshDashFixed']

export const resetLogin = () => {
  localStorage.removeItem(STORAGE_KEY)
  sessionStorage.removeItem(STORAGE_KEY)
  const savedGettingStarted = localStorage.getItem('savedGettingStarted')
  const showCaseInitial = localStorage.getItem('showCaseInitial')
  const notRememberMe = localStorage.getItem('not_rem_me')
  localStorage.clear()
  sessionStorage.clear()

  // remove old cookie
  for (let i = 0; i < oldCookies.length; i += 1) {
    document.cookie = `${oldCookies[i]}=; Path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT; ${domain}`
  }

  document.cookie = `${TOKEN_NAME}=; Path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT; ${domain}`
  document.cookie = `${REFRESH_TOKEN_NAME}=; Path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT; ${domain}`

  if (savedGettingStarted) localStorage.setItem('savedGettingStarted', savedGettingStarted)
  if (showCaseInitial) localStorage.setItem('showCaseInitial', showCaseInitial)
  if (notRememberMe) localStorage.setItem('not_rem_me', notRememberMe)
}
