import React from 'react'
import PropTypes from 'prop-types'
import Styled from './styles'
import { Text } from '@qlue/react-component/dist/QlueTypography'
import theme from '@qlue/react-component/dist/styles/theme'
import { IconClose } from '@qlue/react-component/dist/QlueIcon'

function ItemSubscription({ id, isUnread, src, text, clickableText, timestamp, onClick }) {
  return (
    <Styled.Wrapper isUnread={isUnread}>
      <div className="icon-close" onClick={() => onClick({ type: 'close', id })}>
        <IconClose />
      </div>
      {isUnread && (
        <Text className="mark-as-read" onClick={() => onClick({ type: 'mark', id })}>
          mark as read
        </Text>
      )}
      <Styled.Content onClick={() => onClick({ type: 'content', id })}>
        <div className="avatar">
          <img alt="avatar" src={src} />
        </div>
        <div className="content">
          <div>
            <div>
              <Text>{text}</Text>
            </div>
            <div>
              <Text color={theme.primaryColor}>{clickableText}</Text>
            </div>
          </div>
          <Text color={theme.primaryTextColorTrans}>1 minute ago</Text>
        </div>
      </Styled.Content>
    </Styled.Wrapper>
  )
}

ItemSubscription.defaultProps = {
  isUnread: false,
  id: '',
  src: '',
  text: '',
  clickableText: '',
  timestamp: 0,
  onClick: () => null
}

ItemSubscription.propTypes = {
  isUnread: PropTypes.bool,
  id: PropTypes.string,
  src: PropTypes.string,
  text: PropTypes.string,
  clickableText: PropTypes.string,
  timestamp: PropTypes.number,
  onClick: PropTypes.func
}

export default ItemSubscription
