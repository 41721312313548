import APIConfig from './config'

export default APIConfig
export { default as AuthURL } from './URL/auth'
export { default as AnalyticURL } from './URL/analytic'
export { default as DivisionURL } from './URL/division'
export { default as RoleURL } from './URL/role'
export { default as UserURL } from './URL/user'
export { default as CategoryURL } from './URL/category'
export { default as WorkgroupURL } from './URL/workgroup'
export { default as GeomURL } from './URL/geom'
export { default as TrackingURL } from './URL/tracking'
export { default as TaskURL } from './URL/task'
export { default as CompanyURL } from './URL/company'
export { default as ModuleURL } from './URL/module'
export { default as SiteURL } from './URL/site'
export { default as EngineURL } from './URL/engine'
export { default as LabelURL } from './URL/label'
export { default as EnrollmentURL } from './URL/enrollment'
export { default as LayersURL } from './URL/layers'
export { default as LiveFeedsURL } from './URL/livefeeds'
export { default as FaceURL } from './URL/face'
export { default as FaceMaskURL } from './URL/faceMask'
export { default as PeopleCountingURL } from './URL/peopleCounting'
export { default as PeopleZoneURL } from './URL/pzc'
export { default as PlateURL } from './URL/plate'
export { default as IndustryURL } from './URL/industry'
export { default as IntrusionURL } from './URL/intrusion'
export { default as CCTVUrl } from './URL/cctv'
export { default as TimeFrameURL } from './URL/timeFrame'
export { default as VehicleURL } from './URL/vehicle'
export { default as IllegalParkingURL } from './URL/illegalParking'
export { default as SmartDevicesURL } from './URL/smartdevices'
export { default as ThermalURL } from './URL/thermal'
export { default as AttendanceURL } from './URL/attendance'
export { default as AttendanceOldURL } from './URL/attendanceOld'
export { default as ShiftURL } from './URL/shift'
export { default as EmployeeURL } from './URL/employee'
export { default as DTCVehicleURL } from './URL/dtcVehicle'
export { default as DTCPeopleURL } from './URL/dtcPeople'
export { default as CCTVAgentURL } from './URL/cctvAgent'
export { default as CCTVStreamURL } from './URL/cctvStream'
export { default as GuideURL } from './URL/guide'
export { default as PotholeURL } from './URL/pothole'
export { default as EventURL } from './URL/event'
export { default as AnomalyDetectionURL } from './URL/vad'
export { default as PPEDetectionURL } from './URL/ppe'
export { default as PowerMonitoringURL } from './URL/powerMonitoring'
export { default as BioniqaURL } from './URL/bioniqa'
