import React, { useEffect, useRef, useState } from 'react'
import Styled from './styles'
import { H3 } from '@qlue/react-component/dist/QlueTypography'
import { Conditional } from '@qlue/react-component/dist/utils'
import { IconClose } from '@qlue/react-component/dist/QlueIcon'
import NotificationList from './List'

function Notification({ onClose, setIsAllRead }) {
  const wrapperRef = useRef(null)
  // eslint-disable-next-line no-unused-vars
  const [active, setActive] = useState('All')

  useEffect(() => {
    const handleClickOutside = event => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        onClose()
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [onClose])

  return (
    <Styled.DetailWrapper ref={wrapperRef}>
      <div className="icon-close" onClick={onClose}>
        <IconClose />
      </div>
      <Styled.Header>
        <div className="title">
          <H3 className="fw5">Notification</H3>
        </div>
        <div className="menu mt2"></div>
        <Styled.Line />
      </Styled.Header>
      <Styled.Content>
        <Conditional if={active === 'All'}>
          <NotificationList setIsAllRead={setIsAllRead} />
        </Conditional>
        <Conditional if={active === 'Workgroup'}>Under Construction</Conditional>
        <Conditional if={active === 'Subscription'}>Under Construction</Conditional>
      </Styled.Content>
    </Styled.DetailWrapper>
  )
}

export default Notification
