import { useState, useEffect, useCallback } from 'react'
import carto from '@carto/carto.js'

const {
  REACT_APP_CARTO_URI_HTTPS,
  REACT_APP_CARTO_API_KEY_HTTPS,
  REACT_APP_CARTO_USERNAME_HTTPS
} = process.env

const Carto = ({ map, activeLayers, layers, name }) => {
  const [initialized, setInitialized] = useState(false)
  const [cartoClient] = useState(
    new carto.Client({
      apiKey: REACT_APP_CARTO_API_KEY_HTTPS,
      username: REACT_APP_CARTO_USERNAME_HTTPS,
      serverUrl: REACT_APP_CARTO_URI_HTTPS
    })
  )

  useEffect(() => {
    new cartoClient.getLeafletLayer().options.zIndex = 100
  }, [cartoClient])

  const hideShowLayer = useCallback(() => {
    const allLayers = cartoClient.getLayers()
    for (let i = 0; i < allLayers.length; i += 1) {
      let find = false
      for (let k = 0; k < activeLayers.length; k += 1) {
        let activeSql = ''
        for (let index = 0; index < layers.length; index += 1) {
          if (activeLayers[k] === layers[index].id) {
            activeSql = layers[index].SQL
            index = allLayers.length
          }
        }
        const sql = allLayers[i].getSource()._query
        if (activeSql === sql) {
          find = true
        }
      }
      if (!find) {
        allLayers[i].hide()
      } else {
        allLayers[i].show()
      }
    }
  }, [activeLayers, cartoClient, layers])

  const executeSpan = (fe, data) => {
    const featureEvent = []
    if (data.length > 0) {
      data.forEach(item => {
        featureEvent.push(
          `
            <tr><td style="color: #a2a2a2">${item.displayName}</td></tr>
            <tr><td>${fe[item.columnName]}</td></tr>
          `
        )
      })
      let result = featureEvent.toString()
      result = result.split(',').join('')
      return result
    }
  }

  const initializeCarto = useCallback(
    init => {
      const mapLayers = layers.map(x => {
        const cartoSource = new carto.source.SQL(x.SQL)
        const css = x.CSS.replace(String.fromCharCode(92), String.fromCharCode(92, 92))
        const cartoCSS = new carto.style.CartoCSS(`${css}`)
        const columnNames = x.subLayerColumns ? x.subLayerColumns.map(col => col.columnName) : []
        const layer =
          x.subLayerColumns && x.subLayerColumns.length > 0
            ? new carto.layer.Layer(cartoSource, cartoCSS, {
                featureClickColumns: columnNames,
                visible: false
              })
            : new carto.layer.Layer(cartoSource, cartoCSS, { visible: false })
        layer.on('featureClicked', fe => {
          const popupHTML = `
            <div class="popup-container">
              <div>
                <table>
                  <tr><td style="font-weight: 500; padding-bottom: 10px; text-align: center;">${name}</td></tr>
                  ${executeSpan(fe.data, x.subLayerColumns)}
                </table>
              </div>
            </div>
          `
          map.leafletElement.openPopup(popupHTML, [fe.latLng.lat, fe.latLng.lng])
        })

        return {
          ...x,
          show: false,
          isPolygon: true,
          layer
        }
      })
      if (init) {
        if (mapLayers.length > 0) {
          setInitialized(true)
        }
        cartoClient.addLayers(mapLayers.map(x => x.layer)).then(() => {
          hideShowLayer(mapLayers)
          cartoClient.getLeafletLayer().addTo(map.leafletElement)
        })
      } else {
        hideShowLayer(mapLayers)
      }
    },
    [cartoClient, hideShowLayer, layers, map.leafletElement, name]
  )

  const destroyCarto = useCallback(() => {
    const allLayers = cartoClient.getLayers()
    cartoClient.removeLayers(allLayers).then(() => console.log('Layers Removed'))
  }, [cartoClient])

  useEffect(() => {
    if (!initialized) {
      initializeCarto(true)
    }
  }, [initialized, initializeCarto])

  useEffect(() => {
    return () => {
      destroyCarto()
    }
  }, [destroyCarto])

  useEffect(() => {
    if (initialized) {
      initializeCarto(false)
    }
  }, [activeLayers, initialized, initializeCarto])

  return null
}

export default Carto
