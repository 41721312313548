const API = process.env.REACT_APP_API_URL_BASE_API

const GetAnalytics = `${API}/pzc/analytics`
const GetAnalyticsDetail = `${API}/pzc/analytics/detail`
const GetInitialCounting = `${API}/pzc/count/`
const GetDetailOfAnalytics = `${API}/pzc/heatmap`
const GetHistoryList = `${API}/pzc/history`
const GetHistoryDetail = `${API}/pzc/history/detail`
const DownloadHistory = `${API}/pzc/history/download`
const GetHeatmap = cctvId => `${API}/pzc/new-heatmap/${cctvId}`
const GetHeatmapAnalytic = cctvId => `${API}/pzc/new-heatmap/analytic/${cctvId}`
const DownloadHeatmap = cctvId => `${API}/pzc/new-heatmap/download/${cctvId}`

export default {
  GetAnalytics,
  GetAnalyticsDetail,
  GetInitialCounting,
  GetDetailOfAnalytics,
  GetHistoryList,
  GetHistoryDetail,
  DownloadHistory,
  GetHeatmap,
  GetHeatmapAnalytic,
  DownloadHeatmap
}
