const API = process.env.REACT_APP_API_URL_BASE_API

const GetList = `${API}/category`
const GetAvailableCategory = `${API}/category/dashboard`
const AssignCategory = `${API}/category/company`
const ToggleCompanyCategoryStatus = `${API}/category/company/toggle-status`
const RequestCategory = `${API}/category/request`

export default {
  GetList,
  GetAvailableCategory,
  AssignCategory,
  ToggleCompanyCategoryStatus,
  RequestCategory
}
