import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { TrackingURL } from 'commons/API'
import useEventSource from 'commons/hooks/useEventSource'
import {
  UPDATE_SPECIFIC_OBJECT_IN_ARRAY,
  UPDATE_ARRAY_OF_ARRAY_WITH_PREVIOUS
} from '../locals/constants'
import { formattedTime } from '../utils'

function useSSE({ state, dispatch, id, type, eventType = 'progress' }) {
  const { token } = useSelector(({ login }) => login.token)
  const detailRequest = state?.requests.find(request => request.id === id) || {}
  const eventName =
    {
      progress: `rpd_progress_${type}_${token}`,
      'rpd-snapshot': `snapshot_rpd_${detailRequest.potholeId}`,
      'rpd-validation': `validation_rpd_${detailRequest.potholeId}`
    }[eventType] || ''
  const url = `${TrackingURL.GetLiveUser}?token=${token}`
  const [isOpen, setIsOpen] = useState(() => {
    return {
      'rpd-snapshot': false,
      'rpd-validation': false,
      progress: true
    }[eventType]
  })
  const { eventData } = useEventSource(url, eventName, isOpen)
  useEffectByEventType({ dispatch, eventType, detailRequest, eventData, setIsOpen, id })
}

function useEffectByEventType({ dispatch, eventType, detailRequest, eventData, setIsOpen, id }) {
  useEffect(() => {
    if (eventType === 'rpd-snapshot' || eventType === 'rpd-validation') {
      if (detailRequest.potholeId) setIsOpen(true)
      else setIsOpen(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailRequest.potholeId])

  useEffect(() => {
    if (Object.keys(eventData).length) {
      const getError = () => {
        const err = {
          'not found': {
            is: true,
            msg: 'Sorry! GPS file are not available on your video metadata'
          },
          'unsupported type': { is: true, msg: 'Sorry! Your GPS file are not supported' },
          'invalid video metadata': {
            is: true,
            msg: 'Sorry! Your metadata on GPS File is invalid'
          },
          failed: {
            is: true,
            msg: 'Failed Process! Sorry for your inconvenience. Please try again in few minutes'
          },
          'unsupported gps file': { is: true, msg: 'Sorry! Your GPS file are not supported' },
          'gps not found': {
            is: true,
            msg: 'Sorry! GPS file are not available on your video metadata'
          }
        }[eventData.status] || { is: false, msg: '' }
        return err
      }
      if (eventType === 'progress') {
        const progress = (eventData.percentage / 10).toFixed(1)
        if (progress < 10) {
          dispatch({
            type: UPDATE_SPECIFIC_OBJECT_IN_ARRAY,
            key: 'requests',
            value: {
              id,
              progress
            }
          })
        } else {
          dispatch({
            type: UPDATE_SPECIFIC_OBJECT_IN_ARRAY,
            key: 'requests',
            value: {
              id,
              loading: false,
              progress: 10,
              finish: true,
              url: eventData.url
            }
          })
        }
      } else if (eventType === 'rpd-snapshot') {
        if (getError().is) {
          dispatch({
            type: UPDATE_SPECIFIC_OBJECT_IN_ARRAY,
            key: 'requests',
            value: {
              id,
              loading: false,
              progress: 10,
              finish: false,
              error: getError().is,
              errorMsg: getError().msg
            }
          })
        } else {
          if (eventData.snapshotBase64) {
            const obj = {
              id: eventData.id,
              time: formattedTime(eventData.videoSec * 60),
              imgUrl: `data:image/png;base64,${eventData.snapshotBase64}`,
              address: eventData.address
            }
            dispatch({
              type: UPDATE_ARRAY_OF_ARRAY_WITH_PREVIOUS,
              key: 'requests',
              id,
              childKey: 'realtimeSnapshots',
              value: obj
            })
          }
        }
      } else if (eventType === 'rpd-validation') {
        if (getError().is) {
          dispatch({
            type: UPDATE_SPECIFIC_OBJECT_IN_ARRAY,
            key: 'requests',
            value: {
              id,
              loading: false,
              progress: 10,
              finish: false,
              error: getError().is,
              errorMsg: getError().msg
            }
          })
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventData])

  useEffect(() => {
    if (eventType === 'rpd-snapshot' || eventType === 'rpd-validation') {
      if (
        !detailRequest.error &&
        !detailRequest.finish &&
        detailRequest.loading &&
        eventData.id === detailRequest.potholeId
      ) {
        const currentProgress = eventData.progressPercent / 10
        if (detailRequest.progress < currentProgress) {
          dispatch({
            type: UPDATE_SPECIFIC_OBJECT_IN_ARRAY,
            key: 'requests',
            value: {
              id,
              progress: currentProgress
            }
          })
        }
        if (eventData.progressPercent >= 100) {
          dispatch({
            type: UPDATE_SPECIFIC_OBJECT_IN_ARRAY,
            key: 'requests',
            value: { id, progress: 10, finish: true, loading: false }
          })
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventData, detailRequest])
}

export default useSSE
