import { SET_NOTIFICATION, SET_TOKEN_FCM, REPLACE_DATA_NOTIFICATIONS } from '../constants'

const initialState = {
  data: [],
  token: null
}

export default function notification(state = initialState, { type, payload }) {
  switch (type) {
    case REPLACE_DATA_NOTIFICATIONS: {
      return {
        ...state,
        data: payload
      }
    }
    case SET_NOTIFICATION: {
      return {
        ...state,
        data: [payload, ...state.data]
      }
    }
    case SET_TOKEN_FCM: {
      return {
        ...state,
        token: payload
      }
    }
    default:
      return state
  }
}
