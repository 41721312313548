import styled, { css } from 'styled-components'
import theme from '@qlue/react-component/dist/styles/theme'

export default {
  Wrapper: styled.div`
    position: relative;
    padding: 12px 26px;
    margin-bottom: 1px;
    font-size: 12px;

    ${props =>
      props.isRead
        ? css`
            background-color: initial;
            :hover {
              background-color: #242a39;
            }
          `
        : css`
            background-color: #303747;
            :hover {
              background-color: #242a39;
            }
          `};

    > div.icon-close {
      display: none;
      position: absolute;
      top: 10px;
      right: 15px;
      width: 10px;
      height: 10px;
      cursor: pointer;
    }

    > .mark-as-read {
      position: absolute;
      bottom: 8px;
      right: 15px;
      cursor: pointer;
      display: none;

      :hover {
        text-decoration: underline;
        color: ${theme.primaryColor};
      }
    }

    ${props =>
      !props.isRead &&
      css`
        :hover {
          .mark-as-read {
            display: block;
          }
        }
      `};
  `,
  Content: styled.div`
    height: 90px;
    cursor: pointer;
    display: flex;

    > div.avatar {
      width: 86px;
      height: 86px;
      border-radius: 4px;
      overflow: hidden;
      margin-right: 8px;
      background: #fff;

      > img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    > div.content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 320px;

      > span {
        display: block;
      }

      > div {
        display: flex;
        align-items: center;
      }

      > div > svg.icon-cctv {
        width: 20px;
        height: 20px;
        margin-right: 8px;
      }

      > div > svg.icon-time {
        width: 16px;
        height: 16px;
        margin-right: 12px;
      }
    }
  `
}
