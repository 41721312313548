import styled from 'styled-components'
import theme from '@qlue/react-component/dist/styles/theme'
import layerIcon from 'commons/assets/images/layer.svg'

const getMapContainerWidth = ({ leftbarIsExpanded, rightbarIsExpanded, detailIsExpanded }) => {
  if (detailIsExpanded && !leftbarIsExpanded && !rightbarIsExpanded) {
    return '50px'
  }
  if (detailIsExpanded && !rightbarIsExpanded) {
    return '240px'
  }
  if (detailIsExpanded && leftbarIsExpanded) {
    return '740px'
  }
  if (detailIsExpanded && !leftbarIsExpanded) {
    return '550px'
  }
  if (leftbarIsExpanded && rightbarIsExpanded) {
    return '500px'
  }
  if (!leftbarIsExpanded && rightbarIsExpanded) {
    return '310px'
  }
  if (leftbarIsExpanded && !rightbarIsExpanded) {
    return '240px'
  }
  return '50px'
}

const MapsContainer = styled.div`
  position: relative;
  width: calc(100vw - ${props => getMapContainerWidth(props)});

  ${props => (!props.leftbarTriggered ? 'transition: all 0.3s ease-in-out' : null)}

  .leaflet-container {
    z-index: 0;
  }

  .leaflet-control-layers-toggle {
    background: ${theme.primaryBackground};
  }

  .leaflet-touch .leaflet-control-layers.leaflet-control .leaflet-control-layers-toggle {
    height: 30px;
    width: 30px;
  }

  .leaflet-retina .leaflet-control-layers.leaflet-control .leaflet-control-layers-toggle {
    background-image: url(${layerIcon});
    background-size: 20px;
  }

  .leaflet-control.leaflet-control-layers-expanded {
    background: ${theme.primaryBackground};
    color: ${theme.primaryTextColor};
  }

  .leaflet-control-zoom.leaflet-bar.leaflet-control .leaflet-control-zoom-in,
  .leaflet-control-zoom.leaflet-bar.leaflet-control .leaflet-control-zoom-out {
    background: ${theme.primaryBackground};
    color: rgb(1, 134, 213);
    width: 28px;
  }

  .leaflet-control-zoom.leaflet-bar.leaflet-control .leaflet-control-zoom-in {
    border-bottom: 1px solid rgb(1, 134, 213);
  }

  .base-map {
    .leaflet-tooltip {
      padding: 0px;
      border: none;
      outline: none;
      color: ${theme.primaryTextColor};
      width: 280px;
      border-radius: 1rem;
      background-color: ${theme.primaryTextColor};
      box-shadow: none;
    }

    .leaflet-tooltip-left:before {
      border-left-color: ${theme.primaryTextColor};
    }

    .leaflet-tooltip-right:before {
      border-right-color: ${theme.primaryTextColor};
    }
  }
`

export const MarkerDiv = styled.div`
  width: 40px;
  height: 40px;
  position: relative;

  > .category {
    position: absolute;
    z-index: 1;
    top: 0.25rem;
    left: 0.45rem;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: ${theme.primaryColor};
    display: flex;
    justify-content: center;
    align-items: center;

    > img {
      width: 20px;
      height: 20px;
    }
  }
`

export default MapsContainer
