// dependency libraries
import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import BlockContainer from '@qlue/react-component/dist/BlockContainer'
import TabContainer from '@qlue/react-component/dist/TabContainer'

// root modules
import {
  generateElementID,
  EVENT_PAGE_ID_PREFIX,
  ANALYTICS_PAGE_ID_PREFIX,
  SETTING_PAGE_ID_PREFIX
} from 'utils/generateElementID'

const Tab = ({
  tabs,
  title = '',
  handleReturn,
  handleTab,
  hasBackNavigation,
  selected: selectedProps,
  isLoading = false,
  tabWidth,
  highlight,
  onBounding,
  eachTabWidth
}) => {
  const [selected, setSelected] = useState(selectedProps)

  useEffect(() => {
    setSelected(selectedProps)
  }, [selectedProps])

  const handleClick = useCallback(
    value => {
      setSelected(value.id)
      handleTab(tabs.find(t => t.id === value.id))
    },
    [handleTab, tabs]
  )

  const handleComponentId = title => {
    let id = ''
    switch (title) {
      case 'Analytic':
        id = generateElementID(ANALYTICS_PAGE_ID_PREFIX, 'teksanalytic')
        break
      case 'Events':
        id = generateElementID(EVENT_PAGE_ID_PREFIX, 'teksevent')
        break
      case 'Settings':
      default:
        id = generateElementID(SETTING_PAGE_ID_PREFIX, 'tekssetting')
        break
    }

    return id
  }

  return (
    <BlockContainer className="mb2 ph4">
      <TabContainer
        isLoading={isLoading}
        title={title}
        tabs={tabs}
        handleClick={handleClick}
        defaultSelected={selected}
        isChild={hasBackNavigation}
        handleReturn={handleReturn}
        hasHorizontalScroll={true}
        width={tabWidth}
        tabWidth={eachTabWidth}
        highlight={highlight}
        onBounding={onBounding}
        type={1}
        id={handleComponentId(title)}
      />
    </BlockContainer>
  )
}

Tab.defaultProps = {
  selected: 1,
  handleReturn: () => {},
  hasBackNavigation: '',
  tabWidth: '60%'
}

Tab.propTypes = {
  handleReturn: PropTypes.func,
  handleTab: PropTypes.func,
  hasBackNavigation: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  selected: PropTypes.number,
  tabs: PropTypes.arrayOf(PropTypes.any),
  title: PropTypes.string,
  tabWidth: PropTypes.string
}

export default Tab
