import { useState, useEffect } from 'react'
import API from 'commons/API'
import { useStore } from 'shells/AppShell'
import getNewToken from 'utils/getNewToken'

export default ({
  url = '',
  method = 'get',
  initialFetch: initialFetchProps,
  initialState = {},
  initialParams = {},
  initialBody = {},
  isLoading: isLoadingProps = false,
  throwError = true,
  separateRefetch = false,
  exceptErrorCode
}) => {
  const { setModalExpired } = useStore()
  const [initialFetch, refetch] = useState(initialFetchProps)
  const [isLoading, setIsLoading] = useState(isLoadingProps)
  const [isError, setIsError] = useState(false)
  const [error, setError] = useState(null)
  const [params, setParams] = useState(initialParams)
  const [body, setBody] = useState(initialBody)
  const [data, setData] = useState(initialState)
  /**
   * UseEffect below only run once to execute refetch at initial call
   * If params has been set or changed from the initialParams and initialFetch value is false
   */
  useEffect(() => {
    if (!initialFetch && !separateRefetch) {
      refetch(true)
    }
    // eslint-disable-next-line
  }, [JSON.stringify(params), JSON.stringify(body)])

  useEffect(() => {
    ;(async () => {
      if (initialFetch) {
        setIsLoading(true)
        try {
          const args = method === 'get' ? [url, { params }] : [url, { ...body }, { params }]
          const { data } = await API[method](...args)
          setIsError(false)
          setError(null)
          setData(data)
          setIsLoading(false)
          refetch(false)
        } catch (e) {
          const {
            response: {
              status,
              data: { errorCode, message }
            }
          } = e
          setIsError(true)
          setError(e)
          setIsLoading(false)
          refetch(false)
          if (throwError) {
            if (status >= 500) alert({ type: 'failed', msg: 'Something went wrong on our server' })
            else if (errorCode) {
              if (exceptErrorCode !== errorCode) {
                if (errorCode === 197) {
                  // Condition when company is Expired
                  getNewToken({ setModalExpired })
                } else alert({ type: 'failed', msg: message })
              }
            } else alert({ type: 'failed', msg: e.message })
            // if role not found auto logout (e.errorCode === 36)
            setIsLoading(false)
            throw new Error(e)
          }
        }
      }
    })()
  }, [initialFetch, params, body, url, method, throwError, setModalExpired, exceptErrorCode])

  return { isLoading, isError, error, params, data, refetch, setParams, body, setBody, setData }
}
