import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { H3, Text, Paragraph } from '@qlue/react-component/dist/QlueTypography'
import theme from '@qlue/react-component/dist/styles/theme'
import { IconArrow, IconClose } from '@qlue/react-component/dist/QlueIcon'
import { ArrayMap } from '@qlue/react-component/dist/utils'
import { reload } from 'commons/assets/images'
import { useStore } from 'shells/AppShell'
import Styled from './styles'
import ContentGettingStarted from './Content'

let timeout = null
let slideTimer = null
let slideTimerLeft = null

function GettingStarted({ isAdmin, onClose }) {
  const { data: module } = useSelector(({ module }) => module.module)
  const {
    showcase: { replayShowcase }
  } = useStore()
  const wrapperRef = useRef(null)
  const [active, setActive] = useState('Analytic')
  const [showArrow, setShowArrow] = useState(false)
  const [node, setNode] = useState(null)

  const dataModule = useMemo(() => {
    if (isAdmin)
      return [
        {
          id: 1,
          name: 'Profile',
          path: 'profile'
        },
        ...module
      ]
    return module
  }, [isAdmin, module])

  useEffect(() => {
    setActive(dataModule[0].name)
  }, [dataModule])

  useEffect(() => {
    const handleClickOutside = event => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        onClose()
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      clearInterval(slideTimer)
    }
  }, [onClose])

  const measuredRef = useCallback(
    node => {
      if (node !== null && dataModule.length) {
        window.f = node
        setNode(node)
        setShowArrow(node.scrollWidth > node.offsetWidth)
      }
    },
    [dataModule.length]
  )

  const scrollAction = type => {
    if (timeout) clearTimeout(timeout)
    timeout = setTimeout(() => {
      if (type === 'right') {
        let scrollAmount = 0
        slideTimer = setInterval(() => {
          node.scrollLeft += 10
          scrollAmount += 10
          if (scrollAmount >= 100) {
            clearInterval(slideTimer)
          }
        }, 25)
      } else {
        let scrollAmount = 100
        slideTimerLeft = setInterval(() => {
          node.scrollLeft -= 10
          scrollAmount -= 10
          if (scrollAmount <= 0) {
            clearInterval(slideTimerLeft)
          }
        }, 25)
      }
    }, 200)
  }

  const onReplayShowcase = () => {
    replayShowcase()
  }

  return (
    <Styled.DetailWrapper ref={wrapperRef}>
      <Styled.Header>
        <div className="icon-close" onClick={onClose}>
          <IconClose />
        </div>
        <div className="title">
          <H3 className="fw5">Gettings Started</H3>
          <div
            className="d-flex align-center pointer ml3"
            role="presentation"
            onClick={onReplayShowcase}
          >
            <img width="13px" height="15px" src={reload} alt="reload" />
            <Text className="fw5 fs-14px ml2" color={theme.primaryColor}>
              Replay Showcase
            </Text>
          </div>
        </div>
        <Paragraph className="sub-title fs-18px pv2">
          These tasks are going to make you an expert in using Qlue Dashboard!
        </Paragraph>
        <div className="menu mt2" ref={measuredRef}>
          <div className="tab">
            <ArrayMap data={dataModule}>
              {({ name }) => (
                <Styled.Tab
                  className="fs-14px fw5 pointer"
                  role="presentation"
                  active={active === name}
                  onClick={() => setActive(name)}
                >
                  {name}
                </Styled.Tab>
              )}
            </ArrayMap>
          </div>
        </div>
        <Styled.Line />
        {showArrow && (
          <IconArrow
            onClick={() => scrollAction('left')}
            className="arrow-left"
            color={theme.primaryColor}
          />
        )}
        {showArrow && (
          <IconArrow
            onClick={() => scrollAction('right')}
            className="arrow-right"
            color={theme.primaryColor}
          />
        )}
      </Styled.Header>
      <Styled.Content>
        <ContentGettingStarted type={active} />
      </Styled.Content>
    </Styled.DetailWrapper>
  )
}

export default GettingStarted
