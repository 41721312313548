import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { UnorderedList } from '@qlue/react-component/dist/List'
import DisplayGrid from '@qlue/react-component/dist/DisplayGrid'
import ToggleButton from '@qlue/react-component/dist/ToggleButton'
import MenuItem from '@qlue/react-component/dist/MenuItem'
import { FlexBox } from '@qlue/react-component/dist/FlexBox'
import { resetLogin } from 'utils/auth'
import { Redirect } from '@reach/router'
import { getSidebarIcon } from 'utils'
import { generateElementID, MENU_PAGE_ID_PREFIX } from 'utils/generateElementID'
import getDetailCompany from 'stores/action/getDetailCompany'
import useDispatch from 'commons/hooks/useDispatch'
import { useSelector } from 'react-redux'
import { Text } from '@qlue/react-component/dist/QlueTypography'
import theme from '@qlue/react-component/dist/styles/theme'
import AsideProfile from './AsideProfile'

const GRID1 = 'FIRST_GRID'
const GRID2 = 'SECOND_GRID'
const GRID3 = 'THIRD_GRID'

const hardcode = {
  isAdmin: roleId => roleId === 'd2e7ae6a-37b5-4130-8c86-9ec3507d7071'
}

const Sidebar = ({ className, grid, RenderTree, tree, changeGrid, navigate, path }) => {
  const companyDetail = useSelector(({ companyDetail }) => companyDetail)
  const roleId = useSelector(({ login }) => login.token.roleId)
  const company = companyDetail.data
  const [isLogout, setIsLogout] = useState(false)
  const fetchDetailCompany = useDispatch(getDetailCompany)

  function onClickViewProfile() {
    if (path !== 'profile') {
      navigate('/profile/info')
    }
  }

  const gridStyle = {
    [GRID2]: {
      size: 'small',
      onlyPicture: true,
      className: 'pv3 tc',
      gridTemplateRows: 'unset'
    },
    [GRID3]: {
      size: 'big',
      onlyPicture: false,
      className: 'pa3 pt4',
      gridTemplateRows: '270px 1fr'
    }
  }
  const cn = `${className} relative`
  const profileProps = {
    avatar: {
      src: company && company.logo,
      alt: company && company.name,
      size: gridStyle[grid].size
    },
    companyName: (company && company.name) || '',
    greetingText: company?.createdFromSaaS ? company?.packagePlan : '',
    greetingName: hardcode.isAdmin(roleId) ? (
      <Text
        className="fw5 pointer"
        color={theme.primaryColor}
        onClick={onClickViewProfile}
        id={generateElementID(MENU_PAGE_ID_PREFIX, 'viewprofile')}
      >
        View Profile
      </Text>
    ) : (
      ''
    ),
    className: gridStyle[grid].className,
    onlyPicture: gridStyle[grid].onlyPicture,
    isLoading: companyDetail.isLoading
  }

  React.useEffect(() => {
    fetchDetailCompany()
  }, [fetchDetailCompany])

  if (grid === GRID1) return <aside className={cn} />

  const logout = () => {
    resetLogin()
    setIsLogout(true)
    document.cookie =
      'access-token-dashboard=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=qlue.ai'
    document.cookie =
      'refresh-token-dashboard=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=qlue.ai'
    window.location.reload()
    /**
     * To navigate after resetLogin using absolute path see below
     *
     * @see https://github.com/reach/router/issues/78
     * @see https://github.com/will-moore/gallery2/commit/2e72ab2b57ab07fcc107c8c9a9b903022827f6f3
     */
  }

  if (isLogout) {
    return <Redirect to="login" noThrow />
  }

  const onlyIcon = grid === GRID2
  const menuClassName = onlyIcon ? 'justify-center' : ''
  const text = grid === GRID3 ? 'Logout' : null
  const maxHeight = onlyIcon ? 'calc(100vh - 164px)' : 'calc(100vh - 370px)'

  return (
    <aside style={{ overflow: 'visible' }} className={cn}>
      <DisplayGrid gridTemplateRows={gridStyle[grid].gridTemplateRows}>
        <AsideProfile {...profileProps} />
        <UnorderedList noList className="ma0 overflow-y-auto" style={{ maxHeight }}>
          {RenderTree(tree)}
        </UnorderedList>
        <FlexBox className="absolute bottom-0 w-100">
          <MenuItem
            className={menuClassName}
            active={false}
            name={text}
            icon={getSidebarIcon('Logout')}
            onClick={logout}
            onlyIcon={onlyIcon}
            id={generateElementID(MENU_PAGE_ID_PREFIX, 'logout')}
          />
        </FlexBox>
      </DisplayGrid>
      <ToggleButton position="left" status={grid === GRID3} trigger={changeGrid} />
    </aside>
  )
}

Sidebar.propTypes = {
  RenderTree: PropTypes.func.isRequired,
  changeGrid: PropTypes.func.isRequired,
  className: PropTypes.string,
  grid: PropTypes.string.isRequired,
  tree: PropTypes.arrayOf(PropTypes.any).isRequired
}

Sidebar.defaultProps = {
  className: ''
}

export default Sidebar
