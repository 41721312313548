const API = process.env.REACT_APP_API_URL_BASE_API

const GeneralAPIFR = `${API}/enrollment/face/`
const GetListEnrollment = `${API}/enrollment`
const DeleteFR = `${GeneralAPIFR}delete`
const GeneralAPILPR = `${API}/enrollment/license-plate/`
const DeleteLPR = `${GeneralAPILPR}delete`
const TemplateExcelLPR = `${GeneralAPILPR}template`
const CreateLPRBulk = `${GeneralAPILPR}bulk`
const GeneralAPIThermal = `${API}/enrollment/thermal-face`
const DeleteThermalFace = `${GeneralAPIThermal}/delete`
const GetListBasedOnEngine = id => `${API}/enrollment/${id}/engine`

export default {
  GetListBasedOnEngine,
  GetListEnrollment,
  GeneralAPIFR,
  DeleteFR,
  GeneralAPILPR,
  DeleteLPR,
  TemplateExcelLPR,
  CreateLPRBulk,
  GeneralAPIThermal,
  DeleteThermalFace
}
