import { useEffect, useState } from 'react'
import packageJson from '../../../package.json'
// import { clearCache } from 'utils'
global.appVersion = packageJson.version

// version from response - first param, local version second param
const semverGreaterThan = (versionA, versionB) => {
  if (versionA !== versionB) return true
  return false
}

function CacheBuster() {
  const [{ loading, isLatestVersion }, setState] = useState({
    loading: true,
    isLatestVersion: false
  })

  function refreshCacheAndReload() {
    console.log('Clearing cache and hard reloading...')
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then(function(names) {
        for (let name of names) caches.delete(name)
      })
    }
    // TEMPORARY commented because there's still an issue of infinite reload
    // clearCache()
  }

  useEffect(() => {
    fetch('/meta.json')
      .then(response => response.json())
      .then(meta => {
        const latestVersion = meta.version
        const currentVersion = global.appVersion

        const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion)
        if (shouldForceRefresh) {
          console.log(`We have a new version - ${latestVersion}. Should force refresh`)
          setState({ loading: false, isLatestVersion: false })
        } else {
          console.log(
            `You already have the latest version - ${latestVersion}. No cache refresh needed.`
          )
          setState({ loading: false, isLatestVersion: true })
        }
      })
  }, [])

  useEffect(() => {
    if (!loading && !isLatestVersion) {
      refreshCacheAndReload()
    }
  }, [loading, isLatestVersion])
}

export default CacheBuster
