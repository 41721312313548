import React, { useState, useEffect } from 'react'
import { H2, Text } from '@qlue/react-component/dist/QlueTypography'
import { DATA_SLIDER } from './constant'
import Styled from './styles'

function Frame({ title, body }) {
  return (
    <Styled.FrameWrapper>
      <H2 className="frame-title">{title}</H2>
      <Text className="frame-body">{body}</Text>
    </Styled.FrameWrapper>
  )
}

let interval = null

function BackgroundSlider() {
  const [slideNum, setSlideNum] = useState(0)
  const [resetInterval, setResetInterval] = useState(false)

  useEffect(() => {
    if (resetInterval) {
      setResetInterval(false)
      clearInterval(interval)
    } else {
      interval = setInterval(() => {
        setSlideNum(prev => {
          const nextSlideNum = (prev += 1)
          if (nextSlideNum > DATA_SLIDER.length - 1) {
            return 0
          }
          return nextSlideNum
        })
      }, 4000)
    }

    return () => clearInterval(interval)
  }, [resetInterval])

  function onSliderClick(num) {
    return () => {
      setSlideNum(num)
      setResetInterval(true)
    }
  }

  return (
    <Styled.Wrapper backgroundSrc={DATA_SLIDER[slideNum].src}>
      {DATA_SLIDER.map(({ src }, i) => (
        <img key={i.toString()} src={src} alt="slider" />
      ))}
      <Frame title={DATA_SLIDER[slideNum].title} body={DATA_SLIDER[slideNum].body} />
      <Styled.SliderWrapper>
        {DATA_SLIDER.map((_, i) => (
          <Styled.Slider key={i.toString()} isActive={slideNum === i} onClick={onSliderClick(i)} />
        ))}
      </Styled.SliderWrapper>
    </Styled.Wrapper>
  )
}

export default BackgroundSlider
