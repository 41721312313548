import { useEffect, useState, useRef } from 'react'
import API from 'commons/API'
import { useStore } from 'shells/AppShell'
import getNewToken from 'utils/getNewToken'

function useAPIData({ url = '', params = {}, initialData = {}, deps = [], manual = false }) {
  const { setModalExpired } = useStore()
  const [isLoading, setLoading] = useState(true)
  const [data, setData] = useState(initialData)
  const fetchAPI = useRef(() => {})

  const fn = () => {
    async function getData() {
      try {
        setLoading(true)
        const fetchAPI = await API(url, { params })
        setData(fetchAPI.data)
      } catch (e) {
        const isExpired = e.response?.data?.errorCode === 197
        if (isExpired) {
          getNewToken({ setModalExpired })
        }
        throw new Error(e)
      }
      setLoading(false)
    }

    if (!manual) getData()
    else setLoading(false)
    fetchAPI.current = getData
  }

  useEffect(fn, deps)

  return { isLoading, data, fetchAPI: fetchAPI.current }
}

export default useAPIData
