import { bgLoginFR, bgLoginLPR, bgLoginSmartCity } from 'commons/assets/images'

export const DATA_SLIDER = [
  {
    src: bgLoginFR,
    title: 'Face Recognition',
    body: 'Recognize people faster, easier, & accurately'
  },
  {
    src: bgLoginLPR,
    title: 'License Plate Recognition',
    body: 'Recognize license plate with a vast amount, accurately'
  },
  {
    src: bgLoginSmartCity,
    title: 'Smart City Integration',
    body: 'Integrate public facilities to ensure citizen’s safety'
  }
]
