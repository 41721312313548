import 'utils/initFCM'
import React, { Fragment } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook'
import GlobalStyle from '@qlue/react-component/dist/QlueDashboardStyle'
import bugsnag from '@bugsnag/js'
import bugsnagReact from '@bugsnag/plugin-react'
import { useSelector } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import App from './App'
import * as serviceWorker from './serviceWorker'
import store, { persistor } from './stores'
import useCacheBuster from './commons/hooks/useCacheBuster'
import theme from '@qlue/react-component/dist/styles/theme'
import MultiLanguage from 'utils/MultiLanguage'

const isProduction = process.env.REACT_APP_NODE_ENV === 'production'
const bugsnagClient = isProduction ? bugsnag(process.env.REACT_APP_BUGSNAG_KEY) : null

const OLD_PRODUCTION_URL = 'https://neo-dashboard.qlue.id'
const NEW_PRODUCTION_URL = 'https://dashboard.qlue.ai'

if (isProduction) bugsnagClient.use(bugsnagReact, React)

function ErrorBoundary({ children }) {
  if (isProduction) {
    const BugsnagBoundary = bugsnagClient.getPlugin('react')
    return <BugsnagBoundary>{children}</BugsnagBoundary>
  }

  return <Fragment>{children}</Fragment>
}

function Root() {
  useCacheBuster()
  const companyDetail = useSelector(({ companyDetail }) => companyDetail)
  const pathName = window.location.pathname
  const mainBackground = pathName.search(/activation-/i) !== -1 ? theme.primaryTextColor : ''
  const gtmParams = { id: 'GTM-NXHWLPQ' }

  if (isProduction) bugsnagClient.user = { email: 'Empty from backend', ...companyDetail }

  if (window.location.origin === OLD_PRODUCTION_URL) {
    window.location.href = NEW_PRODUCTION_URL
  }

  return (
    <ErrorBoundary>
      {isProduction ? (
        <GTMProvider state={gtmParams}>
          <MultiLanguage />
          <GlobalStyle mainBackground={mainBackground} />
          <App />
        </GTMProvider>
      ) : (
        <>
          <MultiLanguage />
          <GlobalStyle mainBackground={mainBackground} />
          <App />
        </>
      )}
    </ErrorBoundary>
  )
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Root />
    </PersistGate>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
