const formDataHandler = (payload, option = {}) => {
  const formData = new FormData()
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(payload)) {
    if (value) {
      if (value instanceof Blob || typeof value !== 'object') {
        formData.append(key, value)
      } else if ('length' in value) {
        if (
          value.some(
            x => typeof x === 'string' || typeof x === 'number' || !!option.directlyPassArray
          )
        ) {
          for (let i = 0; i < value.length; i += 1) {
            let val
            if (value[i] instanceof Blob) {
              formData.append(key, value[i])
            } else {
              if (typeof value[i] !== 'object') {
                val = value[i]
              } else {
                val = JSON.stringify(value[i])
              }
              formData.append(`${key}[${i}]`, val)
            }
          }
        } else {
          // eslint-disable-next-line no-restricted-syntax
          for (const [i, v] of value.entries()) {
            // eslint-disable-next-line no-restricted-syntax
            for (const [vKey, vVal] of Object.entries(v)) {
              formData.append(`${key}[${i}][${vKey}]`, vVal)
            }
          }
        }
      } else {
        // eslint-disable-next-line no-restricted-syntax
        for (const [k, v] of Object.entries(value)) {
          formData.append(`${key}[${k}]`, v)
        }
      }
    }
  }
  return formData
}

export default formDataHandler
