import React, { useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import Styled from './styles'
import { Text } from '@qlue/react-component/dist/QlueTypography'
import theme from '@qlue/react-component/dist/styles/theme'
import { ArrayMap, Conditional } from '@qlue/react-component/dist/utils'
import ItemNotification from '../Components/ItemNotification'
import ItemSubscription from '../Components/ItemSubscription'
import useFetch from 'commons/hooks/useFetch'
import useEventSource from 'commons/hooks/useEventSource'
import API, { EventURL, TrackingURL } from 'commons/API'

function NotificationList({ setIsAllRead }) {
  const [ref, setRef] = useState(null)
  const { token, companyId } = useSelector(state => state.login.token)

  const url = `${TrackingURL.GetLiveUser}?token=${token}`

  // fetching
  const { GetNotificationList, ReadNotification } = EventURL
  const { data: notifData, setBody, setData, refetch } = useFetch({
    url: GetNotificationList,
    method: 'post',
    initialState: { event_alerts: [] },
    initialBody: { per_page: 10 }
  })
  // SSE
  const { eventData } = useEventSource(url, `event_alert_${companyId}`)

  useEffect(() => {
    const intersector = new IntersectionObserver(entries => {
      const { isIntersecting } = entries[0]
      if (
        isIntersecting &&
        notifData.meta &&
        notifData.event_alerts.length <= notifData.meta.total
      ) {
        setBody(prev => ({ ...prev, per_page: prev.per_page + 10 }))
      }
    })
    if (ref) intersector.observe(ref)
    return () => ref && intersector.disconnect(ref)
  }, [ref, notifData, setBody])

  // SSE
  useEffect(() => {
    if (Object.keys(eventData).length) {
      setData(prev => ({ ...prev, event_alerts: [eventData, ...prev.event_alerts] }))
      setIsAllRead(true)
    }
  }, [eventData, setData, setIsAllRead])

  const onRefChange = useCallback(node => {
    setRef(node)
  }, [])

  const onMarkRead = async id => {
    try {
      await API.put(ReadNotification, { ids: [id] })
      refetch(true)
    } catch ({ response }) {
      if (response?.data?.errorCode) alert({ type: 'failed', msg: response?.data?.message })
      else if (response?.status >= 500)
        alert({ type: 'failed', msg: 'Something went wrong in our server' })
    }
  }

  const onMarkAllRead = async () => {
    try {
      await API.put(ReadNotification, { ids: ['all'] })
      refetch(true)
    } catch ({ response }) {
      if (response?.data?.errorCode) alert({ type: 'failed', msg: response?.data?.message })
      else if (response?.status >= 500)
        alert({ type: 'failed', msg: 'Something went wrong in our server' })
    }
  }

  function renderItem({
    id,
    name,
    message,
    snapshot_image,
    feed,
    event_source,
    is_read,
    created_at,
    updated_at,
    task,
    type
  }) {
    switch (type) {
      case 'subscription':
        return <ItemSubscription id={id} />
      default:
        return (
          <ItemNotification
            id={id}
            isRead={is_read}
            name={name}
            message={message}
            snapshotImage={snapshot_image}
            feed={feed}
            eventSource={event_source}
            createdAt={created_at}
            updatedAt={updated_at}
            onMarkRead={onMarkRead}
            task={task}
          />
        )
    }
  }

  return (
    <Styled.WrapperList>
      <div className="header-list">
        <Text>Alert</Text>
        <Conditional
          if={notifData.event_alerts && notifData.event_alerts.some(item => item.is_read === false)}
        >
          <Text
            color={theme.primaryColor}
            className="pointer"
            onClick={() => {
              onMarkAllRead()
              setIsAllRead(false)
            }}
          >
            Mark all as read
          </Text>
        </Conditional>
      </div>
      <div className="title-list">
        <Text color={theme.primaryTextColorTrans}>Latest</Text>
      </div>
      <div className="content-list">
        <Conditional if={notifData.event_alerts}>
          <ArrayMap data={notifData.event_alerts}>{data => renderItem(data)}</ArrayMap>
          <Styled.ObserverTrigger ref={onRefChange}></Styled.ObserverTrigger>
        </Conditional>
      </div>
    </Styled.WrapperList>
  )
}

export default NotificationList
