import React from 'react'
import PropTypes from 'prop-types'
import { BaseModal } from '@qlue/react-component/dist/Modal'
import { H3 } from '@qlue/react-component/dist/QlueTypography'
import theme from '@qlue/react-component/dist/styles/theme'
import { IconClose } from '@qlue/react-component/dist/QlueIcon'
import Styled from './style'
import Detail from '../NotifModalDetail/detail'
import { EventURL } from 'commons/API'
import useFetch from 'commons/hooks/useFetch'

export default function NotifModal({ onCloseModal, engine, task, detailId }) {
  const { GetDetailNotification } = EventURL
  const { data: detailData } = useFetch({
    url: GetDetailNotification(detailId),
    initialFetch: false,
    initialState: { data: {} }
  })

  return (
    <BaseModal
      height="90vh"
      width="600px"
      visible
      bgColor={theme.primaryBackground}
      onClose={onCloseModal}
      closeIcon={false}
      overflow="auto"
    >
      <Styled.Wrapper>
        <div className="icon-close" onClick={onCloseModal}>
          <IconClose />
        </div>
        <H3 style={{ marginBottom: '30px' }}>Alert Captured</H3>
        {/* Render Detail */}
        {detailData.event_alert && (
          <Detail detail={detailData.event_alert} engine={engine} task={task} />
        )}
      </Styled.Wrapper>
    </BaseModal>
  )
}

NotifModal.propTypes = {
  onCloseModal: PropTypes.func,
  detailId: PropTypes.string
}
