import { useState, useEffect } from 'react'

const useEventSource = (url, eventName, isOpen = true) => {
  const [message, setMessage] = useState({})
  const [eventData, setEventData] = useState({})

  useEffect(() => {
    if (isOpen) {
      const source = new EventSource(url)
      const listener = m => {
        const d = JSON.parse(m.data)
        setEventData(d)
      }
      source.onmessage = e => {
        const m = JSON.parse(e.data)
        setMessage(m)
      }
      if (eventName) source.addEventListener(eventName, listener)
      return () => {
        source.removeEventListener(eventName, listener)
        source.close()
      }
    }
  }, [url, eventName, isOpen])

  return {
    message,
    eventData
  }
}

export default useEventSource
