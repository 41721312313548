const API = process.env.REACT_APP_API_URL_BASE_API

const GetList = `${API}/smart-device`
const GetDevices = `${API}/device`
const GetDeviceList = `${API}/smart-device/devices`

const AddDeviceLocation = `${API}/smart-device/location`
const GetListDashboard = `${API}/smart-device/dashboard`
const GetDeviceEvent = `${API}/event`

const GetDeviceCategory = `${API}/device-category`
const GetListPowerMonitorDevice = `${API}/smart-device/power-monitoring`
const EditPowerMonitorDevice = id => `${API}/smart-device/power-monitoring/${id}`
const DeletePowerMonitorDevice = id => `${API}/smart-device/power-monitoring/${id}`
const AddDevicePowerMonitor = `${API}/smart-device/power-monitoring`

export default {
  GetList,
  GetDevices,
  GetDeviceList,
  AddDeviceLocation,
  GetListDashboard,
  GetDeviceEvent,
  GetDeviceCategory,
  GetListPowerMonitorDevice,
  EditPowerMonitorDevice,
  DeletePowerMonitorDevice,
  AddDevicePowerMonitor
}
