import styled, { css } from 'styled-components'
import theme from '@qlue/react-component/dist/styles/theme'

export default {
  DetailWrapper: styled.div`
    position: fixed;
    right: 0;
    top: 0;
    height: 100vh;
    width: 429px;
    background: ${theme.primaryBackground};
    z-index: 101;
  `,
  Header: styled.div`
    padding-top: 21px;

    > .title {
      margin-bottom: 26px;
      padding: 0 50px 0 26px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    > .menu {
      padding: 0 26px;
      display: flex;
      justify-content: space-between;

      .tab {
        display: flex;

        > div {
          margin-right: 15px;
        }
      }

      .icon-search {
        width: 17px;
      }
      .icon-close {
        width: 15px;
      }
    }

    > div.icon-close {
      position: absolute;
      top: 21px;
      right: 21px;
      width: 14px;
      height: 14px;
      cursor: pointer;
    }
  `,
  Tab: styled.div`
    padding: 10px 0;
    user-select: none;

    ${props =>
      props.active &&
      css`
        border-bottom: solid 3px ${theme.primaryColor};
      `};
  `,
  Line: styled.div`
    width: 100%;
    height: 1px;
    background-color: ${theme.primaryBackgroundLightest};
    margin-top: -2px;
  `,
  Content: styled.div`
    padding: 0 26px 26px 26px;
    height: calc(100vh - 123px);
    overflow-y: auto;
  `
}
