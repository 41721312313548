import React, { useEffect, useRef, useState } from 'react'
import { H3, Text } from '@qlue/react-component/dist/QlueTypography'
import { IconClose } from '@qlue/react-component/dist/QlueIcon'
import { ArrayMap, Conditional } from '@qlue/react-component/dist/utils'
import { SearchInput } from '@qlue/react-component/dist/Input'
import theme from '@qlue/react-component/dist/styles/theme'
import { reload } from 'commons/assets/images'
import { useStore } from 'shells/AppShell'
import WhatsNew from './WhatsNew'
import GetHelp from './GetHelp'
import Documentation from './Documentation'
import { menu } from './hardcode'
import Styled from './styles'

function Help({ onClose, data }) {
  const {
    showcase: { replayShowcase }
  } = useStore()
  const wrapperRef = useRef(null)
  const [active, setActive] = useState(data.menu || 'Manual Guide')
  const [isSearch, setIsSearch] = useState(false)
  const [search, setSearch] = useState('')

  useEffect(() => {
    const handleClickOutside = event => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        onClose()
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [onClose])

  function onChange({ target: { value } }) {
    setSearch(value)
  }

  function onCloseSearch() {
    setSearch('')
    setIsSearch(false)
  }

  const onReplayShowcase = () => {
    replayShowcase()
  }

  return (
    <Styled.DetailWrapper ref={wrapperRef}>
      <Styled.Header>
        <div className="icon-close" onClick={onClose}>
          <IconClose />
        </div>
        <div className="title">
          <H3 className="fw5">Help Center</H3>
          <div
            className="d-flex align-center pointer ml3"
            role="presentation"
            onClick={onReplayShowcase}
          >
            <img width="13px" height="15px" src={reload} alt="reload" />
            <Text className="fw5 fs-14px ml2" color={theme.primaryColor}>
              Replay Showcase
            </Text>
          </div>
        </div>
        <div className="menu mt2">
          <Conditional if={!isSearch}>
            <>
              <div className="tab">
                <ArrayMap data={menu}>
                  {({ name }) => (
                    <Styled.Tab
                      className="fs-14px fw5 pointer"
                      role="presentation"
                      active={active === name}
                      onClick={() => setActive(name)}
                    >
                      {name}
                    </Styled.Tab>
                  )}
                </ArrayMap>
              </div>
              {/*
                // next feature
                <div
                  className="icon-search pointer"
                  role="presentation"
                  onClick={() => setIsSearch(p => !p)}
                >
                  <IconSearch />
                </div>
              */}
            </>
          </Conditional>
          <Conditional if={isSearch}>
            <>
              <SearchInput
                transparent
                placeholder="Search here..."
                width="363px"
                autoFocus
                value={search}
                onChange={onChange}
              />
              <div className="icon-close pointer" role="presentation" onClick={onCloseSearch}>
                <IconClose />
              </div>
            </>
          </Conditional>
        </div>
        <Styled.Line />
      </Styled.Header>
      <Styled.Content>
        <Conditional if={active === 'Manual Guide'}>
          <Documentation data={data} />
        </Conditional>
        <Conditional if={active === 'Get Help'}>
          <GetHelp />
        </Conditional>
        <Conditional if={active === 'What’s New'}>
          <WhatsNew />
        </Conditional>
      </Styled.Content>
    </Styled.DetailWrapper>
  )
}

export default Help
