import React, { useState, useRef, createRef, useEffect } from 'react'
import Styled from './styles'
import { ArrayMap } from '@qlue/react-component/dist/utils'
import { Text, Paragraph } from '@qlue/react-component/dist/QlueTypography'
import { IconArrow } from '@qlue/react-component/dist/QlueIcon'
import theme from '@qlue/react-component/dist/styles/theme'
import { dummyData } from './hardcode'

function WhatsNew() {
  const [heights, setHeights] = useState([])
  const [openedIds, setOpenedIds] = useState([])
  const elementsRef = useRef(dummyData.map(() => createRef()))

  useEffect(() => {
    const nextHeights = elementsRef.current.map(
      ref => `${ref.current.getBoundingClientRect().height + 55}px`
    )
    setHeights(nextHeights)
  }, [])

  function onClick(id) {
    return () => {
      setOpenedIds(prevOpIds => {
        if (prevOpIds.indexOf(id) !== -1) return prevOpIds.filter(opId => opId !== id)
        return [...prevOpIds, id]
      })
    }
  }

  return (
    <Styled.Wrapper>
      <ArrayMap data={dummyData}>
        {({ id, name, subName, imgUrl, data }, index) => (
          <Styled.MenuItem key={id} active={openedIds.indexOf(id) !== -1} height={heights[index]}>
            <div className="title pointer mt2" role="presentation" onClick={onClick(id)}>
              <div className="first-section">
                <div className="logo-menu">
                  <img src={imgUrl} alt="logo" width="35px" height="35px" />
                </div>
                <div className="text-menu">
                  <Text className="title-content ttc" color="#a0a1a2">
                    {subName}
                  </Text>
                  <Text className="ttu fw5" color="#ffffff">
                    {name}
                  </Text>
                </div>
              </div>
              <div className="arrow">
                <IconArrow color={theme.primaryColor} />
              </div>
            </div>
            <div ref={elementsRef.current[index]}>
              <Styled.Item>
                {data.map(({ title, contents }) => (
                  <div className="content mb2">
                    <Text className="fw5">{title}</Text>
                    {contents.map(content => (
                      <ul>
                        <li>
                          <Paragraph>{content}</Paragraph>
                        </li>
                      </ul>
                    ))}
                  </div>
                ))}
              </Styled.Item>
            </div>
          </Styled.MenuItem>
        )}
      </ArrayMap>
    </Styled.Wrapper>
  )
}

export default WhatsNew
