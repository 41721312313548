const API = process.env.REACT_APP_API_URL_BASE_API

const GetThermalHistory = `${API}/thermal/history`
const GetThermalLocation = `${API}/thermal/device-location`
const GetInitDataSSE = `${API}/thermal/count/` // with deviceId on the latest of URL
const GetAnalytics = `${API}/thermal/analytics`
const GetDetailAnalytics = `${GetAnalytics}/detail`
const GetDeviceLocation = `${API}/thermal/device-location`
const RequestDevice = `${API}/smart-device/request/thermal`
const GetDevices = `${API}/smart-device/devices-filter`
const DownloadHistoryThermal = `${API}/thermal/history/download`

const GetThermalCameraLive = id => `${API}/thermal-camera/${id}/live`
const GetThermalCameraCount = id => `${API}/thermal-camera/count/${id}`
const GetThermalCameraAnalytics = `${API}/thermal-camera/analytics`
const GetDetailThermalAnalytics = `${GetThermalCameraAnalytics}/detail`
const GetThermalCameraHistory = `${API}/thermal-camera/history`
const DownloadHistoryThermalCamera = `${API}/thermal-camera/history/download`

export default {
  GetThermalHistory,
  GetThermalLocation,
  GetInitDataSSE,
  GetAnalytics,
  GetDetailAnalytics,
  GetDeviceLocation,
  RequestDevice,
  GetDevices,
  DownloadHistoryThermal,
  GetThermalCameraLive,
  GetThermalCameraCount,
  GetThermalCameraAnalytics,
  GetDetailThermalAnalytics,
  GetThermalCameraHistory,
  DownloadHistoryThermalCamera
}
