import React, { useState, useRef, createRef, useEffect } from 'react'
import { ArrayMap } from '@qlue/react-component/dist/utils'
import { Text } from '@qlue/react-component/dist/QlueTypography'
import { IconArrow } from '@qlue/react-component/dist/QlueIcon'
import VideoPlayer from '@qlue/react-component/dist/VideoPlayer'
import theme from '@qlue/react-component/dist/styles/theme'
import { qva } from 'commons/assets/videos'
import { MANUAL_GUIDE } from './constant'
import { getAdditionalHeight } from './utils'
import Styled from './styles'

function Documentation({ data }) {
  const [heights, setHeights] = useState([])
  const [openedIds, setOpenedIds] = useState(data.subMenu ? [data.subMenu] : [])
  const elementsRef = useRef(MANUAL_GUIDE.map(() => createRef()))

  useEffect(() => {
    const nextHeights = elementsRef.current.map(
      (ref, i) => `${ref.current.getBoundingClientRect().height + getAdditionalHeight(i)}px`
    )
    setHeights(nextHeights)
  }, [])

  useEffect(() => {
    window.location.href = `${window.location.href}#${data.subMenuDetail}`
  }, [data.subMenuDetail])

  function onClick(id) {
    return () => {
      setOpenedIds(prevOpIds => {
        if (prevOpIds.indexOf(id) !== -1) return prevOpIds.filter(opId => opId !== id)
        return [...prevOpIds, id]
      })
    }
  }

  return (
    <Styled.Wrapper>
      <ArrayMap data={MANUAL_GUIDE}>
        {({ id, name, subName, imgUrl, data }, index) => (
          <Styled.MenuItem
            key={index.toString()}
            active={openedIds.indexOf(id) !== -1}
            height={heights[index]}
          >
            <div id={name} className="title pointer mt2" role="presentation" onClick={onClick(id)}>
              <div className="first-section">
                <div className="logo-menu">
                  <img src={imgUrl} alt="logo" width="35px" height="35px" />
                </div>
                <div className="text-menu">
                  <Text className="title-content ttc" color="#a0a1a2">
                    {subName}
                  </Text>
                  <Text className="fw5" color="#ffffff">
                    {name}
                  </Text>
                </div>
              </div>
              <div className="arrow">
                <IconArrow color={theme.primaryColor} />
              </div>
            </div>
            <div ref={elementsRef.current[index]}>
              {name === 'Explore Smart CCTV' && <VideoPlayer className="mt3" src={qva} />}
              <Styled.Item>
                {data.map(({ title, subTitle, contents, footer }, i) => (
                  <div className="content mb2" key={i.toString()}>
                    <Text className="fw5 content-title">{title}</Text>
                    {subTitle && <Text className="db mt2">{subTitle}</Text>}
                    {contents.map((content, contentIdx) => (
                      <ul key={contentIdx.toString()}>
                        <li>
                          <div className="lh-copy content-trans">{content}</div>
                        </li>
                      </ul>
                    ))}
                    {footer && <Text className="ma2">{footer}</Text>}
                  </div>
                ))}
              </Styled.Item>
            </div>
          </Styled.MenuItem>
        )}
      </ArrayMap>
    </Styled.Wrapper>
  )
}

export default Documentation
