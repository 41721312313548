import React, { useState, useEffect } from 'react'
import FlexContainer from '@qlue/react-component/dist/FlexContainer'
import VerticalCarousel from '@qlue/react-component/dist/VerticalCarousel'
import TableDescription from '@qlue/react-component/dist/TableDescription'
import BlockContainer from '@qlue/react-component/dist/BlockContainer'
import MapEnrollment from '@qlue/react-component/dist/MapEnrollment'
import { BaseModal as Modal } from '@qlue/react-component/dist/Modal'
import { Text, H4 } from '@qlue/react-component/dist/QlueTypography'
import theme from '@qlue/react-component/dist/styles/theme'
import checkIsImage from 'utils/checkIsImage'
import formatDate from 'utils/formatDate'

let timeout = null

const ModalAlert = () => {
  const [isModalMap, setIsModalMap] = useState(false)
  const [isModal, setIsModal] = useState(false)
  const [dummyValue, setDummyValue] = useState('')

  useEffect(() => {
    if (timeout) clearTimeout(timeout)
    timeout = setTimeout(() => {
      setDummyValue('')
    }, 1000)
    if (dummyValue.indexOf('hesoyam') !== -1) {
      setDummyValue('')
      alert({
        type: 'warning',
        msg: (
          <BlockContainer>
            Karyawan telat di pagi hari.{' '}
            <Text
              className="underline pointer"
              color={theme.primaryColor}
              role="presentation"
              onClick={() => setIsModal(true)}
              triggerClose
            >
              See Detail
            </Text>
          </BlockContainer>
        )
      })
    }
  }, [dummyValue])

  function alertFromTask({ key }) {
    setDummyValue(p => `${p}${key}`)
  }

  useEffect(() => {
    window.addEventListener('keyup', alertFromTask)

    return () => {
      window.removeEventListener('keyup', alertFromTask)
    }
  }, [])

  if (!isModal) return ''
  if (isModalMap)
    return (
      <Modal
        visible
        closeIcon
        border
        width="70vw"
        height="70vh"
        onClose={() => setIsModalMap(false)}
        style={{
          minWidth: '500px',
          minHeight: '200px'
        }}
      >
        <MapEnrollment
          className="z-0"
          width="100%"
          height="100%"
          withSearch={false}
          center={[-6.2753362, 106.8277604]}
          attributionControl={false}
          isPreventClick={true}
          onChange={() => null}
          mapProps={{
            dragging: false,
            zoomControl: false,
            scrollWheelZoom: false,
            doubleClickZoom: false
          }}
        />
      </Modal>
    )
  return (
    <Modal
      width="900px"
      height="auto"
      maxHeight="500px"
      closeIcon
      visible
      onClose={() => setIsModal(false)}
    >
      <FlexContainer className="ph4 pv4 w-100" justifyContent="space-between">
        <BlockContainer>
          <VerticalCarousel
            data={[
              {
                id: '1',
                type: 'image',
                filename:
                  'https://qwstg.s3-ap-southeast-1.amazonaws.com/1580876178455_1580876161762.jpg',
                thumbnails: {
                  large:
                    'https://qwstg.s3-ap-southeast-1.amazonaws.com/1580876178455_1580876161762_278.jpg',
                  medium:
                    'https://qwstg.s3-ap-southeast-1.amazonaws.com/1580876178455_1580876161762_100.jpg',
                  small:
                    'https://qwstg.s3-ap-southeast-1.amazonaws.com/1580876178455_1580876161762_46.jpg'
                }
              }
            ].map(m => ({
              url: !checkIsImage(m.filename) ? m.filename : m.thumbnails && m.thumbnails.large,
              type: m.type,
              thumbnail_url: m.thumbnails && m.thumbnails.small
            }))}
            isEmpty={false}
          />
          <BlockContainer className="fw5 fs-18px mt3">Handoko</BlockContainer>
          <BlockContainer className="fs-14px mt3">
            {formatDate(1580876179.741, {
              month: 'long',
              hour: 'numeric',
              minute: 'numeric'
            })}
          </BlockContainer>
        </BlockContainer>
        <BlockContainer className="ml3">
          <H4 className="fw5">Attendance</H4>
          <BlockContainer className="fs-14px mb3">Karyawan telat di pagi hari</BlockContainer>
          <TableDescription
            titleWidth="100px"
            data={[
              {
                title: 'Location',
                content: (
                  <div>
                    {`Jl. Empang Bahagia Raya No.6, RT.1/RW.6, Jelambar, Grogol petamburan, Kota Jakarta Barat, Daerah Khusus Ibukota Jakarta 11460 (`}
                    <span
                      className="pointer"
                      style={{ color: theme.primaryColor }}
                      role="presentation"
                      onClick={() => setIsModalMap(true)}
                    >
                      show on map
                    </span>
                    )
                  </div>
                )
              },
              {
                title: 'Problem Handler',
                content: `Fajar`
              },
              {
                title: 'Manager',
                content: `Norman`
              },
              {
                title: 'Workgroup Name',
                content: `Proyek Apartemen Kebayoran`
              },
              {
                title: 'Priority',
                content: `High`
              }
            ]}
          />
        </BlockContainer>
      </FlexContainer>
    </Modal>
  )
}

export default ModalAlert
