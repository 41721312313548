import styled from 'styled-components'
import theme from '@qlue/react-component/dist/styles/theme'

export default {
  Separator: styled.div`
    width: 100%;
    height: 1px;
    background-color: white;
    margin-top: 16px;
  `,

  TaskWrapper: styled.div`
    font-size: 14px;
  `,

  TaskContent: styled.div`
    display: grid;
    grid-template-columns: 20% 5% 65%;
    margin-top: 16px;

    span:nth-child(2) {
      justify-self: center;
    }
  `,

  MediaWrapper: styled.div`
    display: flex;

    > div > div {
      position: absolute;
      right: 8px;
      top: 8px;
      background-color: rgba(0, 0, 0, 0.4);
      padding: 8px;
      border-radius: 4px;
    }
  `,

  MediaFR: styled.div`
    margin-right: 20px;
    width: 100%;
    height: 250px;
    position: relative;

    > img {
      background-color: white;
      border-radius: 4px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  `,

  Media: styled.div`
    width: 100%;
    height: 250px;
    position: relative;

    > img {
      background-color: white;
      border-radius: 4px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    > div > video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  `,

  ContentWrapper: styled.div`
    font-size: 14px;
    font-weight: 400;
  `,

  Content: styled.div`
    display: grid;
    grid-template-columns: 22% 4% 64%;
    margin-top: 16px;

    span:nth-child(2) {
      justify-self: center;
    }

    > span > .map-link {
      text-decoration: underline;
      color: ${theme.primaryColor};
      cursor: pointer;
    }
  `
}
