import { qluedashboardLogo } from 'commons/assets/images'

export const menu = [
  {
    name: 'Manual Guide'
  },
  {
    name: 'Get Help'
  },
  {
    name: 'What’s New'
  }
]

export const dataDropdown = [
  {
    id: '1',
    name: 'Dashboard',
    imgUrl: qluedashboardLogo,
    items: [
      {
        id: '1',
        name: 'Demo 1',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam',
        imgUrl: qluedashboardLogo,
        content: `
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et bibendum elit.
          In efficitur mi sodales tellus lacinia convallis. Sed aliquet euismod bibendum.
          Donec vehicula gravida lacus et varius. Cras mi leo, ultricies vel ante at, sodales ultrices magna.
          Fusce vehicula, nisi in bibendum euismod, elit tortor scelerisque libero, ac tincidunt sem nisi et eros.
          Cras porttitor congue tortor ac cursus. Nam nec fermentum lectus. Donec nec est nulla.
          Praesent vitae eros risus. Aliquam erat volutpat. Donec semper tincidunt nisi, sit amet pulvinar arcu vestibulum et.
          Integer porttitor efficitur elit, nec lobortis ante tristique vel.
          Vivamus ullamcorper lorem a diam dapibus elementum eget consequat mauris.
          In congue sollicitudin est. Duis commodo commodo augue. Duis laoreet lacinia nisl, sed luctus purus.
          Curabitur mollis volutpat arcu at fermentum. Cras fringilla est eget lobortis varius.
          Pellentesque a pretium velit, eget condimentum dolor. Nullam aliquet fermentum sapien eu tempus.
          Phasellus dapibus dolor convallis sapien rutrum, id volutpat enim malesuada. Sed massa nisi,
          interdum ac massa ac, euismod fringilla augue. Donec viverra rutrum varius.
        `,
        url: 'https://www.w3schools.com/html/mov_bbb.mp4'
      },
      {
        id: '2',
        name: 'Demo 2',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam',
        imgUrl: qluedashboardLogo,
        content: `
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et bibendum elit.
          In efficitur mi sodales tellus lacinia convallis. Sed aliquet euismod bibendum.
          Donec vehicula gravida lacus et varius. Cras mi leo, ultricies vel ante at, sodales ultrices magna.
          Fusce vehicula, nisi in bibendum euismod, elit tortor scelerisque libero, ac tincidunt sem nisi et eros.
          Cras porttitor congue tortor ac cursus. Nam nec fermentum lectus. Donec nec est nulla.
          Praesent vitae eros risus. Aliquam erat volutpat. Donec semper tincidunt nisi, sit amet pulvinar arcu vestibulum et.
          Integer porttitor efficitur elit, nec lobortis ante tristique vel.
          Vivamus ullamcorper lorem a diam dapibus elementum eget consequat mauris.
          In congue sollicitudin est. Duis commodo commodo augue. Duis laoreet lacinia nisl, sed luctus purus.
          Curabitur mollis volutpat arcu at fermentum. Cras fringilla est eget lobortis varius.
          Pellentesque a pretium velit, eget condimentum dolor. Nullam aliquet fermentum sapien eu tempus.
          Phasellus dapibus dolor convallis sapien rutrum, id volutpat enim malesuada. Sed massa nisi,
          interdum ac massa ac, euismod fringilla augue. Donec viverra rutrum varius.
        `,
        url: 'https://www.w3schools.com/html/mov_bbb.mp4'
      },
      {
        id: '3',
        name: 'Demo 3',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam',
        imgUrl: qluedashboardLogo,
        content: `
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et bibendum elit.
          In efficitur mi sodales tellus lacinia convallis. Sed aliquet euismod bibendum.
          Donec vehicula gravida lacus et varius. Cras mi leo, ultricies vel ante at, sodales ultrices magna.
          Fusce vehicula, nisi in bibendum euismod, elit tortor scelerisque libero, ac tincidunt sem nisi et eros.
          Cras porttitor congue tortor ac cursus. Nam nec fermentum lectus. Donec nec est nulla.
          Praesent vitae eros risus. Aliquam erat volutpat. Donec semper tincidunt nisi, sit amet pulvinar arcu vestibulum et.
          Integer porttitor efficitur elit, nec lobortis ante tristique vel.
          Vivamus ullamcorper lorem a diam dapibus elementum eget consequat mauris.
          In congue sollicitudin est. Duis commodo commodo augue. Duis laoreet lacinia nisl, sed luctus purus.
          Curabitur mollis volutpat arcu at fermentum. Cras fringilla est eget lobortis varius.
          Pellentesque a pretium velit, eget condimentum dolor. Nullam aliquet fermentum sapien eu tempus.
          Phasellus dapibus dolor convallis sapien rutrum, id volutpat enim malesuada. Sed massa nisi,
          interdum ac massa ac, euismod fringilla augue. Donec viverra rutrum varius.
        `,
        url: 'https://www.w3schools.com/html/mov_bbb.mp4'
      },
      {
        id: '4',
        name: 'Demo 4',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam',
        imgUrl: qluedashboardLogo,
        content: `
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et bibendum elit.
          In efficitur mi sodales tellus lacinia convallis. Sed aliquet euismod bibendum.
          Donec vehicula gravida lacus et varius. Cras mi leo, ultricies vel ante at, sodales ultrices magna.
          Fusce vehicula, nisi in bibendum euismod, elit tortor scelerisque libero, ac tincidunt sem nisi et eros.
          Cras porttitor congue tortor ac cursus. Nam nec fermentum lectus. Donec nec est nulla.
          Praesent vitae eros risus. Aliquam erat volutpat. Donec semper tincidunt nisi, sit amet pulvinar arcu vestibulum et.
          Integer porttitor efficitur elit, nec lobortis ante tristique vel.
          Vivamus ullamcorper lorem a diam dapibus elementum eget consequat mauris.
          In congue sollicitudin est. Duis commodo commodo augue. Duis laoreet lacinia nisl, sed luctus purus.
          Curabitur mollis volutpat arcu at fermentum. Cras fringilla est eget lobortis varius.
          Pellentesque a pretium velit, eget condimentum dolor. Nullam aliquet fermentum sapien eu tempus.
          Phasellus dapibus dolor convallis sapien rutrum, id volutpat enim malesuada. Sed massa nisi,
          interdum ac massa ac, euismod fringilla augue. Donec viverra rutrum varius.
        `,
        url: 'https://www.w3schools.com/html/mov_bbb.mp4'
      }
    ]
  },
  {
    id: '2',
    name: 'Map',
    imgUrl: qluedashboardLogo,
    items: [
      {
        id: '1',
        name: 'Demo 1',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam',
        imgUrl: qluedashboardLogo,
        content: `
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et bibendum elit.
          In efficitur mi sodales tellus lacinia convallis. Sed aliquet euismod bibendum.
          Donec vehicula gravida lacus et varius. Cras mi leo, ultricies vel ante at, sodales ultrices magna.
          Fusce vehicula, nisi in bibendum euismod, elit tortor scelerisque libero, ac tincidunt sem nisi et eros.
          Cras porttitor congue tortor ac cursus. Nam nec fermentum lectus. Donec nec est nulla.
          Praesent vitae eros risus. Aliquam erat volutpat. Donec semper tincidunt nisi, sit amet pulvinar arcu vestibulum et.
          Integer porttitor efficitur elit, nec lobortis ante tristique vel.
          Vivamus ullamcorper lorem a diam dapibus elementum eget consequat mauris.
          In congue sollicitudin est. Duis commodo commodo augue. Duis laoreet lacinia nisl, sed luctus purus.
          Curabitur mollis volutpat arcu at fermentum. Cras fringilla est eget lobortis varius.
          Pellentesque a pretium velit, eget condimentum dolor. Nullam aliquet fermentum sapien eu tempus.
          Phasellus dapibus dolor convallis sapien rutrum, id volutpat enim malesuada. Sed massa nisi,
          interdum ac massa ac, euismod fringilla augue. Donec viverra rutrum varius.
        `,
        url: 'https://www.w3schools.com/html/mov_bbb.mp4'
      },
      {
        id: '2',
        name: 'Demo 2',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam',
        imgUrl: qluedashboardLogo,
        content: `
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et bibendum elit.
          In efficitur mi sodales tellus lacinia convallis. Sed aliquet euismod bibendum.
          Donec vehicula gravida lacus et varius. Cras mi leo, ultricies vel ante at, sodales ultrices magna.
          Fusce vehicula, nisi in bibendum euismod, elit tortor scelerisque libero, ac tincidunt sem nisi et eros.
          Cras porttitor congue tortor ac cursus. Nam nec fermentum lectus. Donec nec est nulla.
          Praesent vitae eros risus. Aliquam erat volutpat. Donec semper tincidunt nisi, sit amet pulvinar arcu vestibulum et.
          Integer porttitor efficitur elit, nec lobortis ante tristique vel.
          Vivamus ullamcorper lorem a diam dapibus elementum eget consequat mauris.
          In congue sollicitudin est. Duis commodo commodo augue. Duis laoreet lacinia nisl, sed luctus purus.
          Curabitur mollis volutpat arcu at fermentum. Cras fringilla est eget lobortis varius.
          Pellentesque a pretium velit, eget condimentum dolor. Nullam aliquet fermentum sapien eu tempus.
          Phasellus dapibus dolor convallis sapien rutrum, id volutpat enim malesuada. Sed massa nisi,
          interdum ac massa ac, euismod fringilla augue. Donec viverra rutrum varius.
        `,
        url: 'https://www.w3schools.com/html/mov_bbb.mp4'
      },
      {
        id: '3',
        name: 'Demo 3',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam',
        imgUrl: qluedashboardLogo,
        content: `
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et bibendum elit.
          In efficitur mi sodales tellus lacinia convallis. Sed aliquet euismod bibendum.
          Donec vehicula gravida lacus et varius. Cras mi leo, ultricies vel ante at, sodales ultrices magna.
          Fusce vehicula, nisi in bibendum euismod, elit tortor scelerisque libero, ac tincidunt sem nisi et eros.
          Cras porttitor congue tortor ac cursus. Nam nec fermentum lectus. Donec nec est nulla.
          Praesent vitae eros risus. Aliquam erat volutpat. Donec semper tincidunt nisi, sit amet pulvinar arcu vestibulum et.
          Integer porttitor efficitur elit, nec lobortis ante tristique vel.
          Vivamus ullamcorper lorem a diam dapibus elementum eget consequat mauris.
          In congue sollicitudin est. Duis commodo commodo augue. Duis laoreet lacinia nisl, sed luctus purus.
          Curabitur mollis volutpat arcu at fermentum. Cras fringilla est eget lobortis varius.
          Pellentesque a pretium velit, eget condimentum dolor. Nullam aliquet fermentum sapien eu tempus.
          Phasellus dapibus dolor convallis sapien rutrum, id volutpat enim malesuada. Sed massa nisi,
          interdum ac massa ac, euismod fringilla augue. Donec viverra rutrum varius.
        `,
        url: 'https://www.w3schools.com/html/mov_bbb.mp4'
      },
      {
        id: '4',
        name: 'Demo 4',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam',
        imgUrl: qluedashboardLogo,
        content: `
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et bibendum elit.
          In efficitur mi sodales tellus lacinia convallis. Sed aliquet euismod bibendum.
          Donec vehicula gravida lacus et varius. Cras mi leo, ultricies vel ante at, sodales ultrices magna.
          Fusce vehicula, nisi in bibendum euismod, elit tortor scelerisque libero, ac tincidunt sem nisi et eros.
          Cras porttitor congue tortor ac cursus. Nam nec fermentum lectus. Donec nec est nulla.
          Praesent vitae eros risus. Aliquam erat volutpat. Donec semper tincidunt nisi, sit amet pulvinar arcu vestibulum et.
          Integer porttitor efficitur elit, nec lobortis ante tristique vel.
          Vivamus ullamcorper lorem a diam dapibus elementum eget consequat mauris.
          In congue sollicitudin est. Duis commodo commodo augue. Duis laoreet lacinia nisl, sed luctus purus.
          Curabitur mollis volutpat arcu at fermentum. Cras fringilla est eget lobortis varius.
          Pellentesque a pretium velit, eget condimentum dolor. Nullam aliquet fermentum sapien eu tempus.
          Phasellus dapibus dolor convallis sapien rutrum, id volutpat enim malesuada. Sed massa nisi,
          interdum ac massa ac, euismod fringilla augue. Donec viverra rutrum varius.
        `,
        url: 'https://www.w3schools.com/html/mov_bbb.mp4'
      }
    ]
  },
  {
    id: '3',
    name: 'Chat',
    imgUrl: qluedashboardLogo,
    items: [
      {
        id: '1',
        name: 'Demo 1',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam',
        imgUrl: qluedashboardLogo,
        content: `
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et bibendum elit.
          In efficitur mi sodales tellus lacinia convallis. Sed aliquet euismod bibendum.
          Donec vehicula gravida lacus et varius. Cras mi leo, ultricies vel ante at, sodales ultrices magna.
          Fusce vehicula, nisi in bibendum euismod, elit tortor scelerisque libero, ac tincidunt sem nisi et eros.
          Cras porttitor congue tortor ac cursus. Nam nec fermentum lectus. Donec nec est nulla.
          Praesent vitae eros risus. Aliquam erat volutpat. Donec semper tincidunt nisi, sit amet pulvinar arcu vestibulum et.
          Integer porttitor efficitur elit, nec lobortis ante tristique vel.
          Vivamus ullamcorper lorem a diam dapibus elementum eget consequat mauris.
          In congue sollicitudin est. Duis commodo commodo augue. Duis laoreet lacinia nisl, sed luctus purus.
          Curabitur mollis volutpat arcu at fermentum. Cras fringilla est eget lobortis varius.
          Pellentesque a pretium velit, eget condimentum dolor. Nullam aliquet fermentum sapien eu tempus.
          Phasellus dapibus dolor convallis sapien rutrum, id volutpat enim malesuada. Sed massa nisi,
          interdum ac massa ac, euismod fringilla augue. Donec viverra rutrum varius.
        `,
        url: 'https://www.w3schools.com/html/mov_bbb.mp4'
      },
      {
        id: '2',
        name: 'Demo 2',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam',
        imgUrl: qluedashboardLogo,
        content: `
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et bibendum elit.
          In efficitur mi sodales tellus lacinia convallis. Sed aliquet euismod bibendum.
          Donec vehicula gravida lacus et varius. Cras mi leo, ultricies vel ante at, sodales ultrices magna.
          Fusce vehicula, nisi in bibendum euismod, elit tortor scelerisque libero, ac tincidunt sem nisi et eros.
          Cras porttitor congue tortor ac cursus. Nam nec fermentum lectus. Donec nec est nulla.
          Praesent vitae eros risus. Aliquam erat volutpat. Donec semper tincidunt nisi, sit amet pulvinar arcu vestibulum et.
          Integer porttitor efficitur elit, nec lobortis ante tristique vel.
          Vivamus ullamcorper lorem a diam dapibus elementum eget consequat mauris.
          In congue sollicitudin est. Duis commodo commodo augue. Duis laoreet lacinia nisl, sed luctus purus.
          Curabitur mollis volutpat arcu at fermentum. Cras fringilla est eget lobortis varius.
          Pellentesque a pretium velit, eget condimentum dolor. Nullam aliquet fermentum sapien eu tempus.
          Phasellus dapibus dolor convallis sapien rutrum, id volutpat enim malesuada. Sed massa nisi,
          interdum ac massa ac, euismod fringilla augue. Donec viverra rutrum varius.
        `,
        url: 'https://www.w3schools.com/html/mov_bbb.mp4'
      },
      {
        id: '3',
        name: 'Demo 3',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam',
        imgUrl: qluedashboardLogo,
        content: `
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et bibendum elit.
          In efficitur mi sodales tellus lacinia convallis. Sed aliquet euismod bibendum.
          Donec vehicula gravida lacus et varius. Cras mi leo, ultricies vel ante at, sodales ultrices magna.
          Fusce vehicula, nisi in bibendum euismod, elit tortor scelerisque libero, ac tincidunt sem nisi et eros.
          Cras porttitor congue tortor ac cursus. Nam nec fermentum lectus. Donec nec est nulla.
          Praesent vitae eros risus. Aliquam erat volutpat. Donec semper tincidunt nisi, sit amet pulvinar arcu vestibulum et.
          Integer porttitor efficitur elit, nec lobortis ante tristique vel.
          Vivamus ullamcorper lorem a diam dapibus elementum eget consequat mauris.
          In congue sollicitudin est. Duis commodo commodo augue. Duis laoreet lacinia nisl, sed luctus purus.
          Curabitur mollis volutpat arcu at fermentum. Cras fringilla est eget lobortis varius.
          Pellentesque a pretium velit, eget condimentum dolor. Nullam aliquet fermentum sapien eu tempus.
          Phasellus dapibus dolor convallis sapien rutrum, id volutpat enim malesuada. Sed massa nisi,
          interdum ac massa ac, euismod fringilla augue. Donec viverra rutrum varius.
        `,
        url: 'https://www.w3schools.com/html/mov_bbb.mp4'
      },
      {
        id: '4',
        name: 'Demo 4',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam',
        imgUrl: qluedashboardLogo,
        content: `
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et bibendum elit.
          In efficitur mi sodales tellus lacinia convallis. Sed aliquet euismod bibendum.
          Donec vehicula gravida lacus et varius. Cras mi leo, ultricies vel ante at, sodales ultrices magna.
          Fusce vehicula, nisi in bibendum euismod, elit tortor scelerisque libero, ac tincidunt sem nisi et eros.
          Cras porttitor congue tortor ac cursus. Nam nec fermentum lectus. Donec nec est nulla.
          Praesent vitae eros risus. Aliquam erat volutpat. Donec semper tincidunt nisi, sit amet pulvinar arcu vestibulum et.
          Integer porttitor efficitur elit, nec lobortis ante tristique vel.
          Vivamus ullamcorper lorem a diam dapibus elementum eget consequat mauris.
          In congue sollicitudin est. Duis commodo commodo augue. Duis laoreet lacinia nisl, sed luctus purus.
          Curabitur mollis volutpat arcu at fermentum. Cras fringilla est eget lobortis varius.
          Pellentesque a pretium velit, eget condimentum dolor. Nullam aliquet fermentum sapien eu tempus.
          Phasellus dapibus dolor convallis sapien rutrum, id volutpat enim malesuada. Sed massa nisi,
          interdum ac massa ac, euismod fringilla augue. Donec viverra rutrum varius.
        `,
        url: 'https://www.w3schools.com/html/mov_bbb.mp4'
      }
    ]
  },
  {
    id: '4',
    name: 'Live Feeds',
    imgUrl: qluedashboardLogo,
    items: [
      {
        id: '1',
        name: 'Demo 1',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam',
        imgUrl: qluedashboardLogo,
        content: `
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et bibendum elit.
          In efficitur mi sodales tellus lacinia convallis. Sed aliquet euismod bibendum.
          Donec vehicula gravida lacus et varius. Cras mi leo, ultricies vel ante at, sodales ultrices magna.
          Fusce vehicula, nisi in bibendum euismod, elit tortor scelerisque libero, ac tincidunt sem nisi et eros.
          Cras porttitor congue tortor ac cursus. Nam nec fermentum lectus. Donec nec est nulla.
          Praesent vitae eros risus. Aliquam erat volutpat. Donec semper tincidunt nisi, sit amet pulvinar arcu vestibulum et.
          Integer porttitor efficitur elit, nec lobortis ante tristique vel.
          Vivamus ullamcorper lorem a diam dapibus elementum eget consequat mauris.
          In congue sollicitudin est. Duis commodo commodo augue. Duis laoreet lacinia nisl, sed luctus purus.
          Curabitur mollis volutpat arcu at fermentum. Cras fringilla est eget lobortis varius.
          Pellentesque a pretium velit, eget condimentum dolor. Nullam aliquet fermentum sapien eu tempus.
          Phasellus dapibus dolor convallis sapien rutrum, id volutpat enim malesuada. Sed massa nisi,
          interdum ac massa ac, euismod fringilla augue. Donec viverra rutrum varius.
        `,
        url: 'https://www.w3schools.com/html/mov_bbb.mp4'
      },
      {
        id: '2',
        name: 'Demo 2',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam',
        imgUrl: qluedashboardLogo,
        content: `
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et bibendum elit.
          In efficitur mi sodales tellus lacinia convallis. Sed aliquet euismod bibendum.
          Donec vehicula gravida lacus et varius. Cras mi leo, ultricies vel ante at, sodales ultrices magna.
          Fusce vehicula, nisi in bibendum euismod, elit tortor scelerisque libero, ac tincidunt sem nisi et eros.
          Cras porttitor congue tortor ac cursus. Nam nec fermentum lectus. Donec nec est nulla.
          Praesent vitae eros risus. Aliquam erat volutpat. Donec semper tincidunt nisi, sit amet pulvinar arcu vestibulum et.
          Integer porttitor efficitur elit, nec lobortis ante tristique vel.
          Vivamus ullamcorper lorem a diam dapibus elementum eget consequat mauris.
          In congue sollicitudin est. Duis commodo commodo augue. Duis laoreet lacinia nisl, sed luctus purus.
          Curabitur mollis volutpat arcu at fermentum. Cras fringilla est eget lobortis varius.
          Pellentesque a pretium velit, eget condimentum dolor. Nullam aliquet fermentum sapien eu tempus.
          Phasellus dapibus dolor convallis sapien rutrum, id volutpat enim malesuada. Sed massa nisi,
          interdum ac massa ac, euismod fringilla augue. Donec viverra rutrum varius.
        `,
        url: 'https://www.w3schools.com/html/mov_bbb.mp4'
      },
      {
        id: '3',
        name: 'Demo 3',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam',
        imgUrl: qluedashboardLogo,
        content: `
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et bibendum elit.
          In efficitur mi sodales tellus lacinia convallis. Sed aliquet euismod bibendum.
          Donec vehicula gravida lacus et varius. Cras mi leo, ultricies vel ante at, sodales ultrices magna.
          Fusce vehicula, nisi in bibendum euismod, elit tortor scelerisque libero, ac tincidunt sem nisi et eros.
          Cras porttitor congue tortor ac cursus. Nam nec fermentum lectus. Donec nec est nulla.
          Praesent vitae eros risus. Aliquam erat volutpat. Donec semper tincidunt nisi, sit amet pulvinar arcu vestibulum et.
          Integer porttitor efficitur elit, nec lobortis ante tristique vel.
          Vivamus ullamcorper lorem a diam dapibus elementum eget consequat mauris.
          In congue sollicitudin est. Duis commodo commodo augue. Duis laoreet lacinia nisl, sed luctus purus.
          Curabitur mollis volutpat arcu at fermentum. Cras fringilla est eget lobortis varius.
          Pellentesque a pretium velit, eget condimentum dolor. Nullam aliquet fermentum sapien eu tempus.
          Phasellus dapibus dolor convallis sapien rutrum, id volutpat enim malesuada. Sed massa nisi,
          interdum ac massa ac, euismod fringilla augue. Donec viverra rutrum varius.
        `,
        url: 'https://www.w3schools.com/html/mov_bbb.mp4'
      },
      {
        id: '4',
        name: 'Demo 4',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam',
        imgUrl: qluedashboardLogo,
        content: `
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et bibendum elit.
          In efficitur mi sodales tellus lacinia convallis. Sed aliquet euismod bibendum.
          Donec vehicula gravida lacus et varius. Cras mi leo, ultricies vel ante at, sodales ultrices magna.
          Fusce vehicula, nisi in bibendum euismod, elit tortor scelerisque libero, ac tincidunt sem nisi et eros.
          Cras porttitor congue tortor ac cursus. Nam nec fermentum lectus. Donec nec est nulla.
          Praesent vitae eros risus. Aliquam erat volutpat. Donec semper tincidunt nisi, sit amet pulvinar arcu vestibulum et.
          Integer porttitor efficitur elit, nec lobortis ante tristique vel.
          Vivamus ullamcorper lorem a diam dapibus elementum eget consequat mauris.
          In congue sollicitudin est. Duis commodo commodo augue. Duis laoreet lacinia nisl, sed luctus purus.
          Curabitur mollis volutpat arcu at fermentum. Cras fringilla est eget lobortis varius.
          Pellentesque a pretium velit, eget condimentum dolor. Nullam aliquet fermentum sapien eu tempus.
          Phasellus dapibus dolor convallis sapien rutrum, id volutpat enim malesuada. Sed massa nisi,
          interdum ac massa ac, euismod fringilla augue. Donec viverra rutrum varius.
        `,
        url: 'https://www.w3schools.com/html/mov_bbb.mp4'
      }
    ]
  },
  {
    id: '5',
    name: 'Smart Device',
    imgUrl: qluedashboardLogo,
    items: [
      {
        id: '1',
        name: 'Demo 1',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam',
        imgUrl: qluedashboardLogo,
        content: `
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et bibendum elit.
          In efficitur mi sodales tellus lacinia convallis. Sed aliquet euismod bibendum.
          Donec vehicula gravida lacus et varius. Cras mi leo, ultricies vel ante at, sodales ultrices magna.
          Fusce vehicula, nisi in bibendum euismod, elit tortor scelerisque libero, ac tincidunt sem nisi et eros.
          Cras porttitor congue tortor ac cursus. Nam nec fermentum lectus. Donec nec est nulla.
          Praesent vitae eros risus. Aliquam erat volutpat. Donec semper tincidunt nisi, sit amet pulvinar arcu vestibulum et.
          Integer porttitor efficitur elit, nec lobortis ante tristique vel.
          Vivamus ullamcorper lorem a diam dapibus elementum eget consequat mauris.
          In congue sollicitudin est. Duis commodo commodo augue. Duis laoreet lacinia nisl, sed luctus purus.
          Curabitur mollis volutpat arcu at fermentum. Cras fringilla est eget lobortis varius.
          Pellentesque a pretium velit, eget condimentum dolor. Nullam aliquet fermentum sapien eu tempus.
          Phasellus dapibus dolor convallis sapien rutrum, id volutpat enim malesuada. Sed massa nisi,
          interdum ac massa ac, euismod fringilla augue. Donec viverra rutrum varius.
        `,
        url: 'https://www.w3schools.com/html/mov_bbb.mp4'
      },
      {
        id: '2',
        name: 'Demo 2',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam',
        imgUrl: qluedashboardLogo,
        content: `
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et bibendum elit.
          In efficitur mi sodales tellus lacinia convallis. Sed aliquet euismod bibendum.
          Donec vehicula gravida lacus et varius. Cras mi leo, ultricies vel ante at, sodales ultrices magna.
          Fusce vehicula, nisi in bibendum euismod, elit tortor scelerisque libero, ac tincidunt sem nisi et eros.
          Cras porttitor congue tortor ac cursus. Nam nec fermentum lectus. Donec nec est nulla.
          Praesent vitae eros risus. Aliquam erat volutpat. Donec semper tincidunt nisi, sit amet pulvinar arcu vestibulum et.
          Integer porttitor efficitur elit, nec lobortis ante tristique vel.
          Vivamus ullamcorper lorem a diam dapibus elementum eget consequat mauris.
          In congue sollicitudin est. Duis commodo commodo augue. Duis laoreet lacinia nisl, sed luctus purus.
          Curabitur mollis volutpat arcu at fermentum. Cras fringilla est eget lobortis varius.
          Pellentesque a pretium velit, eget condimentum dolor. Nullam aliquet fermentum sapien eu tempus.
          Phasellus dapibus dolor convallis sapien rutrum, id volutpat enim malesuada. Sed massa nisi,
          interdum ac massa ac, euismod fringilla augue. Donec viverra rutrum varius.
        `,
        url: 'https://www.w3schools.com/html/mov_bbb.mp4'
      },
      {
        id: '3',
        name: 'Demo 3',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam',
        imgUrl: qluedashboardLogo,
        content: `
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et bibendum elit.
          In efficitur mi sodales tellus lacinia convallis. Sed aliquet euismod bibendum.
          Donec vehicula gravida lacus et varius. Cras mi leo, ultricies vel ante at, sodales ultrices magna.
          Fusce vehicula, nisi in bibendum euismod, elit tortor scelerisque libero, ac tincidunt sem nisi et eros.
          Cras porttitor congue tortor ac cursus. Nam nec fermentum lectus. Donec nec est nulla.
          Praesent vitae eros risus. Aliquam erat volutpat. Donec semper tincidunt nisi, sit amet pulvinar arcu vestibulum et.
          Integer porttitor efficitur elit, nec lobortis ante tristique vel.
          Vivamus ullamcorper lorem a diam dapibus elementum eget consequat mauris.
          In congue sollicitudin est. Duis commodo commodo augue. Duis laoreet lacinia nisl, sed luctus purus.
          Curabitur mollis volutpat arcu at fermentum. Cras fringilla est eget lobortis varius.
          Pellentesque a pretium velit, eget condimentum dolor. Nullam aliquet fermentum sapien eu tempus.
          Phasellus dapibus dolor convallis sapien rutrum, id volutpat enim malesuada. Sed massa nisi,
          interdum ac massa ac, euismod fringilla augue. Donec viverra rutrum varius.
        `,
        url: 'https://www.w3schools.com/html/mov_bbb.mp4'
      },
      {
        id: '4',
        name: 'Demo 4',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam',
        imgUrl: qluedashboardLogo,
        content: `
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et bibendum elit.
          In efficitur mi sodales tellus lacinia convallis. Sed aliquet euismod bibendum.
          Donec vehicula gravida lacus et varius. Cras mi leo, ultricies vel ante at, sodales ultrices magna.
          Fusce vehicula, nisi in bibendum euismod, elit tortor scelerisque libero, ac tincidunt sem nisi et eros.
          Cras porttitor congue tortor ac cursus. Nam nec fermentum lectus. Donec nec est nulla.
          Praesent vitae eros risus. Aliquam erat volutpat. Donec semper tincidunt nisi, sit amet pulvinar arcu vestibulum et.
          Integer porttitor efficitur elit, nec lobortis ante tristique vel.
          Vivamus ullamcorper lorem a diam dapibus elementum eget consequat mauris.
          In congue sollicitudin est. Duis commodo commodo augue. Duis laoreet lacinia nisl, sed luctus purus.
          Curabitur mollis volutpat arcu at fermentum. Cras fringilla est eget lobortis varius.
          Pellentesque a pretium velit, eget condimentum dolor. Nullam aliquet fermentum sapien eu tempus.
          Phasellus dapibus dolor convallis sapien rutrum, id volutpat enim malesuada. Sed massa nisi,
          interdum ac massa ac, euismod fringilla augue. Donec viverra rutrum varius.
        `,
        url: 'https://www.w3schools.com/html/mov_bbb.mp4'
      }
    ]
  },
  {
    id: '6',
    name: 'Settings',
    imgUrl: qluedashboardLogo,
    items: [
      {
        id: '1',
        name: 'Demo 1',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam',
        imgUrl: qluedashboardLogo,
        content: `
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et bibendum elit.
          In efficitur mi sodales tellus lacinia convallis. Sed aliquet euismod bibendum.
          Donec vehicula gravida lacus et varius. Cras mi leo, ultricies vel ante at, sodales ultrices magna.
          Fusce vehicula, nisi in bibendum euismod, elit tortor scelerisque libero, ac tincidunt sem nisi et eros.
          Cras porttitor congue tortor ac cursus. Nam nec fermentum lectus. Donec nec est nulla.
          Praesent vitae eros risus. Aliquam erat volutpat. Donec semper tincidunt nisi, sit amet pulvinar arcu vestibulum et.
          Integer porttitor efficitur elit, nec lobortis ante tristique vel.
          Vivamus ullamcorper lorem a diam dapibus elementum eget consequat mauris.
          In congue sollicitudin est. Duis commodo commodo augue. Duis laoreet lacinia nisl, sed luctus purus.
          Curabitur mollis volutpat arcu at fermentum. Cras fringilla est eget lobortis varius.
          Pellentesque a pretium velit, eget condimentum dolor. Nullam aliquet fermentum sapien eu tempus.
          Phasellus dapibus dolor convallis sapien rutrum, id volutpat enim malesuada. Sed massa nisi,
          interdum ac massa ac, euismod fringilla augue. Donec viverra rutrum varius.
        `,
        url: 'https://www.w3schools.com/html/mov_bbb.mp4'
      },
      {
        id: '2',
        name: 'Demo 2',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam',
        imgUrl: qluedashboardLogo,
        content: `
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et bibendum elit.
          In efficitur mi sodales tellus lacinia convallis. Sed aliquet euismod bibendum.
          Donec vehicula gravida lacus et varius. Cras mi leo, ultricies vel ante at, sodales ultrices magna.
          Fusce vehicula, nisi in bibendum euismod, elit tortor scelerisque libero, ac tincidunt sem nisi et eros.
          Cras porttitor congue tortor ac cursus. Nam nec fermentum lectus. Donec nec est nulla.
          Praesent vitae eros risus. Aliquam erat volutpat. Donec semper tincidunt nisi, sit amet pulvinar arcu vestibulum et.
          Integer porttitor efficitur elit, nec lobortis ante tristique vel.
          Vivamus ullamcorper lorem a diam dapibus elementum eget consequat mauris.
          In congue sollicitudin est. Duis commodo commodo augue. Duis laoreet lacinia nisl, sed luctus purus.
          Curabitur mollis volutpat arcu at fermentum. Cras fringilla est eget lobortis varius.
          Pellentesque a pretium velit, eget condimentum dolor. Nullam aliquet fermentum sapien eu tempus.
          Phasellus dapibus dolor convallis sapien rutrum, id volutpat enim malesuada. Sed massa nisi,
          interdum ac massa ac, euismod fringilla augue. Donec viverra rutrum varius.
        `,
        url: 'https://www.w3schools.com/html/mov_bbb.mp4'
      },
      {
        id: '3',
        name: 'Demo 3',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam',
        imgUrl: qluedashboardLogo,
        content: `
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et bibendum elit.
          In efficitur mi sodales tellus lacinia convallis. Sed aliquet euismod bibendum.
          Donec vehicula gravida lacus et varius. Cras mi leo, ultricies vel ante at, sodales ultrices magna.
          Fusce vehicula, nisi in bibendum euismod, elit tortor scelerisque libero, ac tincidunt sem nisi et eros.
          Cras porttitor congue tortor ac cursus. Nam nec fermentum lectus. Donec nec est nulla.
          Praesent vitae eros risus. Aliquam erat volutpat. Donec semper tincidunt nisi, sit amet pulvinar arcu vestibulum et.
          Integer porttitor efficitur elit, nec lobortis ante tristique vel.
          Vivamus ullamcorper lorem a diam dapibus elementum eget consequat mauris.
          In congue sollicitudin est. Duis commodo commodo augue. Duis laoreet lacinia nisl, sed luctus purus.
          Curabitur mollis volutpat arcu at fermentum. Cras fringilla est eget lobortis varius.
          Pellentesque a pretium velit, eget condimentum dolor. Nullam aliquet fermentum sapien eu tempus.
          Phasellus dapibus dolor convallis sapien rutrum, id volutpat enim malesuada. Sed massa nisi,
          interdum ac massa ac, euismod fringilla augue. Donec viverra rutrum varius.
        `,
        url: 'https://www.w3schools.com/html/mov_bbb.mp4'
      },
      {
        id: '4',
        name: 'Demo 4',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam',
        imgUrl: qluedashboardLogo,
        content: `
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et bibendum elit.
          In efficitur mi sodales tellus lacinia convallis. Sed aliquet euismod bibendum.
          Donec vehicula gravida lacus et varius. Cras mi leo, ultricies vel ante at, sodales ultrices magna.
          Fusce vehicula, nisi in bibendum euismod, elit tortor scelerisque libero, ac tincidunt sem nisi et eros.
          Cras porttitor congue tortor ac cursus. Nam nec fermentum lectus. Donec nec est nulla.
          Praesent vitae eros risus. Aliquam erat volutpat. Donec semper tincidunt nisi, sit amet pulvinar arcu vestibulum et.
          Integer porttitor efficitur elit, nec lobortis ante tristique vel.
          Vivamus ullamcorper lorem a diam dapibus elementum eget consequat mauris.
          In congue sollicitudin est. Duis commodo commodo augue. Duis laoreet lacinia nisl, sed luctus purus.
          Curabitur mollis volutpat arcu at fermentum. Cras fringilla est eget lobortis varius.
          Pellentesque a pretium velit, eget condimentum dolor. Nullam aliquet fermentum sapien eu tempus.
          Phasellus dapibus dolor convallis sapien rutrum, id volutpat enim malesuada. Sed massa nisi,
          interdum ac massa ac, euismod fringilla augue. Donec viverra rutrum varius.
        `,
        url: 'https://www.w3schools.com/html/mov_bbb.mp4'
      }
    ]
  }
]
