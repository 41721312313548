import React from 'react'
import { navigate } from '@reach/router'
import { Paragraph, Text } from '@qlue/react-component/dist/QlueTypography'
import { IconWorkgroup, IconCctv, IconQlueVisionAgentWhite } from 'commons/assets/images'
import { OS } from 'pages/Settings/SmartCctv/ButtonDownloadQlueVision'
import theme from '@qlue/react-component/dist/styles/theme'

export const MANUAL_GUIDE = [
  {
    id: '1',
    name: 'Get started with Workgroup',
    imgUrl: IconWorkgroup,
    data: [
      {
        title: 'A brief overview of QlueWork',
        subTitle: (
          <div>
            QlueWork is a comprehensive mobile workforce management application for on-ground
            personnel to coordinate, report and resolve issues. All of task will appear on{' '}
            <strong>Workgroup</strong> Menu
          </div>
        ),
        contents: []
      },
      {
        title: 'Workgroup: 3 Basic steps',
        contents: [`Choose Category`, 'Invite User', 'Setup Workgroup']
      },
      {
        title: 'Choose Category',
        contents: [
          <div>
            Go to <strong>Setting {`>`} Category</strong>
          </div>,
          <div>
            In the top-right corner, select <strong>Choose Category.</strong>
          </div>,
          `In the left box,choose your category related to your field. for example search 'Cleanliness'`,
          'Selected Categories will appear in the right box',
          <div>
            Click <strong>Save</strong> When you are finished
          </div>
        ]
      },
      {
        title: 'Invite User',
        subTitle:
          'To trying Workgroup in full cycle, you need two user contains one manager & one officer in minimum',
        footer:
          'User will get email to verify the account & set the password for QlueWork Mobile Apps login',
        contents: [
          <div>
            Go to <strong>Setting {`>`} User</strong>
          </div>,
          <div>
            In the top-right corner, select <strong>New</strong> to add New User
          </div>,
          <div>
            Choose <strong>Fill form</strong> to create new one, then input user data.
          </div>,
          <div>
            <div>Two types of Roles:</div>
            <ol type="a">
              <li>
                <Paragraph>Manager, monitoring all of task information</Paragraph>
              </li>
              <li>
                <Paragraph>Officer, on-ground personnel mostly as task worker</Paragraph>
              </li>
            </ol>
          </div>,
          <div>
            Click <strong>Add</strong> to invite user through their email
          </div>
        ]
      },
      {
        title: 'Setup Workgroup',
        subTitle:
          'Make sure all of users already verified their account that invited through email',
        contents: [
          <div>
            Go to <strong>Workgroup {`>`} Create Workgroup</strong>
          </div>,
          <div>
            Fill in data in <em>Workgroup Detail</em>, workgroup name can be general or spesific
            such as division name, field issues , etc
          </div>,
          <div>
            Choose manager & officer that related to the Workgroup. In <em>Manage Officers</em>,
            only users that already verified their account are appear here.
          </div>,
          <div>
            Click <strong>Confirm</strong> to create workgroup. Hola! your team are already set
          </div>
        ]
      },
      {
        title: 'QlueWork Mobile Apps',
        subTitle:
          'QlueWork is the mobile app to respond and resolve issues,was first utilized to empower all public servants (e.g., transportation, sanitation, security, etc) to direct response to citizen reports,with benefits:',
        contents: [
          'Improve on-ground coordination and accountability of personnel.',
          'Breaks down long processes for issue monitoring and resolution.',
          'Feature-rich yet user-friendly that can be used by anyone in the organization.'
        ]
      },
      {
        title: 'Create Task',
        subTitle: `
            Create task only available on QlueWork mobile apps, report can  using a photo, video, voice note or
            even text, for team or others to ensure reports are resolved by the appropriate team members. On the
            list task, you can view tasks assigned to you, process and complete using image/video to ensure accountability.          
          `,
        contents: []
      },
      {
        title: 'Task on Workgroup',
        subTitle: (
          <div>
            Shows the number of all issues reported, detailed with media, location, time and status.
            The integration allows reports from smart cctv,etc to be converted into actionable
            tasks. You can view this on menu{' '}
            <strong>
              Workgroup {`>`} Workgroup Name {`>`} Task List
            </strong>
          </div>
        ),
        contents: []
      }
    ]
  },
  {
    id: '2',
    name: 'Explore Smart CCTV',
    imgUrl: IconCctv,
    data: [
      {
        title: 'Setup CCTV',
        subTitle: 'Please follow this simple guide to setup your CCTV:',
        contents: [
          <div>
            Go to <strong>Setting {`>`} Smart CCTV</strong>
          </div>,
          <div>
            <strong>Download</strong> QlueVision Agent (QVA) first If you don’t have IP public or
            private server
          </div>,
          <div>
            Then <strong>Add CCTV</strong> to connect & sync your cctv
          </div>
        ],
        footer: ''
      },
      {
        title: 'QlueVision Agent',
        subTitle: (
          <div>
            <div>
              QlueVision Agent are our desktop application that convert your local cctv to became
              public,that accessible from outside your local network. Also QlueVision is a
              cutting-edge technology leveraging artificial intelligence (AI) for Vision integrated
              to the CCTV system to automatically recognize specific objects, optimizing security
              and safety operations. Works with any type of camera with an award-winning compression
              system and optimization level to ensure light AI processing.
            </div>
            <p>
              Detecting human-object through face recognition, face mask detection, people line
              counting, and intrusion detection. Also functioning as traffic monitoring through
              license plate recognition, vehicle counting and classification, and illegal parking.
            </p>
          </div>
        ),
        contents: []
      },
      {
        title: 'CCTV Information',
        subTitle: (
          <div>
            Explenation about cctv information when you are on{' '}
            <strong>
              Setting {`>`} Smart CCTV {`>`} Add CCTV:
            </strong>
          </div>
        ),
        contents: [
          <div>
            <strong>Source type</strong>, This source type will determined your server location. If
            You register from our website please choose <strong>Cloud</strong>, however you can use{' '}
            <strong>Private</strong> server if you need more capacity by contact our team.
          </div>,
          <div>
            <strong>Site</strong>, This option is related to your private server, please choose your
            site installed our computer vision AI
          </div>,
          <div>
            <strong>Engine</strong>: Please choose computer vision AI Engine that you want to used
            in order to connect with your CCTV
          </div>,
          <div>
            <strong>CCTV Source</strong>: Please choose QV Agent If you don’t have IP Public or URL
            that accessible from outside your local network. If you do have IP Public please choose
            private then put Your RTSP URL with IP Public on CCTV Link field. Example:{' '}
            <em>rtsp://123.232.232.121:554</em>
          </div>,
          <div>
            <strong>CCTV Stream</strong>: Please choose your stream that has been added from CCTV
            Agent.
          </div>,
          <div>
            <strong>CCTV Name</strong>: You can give your CCTV based on your needs. this name will
            be showed as your live feeds name on Live Feeds Menu.
          </div>,
          <div>
            <strong>Description</strong>: Write down the information of your CCTV to make easier
            identified.
          </div>
        ],
        footer: ''
      },
      {
        title: 'About Annotation',
        subTitle: (
          <div>
            In <strong>Add New CCTV</strong> page,after choose and fill in{' '}
            <strong>CCTV Detail</strong> you will create <strong>Annotation</strong>. This is
            explanation how to create detected area (annotation) for each engines:
          </div>
        ),
        contents: [
          <div>
            Face Recognition, you are expected to create <strong>shape like a box</strong>, either
            square,rectangle,or so ever in cctv area that most people will pass to recognize their
            faces.
          </div>,
          <div>
            Face Mask Detection,similar with face recognition,create shape like a box{' '}
            <strong>or even trapezium</strong> to recognize their faces with mask or no mask.
          </div>,
          <div>
            People Line Counting, will count people passing defined by counting line. So, you are
            expected to create <strong>three lines spaced wide enough</strong>. First and third line
            will counting you in and out,second line will define in between.
          </div>,
          <div>
            Intrusion Detection, create a <strong>square whole cctv area circularly</strong>. This
            will record only on the specific event while camera are detecting intrusion
          </div>,
          <div>
            License Plate Recognition, will capture snapshot of illegally parking vehicles. Please
            set boundary by creating shape like square,rectangle,trapezium, or so ever to determine
            illegal parking within an area.
          </div>,
          <div>
            Vehicle Counting & Categorization, you are expected to create{' '}
            <strong>shape like a box</strong> in cctv area that most vehicles will pass to recognice
            their plate number.
          </div>,
          <div>
            Illegal Parking, <strong>create one line</strong> horizontaly or verticaly from one side
            to another side. This engine will count vehicle passing defined by that one counting
            line based on their categories: truck, car, bus, & motorcycle.
          </div>
        ],
        footer: ''
      },
      {
        title: 'Enrollment',
        subTitle:
          'Enrollment means object registration to be recognize as person by their face or recognize vehicle by their plate number powered by our artificial intelligence (QlueVision)',
        contents: [
          <div>
            Go to <strong>Setting {`>`} Enrollment</strong>
          </div>,
          <div>
            Choose engine that you want to register,only for <strong>Face Recognition</strong> or{' '}
            <strong>License Plate Recognition</strong>
          </div>,
          <div>
            <div>
              Then create <strong>New Enrollment</strong> for:
            </div>
            <ol type="a">
              <li>
                <Paragraph>
                  <strong>Face Recognition</strong>, fill in person name & upload photo.
                </Paragraph>
              </li>
              <li>
                <Paragraph>
                  <strong>License Plate Recognition</strong>, fill in plate number & vehicle owner.
                </Paragraph>
              </li>
            </ol>
          </div>,
          'Two types of enrollment, whitelist & blacklist.',
          'Successfull enrollment will be found on the list of each enrollment'
        ],
        footer: ''
      }
    ]
  },
  {
    id: '3',
    name: 'QlueVision Agent',
    imgUrl: IconQlueVisionAgentWhite,
    data: [
      {
        title: 'Connect Your Local CCTV Using QlueVision Agent',
        subTitle: `
          QlueVision Agent is a desktop application that is required to connect Your local CCTV
          to Qlue Computer Vision AI Engine. This application can help You if you don’t have
          Public IP Address. Please follow these instructions to use QlueVision Agent (QV
          Agent).
        `,
        contents: [
          <div>
            <div>Download</div>
            <p>
              Please download the QV Agent application by downloading QV Agent installer based on
              Your operating system.
            </p>
            <ol type="a">
              <li>
                <Paragraph>
                  Windows (
                  <a download={OS.windows.download} href={OS.windows.href}>
                    download here
                  </a>
                  )
                </Paragraph>
              </li>
              <li>
                <Paragraph>
                  Mac OS (
                  <a download={OS.mac.download} href={OS.mac.href}>
                    download here
                  </a>
                  )
                </Paragraph>
              </li>
              <li>
                <Paragraph>
                  Ubuntu (
                  <a download={OS.linux.download} href={OS.linux.href}>
                    download here
                  </a>
                  )
                </Paragraph>
              </li>
            </ol>
          </div>,
          <div>
            <div>Install</div>
            <p>After downloading, please install the application by following the instructions.</p>
          </div>,
          <div>
            <div>Login</div>
            <p>
              After successful installation, please login using Your credentials that same as
              Dashboard credentials.
            </p>
          </div>,
          <div>
            <div>Add CCTV</div>
            <ol type="a">
              <li className="mb2">
                <div>CCTV Name</div>
                <Paragraph>
                  Please filled it using your prefer name to make You easier identified the CCTV
                </Paragraph>
              </li>
              <li className="mb2">
                <div>RTSP URL</div>
                <Paragraph>
                  IP address that belongs to Your IP Cam or IP CCTV. You can find it on Your CCTV
                  Admin dashboard. For example: rtsp://192.168.0.108:554
                </Paragraph>
              </li>
              <li className="mb2">
                <div>Credential</div>
                <Paragraph>
                  If Your CCTV required credentials please filled with Your CCTV credential that
                  consist of username and password
                </Paragraph>
              </li>
              <li className="mb2">
                <div>FPS</div>
                <Paragraph>
                  This is optional but You can adjust Frame per second considering Your CCTV
                  capabilities
                </Paragraph>
              </li>
              <li className="mb2">
                <div>Bitrate</div>
                <Paragraph>This is optional but You can adjust the bitrate</Paragraph>
              </li>
              <li className="mb2">
                <div>Region</div>
                <Paragraph>
                  Please choose region where Your CCTV location by choosing the country
                </Paragraph>
              </li>
              <li className="mb2">
                <div>Resolution</div>
                <Paragraph>
                  This is optional but You can adjust the resolution of Your CCTV
                </Paragraph>
              </li>
              <li className="mb2">
                <div>Show Preview</div>
                <Paragraph>
                  Please click Show Preview button to make sure You are add the right CCTV. After
                  filled in all the field please click button <strong>Add</strong> to add Your CCTV
                </Paragraph>
              </li>
              <li>
                <div>Check CCTV is connect or not</div>
                <Paragraph>
                  If You have added your CCTV Please check on CCTV list the status is connected. It
                  means Your CCTV is ready to integrate to Qlue Computer Vision Ai. The next step is
                  please configure it on the dashboard by following the instructions{' '}
                  <Text
                    color={theme.primaryColor}
                    className="pointer"
                    onClick={() => navigate('/settings/smart-cctv')}
                  >
                    here
                  </Text>
                  .
                </Paragraph>
              </li>
            </ol>
          </div>
        ]
      }
    ]
  },
  {
    id: '4',
    name: 'CCTV Alert Overview',
    imgUrl: IconCctv,
    data: [
      {
        title: 'About CCTV Alert',
        subTitle: (
          <div>
            CCTV Alert means to create some rules for your CCTV to produce alert, to shows the
            number of all issues reported from your cctv, detailed with media, location, time and
            status. this task will send to your QlueWork mobile apps directly,also can be converted
            into actionable tasks by on-ground personnel.
            <br />
            <br />
            This action are related with previous guide, you have to complete this step before:
          </div>
        ),
        contents: [
          <div>
            Already have Workgroup , Go to <strong>Workgroup {`>`} Create Workgroup</strong> to have
            one
          </div>,
          <div>
            Already sync your CCTV to our dashboard, Go to{' '}
            <strong>
              Setting {`>`} Smart CCTV {`>`} + New CCTV
            </strong>{' '}
            to have one
          </div>,
          <div>
            You have to create <strong>New Enrollment</strong>, valid only for{' '}
            <strong>Face Recognition</strong> and <strong>License Plate Recognition Engine</strong>.
            Go to <strong>Setting {`>`} Enrollment</strong> to have one
          </div>,
          'You are ready to setup CCTV alert'
        ]
      },
      {
        title: 'Setup CCTV Alert',
        subTitle: 'Please follow this simple guide to setup your CCTV Alert:',
        contents: [
          <div>
            Go to{' '}
            <strong>
              Workgroup {`>`} Workgroup Name {`>`} CCTV Alert
            </strong>
          </div>,
          <div>
            Then click <strong>+ New CCTV Alert</strong> button to create some rules for your CCTV
            to produce alert
          </div>
        ]
      },
      {
        title: 'Choose CCTV',
        subTitle: (
          <div>
            Please choose computer vision AI Engine name that you want to used in order to produce
            alert when you are on{' '}
            <strong>
              Workgroup {`>`} Workgroup Name {`>`} CCTV Alert {`>`} + New CCTV Alert
            </strong>
            <br />
            After choose CCTV there will be information related below:
          </div>
        ),
        contents: [
          <div>
            <strong>Engine</strong>: A Computer vision AI Engine that you subscribe on plan
          </div>,
          <div>
            <strong>CCTV Link</strong>: Your RTSP URL with IP Public
          </div>,
          <div>
            <strong>Description</strong>: The Information of your CCTV to make easier identified
          </div>,
          <div>
            <strong>Location</strong>: A Place where your CCTV are
          </div>,
          <div>
            <strong>Live Preview</strong>: Video or Image thumbnails to make sure CCTV that you
            choose are right
          </div>
        ]
      },
      {
        title: 'Task & Enrollment',
        subTitle: (
          <div>
            Explanation when you are fill in the form on{' '}
            <strong>
              Workgroup {`>`} Workgroup Name {`>`} CCTV Alert {`>`} + New CCTV Alert {`>`} Choose
              CCTV
            </strong>
            :
          </div>
        ),
        contents: [
          <div>
            <strong>CCTV Alert or Task Title</strong>, Input name of this CCTV configuration here
          </div>,
          <div>
            <strong>Task Category</strong>, Choose your category related to your field. for example
            ‘Cleanliness’
          </div>,
          <div>
            <strong>Task Priority</strong>, Choose your priority between low, medium, or high
          </div>,
          <div>
            <strong>Person in Charge</strong>, Assign manager to responsible on this task
          </div>,
          <div>
            <strong>Problem Handler</strong>, Assign on-ground personnel to responsible for this
            task
          </div>,
          <div>
            <strong>Report Message</strong>, Fill in a message that will be appear if cctv alert are
            created
          </div>,
          <div>
            <strong>Time to Generate Task</strong>, Set day, hour, and interval for task creation
          </div>,
          <div>
            <strong>Enrollment</strong>, Add list of people/vehicle on enrollment for FR or LPR
            Engine
          </div>,
          <div>
            <strong>Alert</strong>, will show wherever you are on the dashboard if box alert are
            checked
          </div>
        ]
      },
      {
        title: 'Task from CCTV',
        subTitle: (
          <div>
            Shows the number of all issues reported from CCTV, detailed with media, location, time
            and status. Also can be converted into actionable tasks. Possibility filtered per month
            and can be downloaded into spreadsheet file . You can view this on menu{' '}
            <strong>
              Workgroup {`>`} Workgroup Name {`>`} Task from CCTV
            </strong>
          </div>
        ),
        contents: []
      }
    ]
  }
]
