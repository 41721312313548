import { getUnixTime, fromUnixTime } from 'date-fns'

const getTimestamp = ({ second = 0, minute = 0, hour = 0, date = 1, month = 1, year = 2020 }) => {
  return Date.UTC(year, month, date, hour, minute, second)
}

/**
 * Get Local Date Time and convert to Unix
 *
 * @param {Date/Unix} date
 */
const getUnixLocalTime = d => {
  const isDateObject = typeof d === 'object'
  const date = isDateObject ? d : fromUnixTime(d)

  const dateObject = {
    year: date.getFullYear(),
    month: date.getMonth(),
    date: date.getDate(),
    hour: date.getHours(),
    minute: date.getMinutes(),
    second: date.getSeconds()
  }

  const localDateTime = getTimestamp(dateObject)
  const localUnixTime = getUnixTime(localDateTime)

  return localUnixTime
}

export default getUnixLocalTime
