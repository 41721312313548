import styled from 'styled-components'
import { BaseModal } from '@qlue/react-component/dist/Modal'
import { BaseButton } from '@qlue/react-component/dist/QlueButton'

export default {
  Avatar: styled.div`
    width: 124px;

    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  `,
  Button: styled(BaseButton)`
    font-size: 20px;
    font-weight: 400;
  `,
  Modal: styled(BaseModal)`
    > div:first-child {
      border-radius: 4px;
      border: none;
      padding: 43px 40px;
    }
  `,
  Text: styled.div`
    margin-top: 25px;
    text-decoration: underline;
    font-size: 16px;
    cursor: pointer;
  `
}
