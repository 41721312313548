export const WIN_FILENAME_PRD = 'QlueVision-Agent-Setup-1.2.1.exe'
export const MAC_FILENAME_PRD = 'QlueVision-Agent-1.2.1-universal.dmg'
export const LNX_FILENAME_PRD = 'qlue-vision_1.2.1_amd64.deb'

export const WIN_HREF_PRD =
  'https://qluevision-saas-assets.s3.ap-southeast-1.amazonaws.com/v-1.2.1/QlueVision-Agent-Setup-1.2.1.exe'
export const MAC_HREF_PRD =
  'https://qluevision-saas-assets.s3.ap-southeast-1.amazonaws.com/v-1.2.1/QlueVision-Agent-1.2.1-universal.dmg'
export const LNX_HREF_PRD =
  'https://qluevision-saas-assets.s3.ap-southeast-1.amazonaws.com/v-1.2.1/qlue-vision_1.2.1_amd64.deb'

export const WIN_FILENAME_STG = 'QlueVision-Agent-stg-Setup-1.2.1.exe'
export const MAC_FILENAME_STG = 'QlueVision-Agent-stg-1.2.1-universal.dmg'
export const LNX_FILENAME_STG = 'qlue-vision-stg_1.2.1_amd64.deb'

export const WIN_HREF_STG =
  'https://qluevision-saas-assets.s3.ap-southeast-1.amazonaws.com/v-1.2.1/QlueVision-Agent-stg-Setup-1.2.1.exe'
export const MAC_HREF_STG =
  'https://qluevision-saas-assets.s3.ap-southeast-1.amazonaws.com/v-1.2.1/QlueVision-Agent-stg-1.2.1-universal.dmg'
export const LNX_HREF_STG =
  'https://qluevision-saas-assets.s3.ap-southeast-1.amazonaws.com/v-1.2.1/qlue-vision-stg_1.2.1_amd64.deb'
