import React from 'react'
import { Wrapper, Title } from './styles'
import { BaseButton as Button } from '@qlue/react-component/dist/QlueButton'
import theme from '@qlue/react-component/dist/styles/theme'

function PopupLocation({ handleLocation }) {
  return (
    <Wrapper>
      <Title>Maps</Title>
      <span>‘Allow location’ to view tasks from workgroups, to </span>
      <span>tracking users, and to view layers.</span>
      <Button onClick={handleLocation} customColor={theme.primaryColor} rounded>
        okay
      </Button>
    </Wrapper>
  )
}

export default PopupLocation
