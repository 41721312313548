import React from 'react'
import Styled from './styles'
import theme from '@qlue/react-component/dist/styles/theme'
import { Text } from '@qlue/react-component/dist/QlueTypography'
import { ArrayMap } from '@qlue/react-component/dist/utils'
import { borderedCheck, borderedUncheck } from 'commons/assets/images'

function CardProgress({ id, isChecked, title, content }) {
  function onClick() {
    return id
  }

  return (
    <Styled.Progress onClick={onClick}>
      <div className="check">
        <img src={isChecked ? borderedCheck : borderedUncheck} alt="bordered-check" />
      </div>
      <div className="body">
        <Text className="db fs-18px fw5">{title}</Text>
        <Text className="db fs-14px" color={theme.primaryTextColorTrans}>
          {content}
        </Text>
      </div>
    </Styled.Progress>
  )
}

function Content({ type }) {
  const data = localStorage.getItem('savedGettingStarted')
    ? JSON.parse(localStorage.getItem('savedGettingStarted'))[type] || []
    : []
  return (
    <Styled.Wrapper>
      <Text className="mb2 db fw5 fs-14px title">Your Progress</Text>
      <div className="progress">
        <Text className="mr2 fs-14px">0%</Text>
        <Styled.ProgressBar width="85%" height="12px" percentage={0} color={theme.primaryColor} />
      </div>
      <ArrayMap data={data}>{d => <CardProgress key={d.id} {...d} />}</ArrayMap>
    </Styled.Wrapper>
  )
}

function ContentGettingStarted({ type }) {
  return <Content type={type} />
}

export default ContentGettingStarted
