import React from 'react'
import FlexContainer from '@qlue/react-component/dist/FlexContainer'
import { H3, Paragraph } from '@qlue/react-component/dist/QlueTypography'
import { qlue } from 'commons/assets/images'
import { useStore } from 'shells/AppShell'

import Styled from './styles'

function ModalStartTour({ onClick }) {
  const {
    showcase: { exit }
  } = useStore()
  return (
    <Styled.Modal visible width="440px" height="auto" border closeIcon={false} bgColor="#303747">
      <FlexContainer flexDirection="column" alignItems="center" justifyContent="center">
        <Styled.Avatar className="mb3">
          <img alt="qlue" src={qlue} />
        </Styled.Avatar>
        <H3>Welcome To Qlue Dashboard!</H3>
        <Paragraph className="fs-18px mv4 tc">
          In this next steps of interactive guide, we will show you how to use Qlue Dashboard for
          powerful visualizations.
        </Paragraph>
        <Styled.Button
          width="100%"
          height="52px"
          primary
          className="button-start"
          onClick={onClick}
        >
          Start The Tour!
        </Styled.Button>
        <Styled.Text id="skip-guide" onClick={() => exit(true)}>
          Skip Guide
        </Styled.Text>
      </FlexContainer>
    </Styled.Modal>
  )
}

export default ModalStartTour
