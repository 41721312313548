import styled, { css } from 'styled-components'
import { BaseModal } from '@qlue/react-component/dist/Modal'

export default {
  Modal: styled(BaseModal)`
    > div:first-child {
      ${props =>
        props.bounding &&
        css`
          top: ${props => `${props.bounding.y}px`};
          ${props.isLeft
            ? css`
                left: ${props => `${props.bounding.x}px`};
              `
            : css`
                right: ${props => `${props.bounding.x}px`};
              `};
        `}
      border: none;
      padding: 28px;
    }
  `
}
