import React, { useState, useMemo, useContext, useEffect } from 'react'
import qs from 'query-string'
import { usePrevious } from '@qlue/react-component/dist/hooks'
import { useSelector } from 'react-redux'

import Tab from 'utils/Tab'
import useFilterTab from 'commons/hooks/useFilterTab'
import { useStore as useStoreSidebar } from 'shells/AppShell'
import { IconEmptyResult, IconLoading } from '@qlue/react-component/dist/QlueIcon'

import taskListQuery from 'pages/Workgroup/Qluework/DynamicPath/TaskList/queryParams'
import ModalShowcase from 'shells/AppShell/Components/Showcase/ModalShowcase'

const TabShellContext = React.createContext()

const tabList = {
  Initial: [],
  Analytic: [],
  Settings: [],
  Attendance: [
    { id: 1, name: 'Real-time Data', path: 'realtime' },
    { id: 2, name: 'Attendance', path: 'list' }
  ],
  Profile: [
    { id: 1, name: 'Profile Info', path: 'info' },
    { id: 2, name: 'Credential', path: 'credential' },
    { id: 3, name: 'Plan', path: 'plan' }
  ],
  WorkgroupDetail: [
    { id: 1, name: 'Detail', path: 'detail' },
    { id: 2, name: 'Officers', path: 'list-of-participants' },
    { id: 3, name: 'CCTV Alert', path: 'cctv-rules' },
    {
      id: 4,
      name: 'Task List',
      path: 'task-list',
      queryParams: taskListQuery
    },
    { id: 5, name: 'Task from CCTV', path: 'task-cctv' },
    { id: 6, name: 'Report from Citizen', path: 'report-citizen' }
  ],
  DetailAnalytic: [
    { id: 1, name: 'Completed Task', path: 'completed-task' },
    { id: 2, name: 'Average Completion Time', path: 'average-completion-time' },
    { id: 3, name: 'Task Status', path: 'task-status' }
  ],
  DetailWorkgroup: [
    { id: 1, name: 'Completed Task', path: 'completed-task' },
    { id: 2, name: 'Average Completion Time', path: 'average-completion-time' },
    { id: 3, name: 'Most Reported Categories', path: 'most-reported-categories' },
    { id: 4, name: 'Task Status', path: 'task-status' },
    { id: 5, name: 'Task Priority', path: 'task-priority' }
  ],
  SettingsSmartCCTV: [
    { id: 1, name: 'Site', path: 'site' },
    { id: 2, name: 'Enrollment', path: 'enrollment' }
  ],
  SettingsSmartCCTVSiteDetail: [
    { id: 1, name: 'Site Info', path: 'detail' },
    { id: 2, name: 'Subscription', path: 'subscription' },
    { id: 3, name: 'CCTV List', path: 'cctv-list' }
  ],
  SettingsCompanyProfile: [
    { id: 1, name: 'Company Info', path: 'info' },
    { id: 2, name: 'Credential', path: 'credential' },
    { id: 3, name: 'Plan', path: 'plan' }
  ],
  SettingsThermal: [
    { id: 1, name: 'Device List', path: 'device-list' },
    { id: 2, name: 'Enrollment', path: 'enrollment' }
  ],
  SettingsDTC: [{ id: 1, name: 'Device List', path: 'device-list' }],
  SettingsBioniqa: [{ id: 1, name: 'Device List', path: 'device-list' }],
  AnalyticAttendance: [
    { id: 1, name: 'Detail', path: 'detail' },
    { id: 2, name: 'Status', path: 'status' }
  ],
  AnalyticAttendanceLine: [
    { id: 1, name: 'Attendance Status', path: 'attendance-status' },
    { id: 2, name: 'In-Time Statistic', path: 'intime-statistic' },
    { id: 3, name: 'Avg. Work Duration', path: 'average-work-duration' },
    { id: 4, name: 'Most Late Comers', path: 'most-late-comers' },
    { id: 5, name: 'Most On-Timers', path: 'most-on-timers' },
    { id: 6, name: 'Most Absence', path: 'most-absence' }
  ],
  Events: [
    { id: 1, name: 'Real Time', path: 'realtime' },
    { id: 2, name: 'Alert Rules', path: 'alert-rules' }
  ],
  Workgroup: [
    { id: 1, name: 'Workgroup', path: 'qluework' },
    { id: 2, name: 'Task List', path: 'task-list' },
    { id: 3, name: 'Task from CCTV', path: 'task-cctv' },
    { id: 4, name: 'Task from Citizen', path: 'task-citizen' }
  ]
}

const TabShell = ({ children, useTab, useTitle, navigate, fetchTab, ...restProps }) => {
  const { pathname } = restProps.location
  const { tabs, setTabs, isLoading } = useFilterTab(tabList, useTab, fetchTab, pathname, navigate)

  const [selectedTab, setSelectedTab] = useState(1)
  const [title, setTitle] = useState(useTitle)
  const [backNavigation, setBackNavigation] = useState(false)
  const [hideTab, setHideTab] = useState(false)
  const [returnPath, setReturnPath] = useState('../')
  const [bounding, setBounding] = useState(null)
  const toggleShowBackNav = is => setBackNavigation(v => is || !v)
  const detailTab = useMemo(() => {
    return tabs?.[selectedTab - 1]
  }, [selectedTab, tabs])

  function onHandleTab(val) {
    if (!restProps.noNavigate) {
      setSelectedTab(val.id)
      if (val.path) {
        if (!val.queryParams) navigate(val.path + restProps.location.search)
        else navigate(val.path + '?' + qs.stringify(val.queryParams))
      }
    } else setSelectedTab(val.id)
  }

  /**
   * The conditional effect that trigger auto redirect
   * When the current last pathname not including on each path of tabs
   */
  useEffect(() => {
    if (!!tabs.length && !!pathname) {
      const splitedPathname = pathname.split('/')
      const mappingPathTabs = tabs.map(({ path }) => path)
      if (mappingPathTabs.indexOf(splitedPathname[splitedPathname.length - 1]) === -1) {
        navigate(`${pathname}/${mappingPathTabs[0]}`)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, tabs])

  /**
   * Setup notification Tab for workgroup module and then
   * hide some tab if module Live-feeds not required
   */
  const { menuActive, showcase, additionalModule } = useStoreSidebar()
  const notifications = useSelector(({ notification }) => notification.data)
  const modules = useSelector(({ module }) => Object.keys(module.moduleFeatures))
  const hasModuleLiveFeeds = modules.some(module => module.search(/smart cctv/gi) !== -1)
  const isWorkgroupMenu = menuActive.search(/workgroup/gi) !== -1
  useNotificationWorkgroup({
    notifications,
    detailTab,
    isWorkgroupMenu,
    navigate,
    restProps,
    onHandleTab,
    tabs
  })

  const constructTabs = useMemo(() => {
    if (isWorkgroupMenu && tabs.length > 0) {
      let copyTabs = [...tabs]

      const indexTaskList = copyTabs.findIndex(tab => tab.name.search(/task list/gi) !== -1)
      const indexTaskCCTV = copyTabs.findIndex(tab => tab.name.search(/task from cctv/gi) !== -1)
      const taskList = { ...copyTabs[indexTaskList], notif: 0 }
      const taskCCTV = { ...copyTabs[indexTaskCCTV], notif: 0 }

      for (let i = 0; i < notifications.length; i++) {
        const notif = notifications[i]
        const isTaskType = notif.entityType.search(/task/gi) !== -1
        const isTaskCCTV = notif.source.search(/cctv/gi) !== -1
        const isTaskPerson = notif.source.search(/person/gi) !== -1

        if (!notif.readByTab && isTaskType) {
          if (isTaskCCTV) taskCCTV.notif += 1
          if (isTaskPerson) taskList.notif += 1
        }
      }

      if (taskList.notif > 0) taskList.name = `${taskList.name} (${taskList.notif})`
      if (taskCCTV.notif > 0) taskCCTV.name = `${taskCCTV.name} (${taskCCTV.notif})`

      copyTabs.splice(indexTaskList, 1, taskList)
      copyTabs.splice(indexTaskCCTV, 1, taskCCTV)

      // remove tab cctv rules not available module live-feeds
      if (!hasModuleLiveFeeds) {
        copyTabs = copyTabs.filter(
          tab => tab.name?.search(/cctv alert/gi) === -1 && tab.name?.search(/cctv/gi) === -1
        )
      }

      // remove tab report from citizen not available module qlueapp
      if (!additionalModule.qlueApp) {
        copyTabs = copyTabs.filter(tab => tab.name?.search(/report from citizen/gi) === -1)
      }

      return copyTabs
    }

    return [...tabs]
  }, [additionalModule.qlueApp, hasModuleLiveFeeds, isWorkgroupMenu, notifications, tabs])
  // ---

  useEffect(() => {
    let lastPath = window.location.pathname.split('/').pop()
    const splitedPathByAnalytic = window.location.pathname.split('/analytic/')
    if (splitedPathByAnalytic.length > 1) lastPath = splitedPathByAnalytic[1].split('/')[0]
    tabs.forEach((item, i) => {
      if (lastPath === item.path) setSelectedTab(item.id)
    })
  }, [tabs])

  // specifically fix bug in Tabs of events page, not graceful but works
  useEffect(() => {
    if (
      window.location.pathname === '/events/realtime' ||
      window.location.pathname === '/workgroup/qluework'
    ) {
      setSelectedTab(1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname])

  const highlight = useMemo(() => {
    if (showcase.currentShowcase) {
      return showcase.currentShowcase.useTab === useTab
    }
    return false
  }, [showcase, useTab])

  function onBounding(payload) {
    setBounding(payload)
  }

  const renderTab = () => {
    if (isLoading) {
      return (
        <div className="center w-10 mt6">
          <IconLoading />
        </div>
      )
    }

    if (tabs.length === 0 && !isLoading) {
      return (
        <div className="center w-20 mt6">
          <IconEmptyResult />
        </div>
      )
    }

    return children
  }

  return (
    <TabShellContext.Provider
      value={{
        tabList,
        title,
        setTitle,
        toggleShowBackNav,
        setHideTab,
        detailTab,
        selectedTab,
        setSelectedTab,
        tabs,
        setTabs,
        setReturnPath
      }}
    >
      <>
        {showcase.currentShowcase?.useTab === useTab && <ModalShowcase bounding={bounding} />}
        {!hideTab && (
          <Tab
            tabs={constructTabs}
            title={title}
            hasBackNavigation={backNavigation}
            handleReturn={() => navigate(returnPath)}
            handleTab={onHandleTab}
            selected={selectedTab}
            highlight={highlight}
            onBounding={onBounding}
            {...restProps}
          />
        )}
        {renderTab(tabs, children)}
      </>
    </TabShellContext.Provider>
  )
}

function useNotificationWorkgroup({
  detailTab,
  isWorkgroupMenu,
  onHandleTab,
  tabs,
  notifications
}) {
  const prevLength = usePrevious(notifications.length) || 0
  const hasANewNotification = prevLength !== notifications.length
  const detailData = notifications[0]

  function Message() {
    const activeTab = detailData.source.search(/person/gi) !== -1 ? 4 : 5
    const name =
      detailData.source.search(/person/gi) !== -1
        ? 'New task available from Personnel'
        : 'New task available from CCTV'

    function onClick() {
      onHandleTab(tabs[activeTab - 1])
    }

    return <u className="pointer" onClick={onClick}>{`${name} (${detailData.projectName})`}</u>
  }

  useEffect(() => {
    /**
     * make sure this notification just appears for workgroup
     * and then appears in all sub-tab in workgroup menu
     * except in Task List and Task from CCTV inside in workgroup
     */
    if (detailTab && isWorkgroupMenu && detailData && hasANewNotification) {
      const isNotifCCTV = detailData.source.search(/person/gi) === -1
      const isTabCCTV = detailTab.name.search(/task from cctv/gi) !== -1
      const isTabTaskPerson = detailTab.name.search(/task list/gi) !== -1

      // make sure notification for Task from CCTV appears if  user in tab Task List
      if (isNotifCCTV && !isTabCCTV) createNotification()
      // make sure notification for Task List appears if user in tab Task from CCTV
      else if (!isNotifCCTV && !isTabTaskPerson) createNotification()
    }

    function createNotification(params) {
      alert({
        type: 'primary',
        noAutomateClose: true,
        triggerClose: true,
        msg: <Message />
      })
    }
  }, [detailData, detailTab, hasANewNotification, isWorkgroupMenu])
}

function useStore() {
  const store = useContext(TabShellContext)
  if (!store) throw new Error('Cannot using this store')

  return store
}

export { TabShell, TabShellContext, useStore }
