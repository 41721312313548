export default {
  page: 1,
  pageSize: 20,
  search: '',
  sort: '',
  order: '',
  time: '',
  start: '',
  end: '',
  categoryIds: '',
  type: '',
  status: '',
  priorities: '',
  myTask: ''
}
