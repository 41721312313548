import qs from 'query-string'
import { navigate } from '@reach/router'

const updateQueryParams = newQryParams => {
  if (typeof newQryParams === 'function') {
    newQryParams = {
      ...qs.parse(window.location.search),
      ...newQryParams()
    }
  }

  const updatedQuery = qs.stringify(newQryParams)
  navigate(`?${updatedQuery}`, { replace: true })
}

export default updateQueryParams
