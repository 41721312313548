import React from 'react'
import qs from 'query-string'
import TaskItem from '@qlue/react-component/dist/TaskItem'
import UserProfile from '@qlue/react-component/dist/UserProfile'
import noImage from 'commons/assets/images/no_image.svg'
import cctv from 'commons/assets/images/cctv.png'
import smartDeviceIcon from 'commons/assets/images/smart-device.png'
import { markerWaiting, markerProcess, markerComplete } from 'commons/assets/images'
import LayerTooltip from './Layer/Tooltip'
import { MarkerDiv } from './styles'

export const getBounds = () => {
  const bounds = window.maps.leafletElement.getBounds()
  return {
    neLat: bounds.getNorthEast().lat,
    neLng: bounds.getNorthEast().lng,
    swLat: bounds.getSouthWest().lat,
    swLng: bounds.getSouthWest().lng
  }
}

export const initialBounds = {
  neLat: 67.47597560646741,
  neLng: 167.71514159022294,
  swLat: -42.8095048025746,
  swLng: -156.42548340977714
}

export const getTracking = (data, trackingFilter, search, eventData) => {
  let trackingList = []
  let trackingMarkers = []

  if (data.length > 0) {
    trackingList = data.filter(x => x.name.toLowerCase().includes(search.toLowerCase()))

    // Show only online users
    if (trackingFilter === 'online') {
      trackingList = trackingList.filter(x => x.isOnline)
    }

    // Show only offline users
    if (trackingFilter === 'offline') {
      trackingList = trackingList.filter(x => !x.isOnline)
    }

    trackingMarkers = trackingList.map(({ id, userLocation, nameInitials, name, division }) => {
      const commonMarkers = {
        tooltip: {
          Component: (
            <UserProfile
              avatar={nameInitials}
              avatarAlt="label-avatar"
              title={name}
              avatarSize="medium"
              meta={division.name}
              className="pl3 pr3 pt2 pb2 item"
              titleColor="black"
              isAvatarInitial
            />
          ),
          opacity: 1
        }
      }
      if (eventData.user === id) {
        return {
          position: [eventData.userLocation.lat, eventData.userLocation.lng],
          ...commonMarkers
        }
      }
      return {
        position: [userLocation.lat, userLocation.lng],
        ...commonMarkers
      }
    })
  }

  return { trackingMarkers, trackingList }
}

export const getTask = (data, navigate, mapRef) => {
  const taskMarkers = data.map(
    ({ id, lat, lng, title, media, status, createdAt, category, location, priority }) => {
      const getSrc = () => {
        if (status?.name?.toLowerCase() === 'complete') return markerComplete
        if (status?.name?.toLowerCase() === 'process') return markerProcess
        return markerWaiting
      }
      return {
        position: [lat, lng],
        divIconOptions: {
          html: (
            <MarkerDiv>
              <img src={getSrc()} alt={`marker ${status}`} />
              {category?.imageUrl && (
                <div className="category">
                  <img src={category.imageUrl} alt="category" />
                </div>
              )}
            </MarkerDiv>
          )
        },
        tooltip: {
          Component: (
            <TaskItem
              className="bg-white br4 black-90"
              key={id}
              onClick={() => navigate(id)}
              imgUrl={media.length > 0 ? media[0].thumbnails.medium : noImage}
              status={status.name}
              date={createdAt}
              title={title}
              category={category.name}
              location={location}
              priority={priority.name}
              color="gray"
            />
          ),
          opacity: 1
        },
        onClick: e => {
          mapRef.current.leafletElement.setView([e.latlng.lat, e.latlng.lng])
          navigate(id)
        }
      }
    }
  )
  return taskMarkers
}

export const getLayer = ({ layerCCTV = [], layerDevice = [], navigate }) => {
  const layerCCTVMarkers = [...layerCCTV, ...layerDevice].map(
    ({ id, name, link, engineAlias, address, lat, lng, smartDeviceId }) => ({
      position: [lat, lng],
      divIconOptions: {
        html: <img src={smartDeviceId ? smartDeviceIcon : cctv} alt={`cctv ${name}`} />
      },
      tooltip: {
        Component: (
          <LayerTooltip
            className="bg-white br4 black-90"
            key={id}
            title={name}
            location={address}
            imgUrl={link}
            color="gray"
          />
        )
      },
      onClick: () => {
        if (smartDeviceId) {
          const qry = qs.stringify({ id, name, deviceId: smartDeviceId })
          navigate(`/smartdevices/${engineAlias.toLowerCase()}?${qry}`)
        } else {
          const qry = qs.stringify({ id, name, link })
          navigate(`/livefeeds/${engineAlias.toLowerCase()}?${qry}`)
        }
      }
    })
  )
  return layerCCTVMarkers
}

export const checkLocationPromise = () => {
  return new Promise((resolve, reject) => {
    navigator.permissions
      .query({ name: 'geolocation' })
      .then(function(result) {
        if (result.state === 'granted') {
          resolve(true)
        } else if (result.state === 'prompt') {
          resolve(false)
        } else if (result.state === 'denied') {
          resolve(false)
        }
      })
      .catch(err => {
        reject(false)
      })
  })
}
