const API = process.env.REACT_APP_API_URL_BASE_API

const GetListBioniqaDevice = `${API}/smart-device/bioniqa`
const AddDevice = `${API}/smart-device/bioniqa`
const EditDevice = id => `${API}/smart-device/bioniqa/${id}`
const DeleteDevice = id => `${API}/smart-device/bioniqa/${id}`
const GetAQIStatus = id => `${API}/smart-device/bioniqa/${id}/aqi`
const GetCO2Reduction = id => `${API}/smart-device/bioniqa/${id}/total-co2`
const GetPM25Data = id => `${API}/smart-device/bioniqa/${id}/pm25`
const GetAnalyticDetail = id => `${API}/smart-device/bioniqa/${id}/analytics`
const GetModuleTankDetail = id => `${API}/smart-device/bioniqa/${id}/tank-module`
const GetAnalyticLineBioniqa = `${API}/analytic/bioniqa/line/total-co2`
const GetAnalyticTotalCO2 = `${API}/analytic/bioniqa/total-co2`
const GetAnalyticAverageAQI = `${API}/analytic/bioniqa/average-aqi`
const GetAnalyticTable = `${API}/analytic/bioniqa/table-co2-aqi`
const GetAnalyticBarAQI = `${API}/analytic/bioniqa/line/aqi`

export default {
  GetListBioniqaDevice,
  AddDevice,
  EditDevice,
  DeleteDevice,
  GetAQIStatus,
  GetCO2Reduction,
  GetPM25Data,
  GetAnalyticDetail,
  GetModuleTankDetail,
  GetAnalyticLineBioniqa,
  GetAnalyticTotalCO2,
  GetAnalyticAverageAQI,
  GetAnalyticTable,
  GetAnalyticBarAQI
}
