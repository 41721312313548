const API = 'https://ahm-api.qlue.id/api'

const GetAttendances = `${API}/attendances`
const GetReaders = `${API}/attendances/readers`
const GetAttendanceStatus = `${API}/attendances/attendance-statuses`
const GetShifts = `${API}/attendances/shifts`
const PostDownloadList = `${API}/attendances/download-sheet`

const GetAttendancesRealtime = `${API}/real-time-attendances`
const GetAttendancesRealtimeBySearch = `${API}/real-time-attendances/attendances`
const GetReadersRealtime = `${API}/real-time-attendances/readers`

export default {
  GetAttendances,
  GetAttendanceStatus,
  GetShifts,
  GetReaders,
  PostDownloadList,
  GetAttendancesRealtime,
  GetAttendancesRealtimeBySearch,
  GetReadersRealtime
}
