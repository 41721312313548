import API, { AuthURL } from 'commons/API'
import { getToken, saveLogin } from 'utils/auth'
import { getDefaultPath } from 'pages/Login/stores/action'

export default async function getNewToken({ setModalExpired }) {
  try {
    const res = await API.post(AuthURL.GetTokenFromRefreshToken, {
      userId: getToken().userId,
      refreshToken: getToken().refreshToken
    })
    const { authorization } = res.headers
    const { user } = res.data

    API.interceptors.request.use(config => {
      config.headers.authorization = authorization
      return config
    })

    getDefaultPath(user.roleId).then(module => {
      saveLogin(
        {
          ...getToken(),
          token: authorization,
          refreshToken: user.refreshToken,
          defaultPath: module[0]?.path || 'profile/plan'
        },
        !!localStorage.getItem('not_rem_me')
      )
      if (user.companyExpired) setModalExpired(true)
      else window.location.href = window.location.pathname
    })
  } catch (e) {
    setModalExpired(true)
  }
}
