import styled from 'styled-components'
import { IconButton as ButtonIcon } from '@qlue/react-component/dist/QlueButton'

export const ButtonWrapper = styled(ButtonIcon)`
  display: flex;
  width: 100%;
  justify-content: center;

  svg {
    cursor: pointer;
    margin-right: 8px;
    width: 17px;
    height: 17px;
  }
`
