const API = process.env.REACT_APP_API_URL_BASE_API

const GetAnalytics = `${API}/plate/analytics`
const GetDetailAnalytics = `${API}/plate/analytics/detail`
const GetInitSnapshot = `${API}/plate/count/`
const GetListHistory = `${API}/plate/history`
const DownloadHistory = `${API}/plate/history/download`

export default {
  GetAnalytics,
  GetDetailAnalytics,
  GetInitSnapshot,
  GetListHistory,
  DownloadHistory
}
