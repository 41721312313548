const API = process.env.REACT_APP_API_URL_BASE_API

const GetTrafficMode = id => `${API}/intersection/${id}/traffic-mode`
const GetDensity = id => `${API}/intersection/${id}/density`
const GetDate = id => `${API}/intersection/${id}/date`
const GetAnalyticFeed = id => `${API}/intersection/${id}/analytics/per-feed`
const GetAnalyticVehicle = id => `${API}/intersection/${id}/analytics/per-vehicle-type`
const GetAnalyticVehicleById = (id, feedId) =>
  `${API}/intersection/${id}/${feedId}/analytics/per-vehicle-type`

export default {
  GetTrafficMode,
  GetDensity,
  GetDate,
  GetAnalyticFeed,
  GetAnalyticVehicle,
  GetAnalyticVehicleById
}
