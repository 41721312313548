import { useEffect, useState, lazy } from 'react'
import { titleCase } from 'utils'

/**
 * Get default Query Params from path
 *
 * @param {String} path
 */
const getDefaultQueryParams = ({ path, ignoreQueryParamsFile }) => {
  if (ignoreQueryParamsFile) return {}
  else {
    return import(`../../pages${path}/queryParams`)
      .then(m => m.default)
      .catch(e => console.warn(e, 'queryParams not found'))
  }
}

/**
 * Use this function to import page from given pathname
 * and remove dynamicSegments from router to match file path
 *
 * @param {String} pathname
 * @param {Object} dynamicSegments
 */
const useImportPage = (pathname, dynamicSegments = {}) => {
  const [page, setPage] = useState({ Component: null, queryParams: {} })
  const { customTarget, ignoreQueryParamsFile = false } = dynamicSegments

  delete dynamicSegments['*']

  /**
   * WIP : Simplify logic to getFilePath
   */

  /**
   * UpperCase after slash in pathname
   *
   * From :
   * ./analytic/qluework
   * Into :
   * ./Analytic/Qluework
   *
   * @param {String} str
   */
  const upperCaseSlash = str =>
    str
      .split('/')
      .map(x => titleCase(x))
      .join('/')

  /**
   * UpperCase after hyphen in pathname
   *
   * From :
   * ./analytic/qluework/average-completion-time
   *
   * Into :
   * ./analytic/qluework/AverageCompletionTime
   *
   * @param {String} str
   */
  const upperCaseHyphen = str =>
    str
      .split('-')
      .map(x => titleCase(x))
      .join('')

  /**
   * Remove Segments in pathname
   *
   * From :
   * ./analytic/qluework/workgroup/:workgroupId
   *
   * Into :
   * ./analytic/qluework/workgroup
   *
   * @param {String} str
   */
  const dynamicSegment = str => {
    const findDynamicSegment = string => Object.values(dynamicSegments).find(x => x === string)
    return str
      .split('/')
      .map(x => x.replace(findDynamicSegment(x), 'DynamicPath'))
      .join('/')
  }

  /**
   * Replace last path with `customTarget`
   *
   * from :
   * ./analytic/qluework/workgroup/
   *
   * Into :
   * ./analytic/qluework/CustomWorkgroup
   * @param {String} str
   */
  const replaceLastPath = str => {
    if (customTarget) {
      const splitStr = str.split('/')
      splitStr[splitStr.length - 1] = customTarget

      return splitStr.join('/')
    }

    return str
  }

  const getFile = replaceLastPath(upperCaseHyphen(upperCaseSlash(dynamicSegment(pathname))))
  const getFilePath = getFile[0] !== '/' ? `/${getFile}` : getFile

  useEffect(() => {
    ;(async () => {
      const Component = lazy(() =>
        import(`../../pages${getFilePath}`)
          .then(comp => comp)
          .catch(e => console.warn(e, 'Module not found'))
      )
      const queryParams = await getDefaultQueryParams({ path: getFilePath, ignoreQueryParamsFile })
      setPage({ Component, queryParams })
    })()
  }, [customTarget, getFilePath, ignoreQueryParamsFile])

  return page
}

export default useImportPage
