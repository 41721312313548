import { combineReducers } from 'redux'

import login from 'pages/Login/stores/reducer'
import module from 'shells/AppShell/stores/reducer'
import companyDetail from './companyDetail'
import notification from './notification'

const rootReducer = combineReducers({ login, module, companyDetail, notification })

export default rootReducer
