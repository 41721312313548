import { format } from 'date-fns'

const DEFAULT_MONTH = Number(format(new Date(), 'MM'))
const DEFAULT_YEAR = Number(format(new Date(), 'yyyy'))

export default {
  month: DEFAULT_MONTH,
  year: DEFAULT_YEAR,
  search: '',
  sort: 1
}
