const API = process.env.REACT_APP_API_URL_BASE_API

const AssignPassword = `${API}/user/password`
const GetUserList = `${API}/user`
const ResendEmailActivation = `${API}/user/resendEmailActivation`
const UserURL = `${API}/user/`
const DeleteUsers = `${API}/user/delete`
const DownloadDataUser = `${API}/user/download`
const GetUserTemplate = `${API}/user/template`
const UploadUsersBulk = `${API}/user/bulk`
const RequestUser = `${API}/user/request`

export default {
  AssignPassword,
  GetUserList,
  ResendEmailActivation,
  UserURL,
  DeleteUsers,
  DownloadDataUser,
  GetUserTemplate,
  UploadUsersBulk,
  RequestUser
}
