import '@qlue/react-component/dist/styles.css'

import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Router, Redirect } from '@reach/router'
import { useSelector } from 'react-redux'
import getRoutes, { reMapRoutes } from './routes'
import { publicRoutes, privateRoutes } from './routes/route'
import { LoginShell, AppShell, ActivationShell, BlankShell } from 'shells'
import { ArrayMap } from '@qlue/react-component/dist/utils'
import Toast from '@qlue/react-component/dist/Toast'
import ModalAlert from 'utils/ModalAlert'

const WrapperToast = styled.div`
  position: fixed;
  display: grid;
  row-gap: 10px;
  left: ${props => (props.isPrivate ? 'calc(240px + 2em)' : '2em')};
  bottom: 2em;
  z-index: 10002;
`

function App() {
  const publicRoute = getRoutes(publicRoutes, 'public')
  const privateRoute = getRoutes(reMapRoutes(privateRoutes), 'private')
  const t = useSelector(({ login }) => login.token)
  const authIsLoaded = t?.token
  const pathName = window.location.pathname

  const component = React.useMemo(() => {
    if (pathName.search(/activation-/i) !== -1) return ActivationShell
    else if (pathName === '/session') {
      return BlankShell
    }
    //special route for check login session from CF to reduce main chunck size
    else if (authIsLoaded && pathName !== '/session') return AppShell
    else return LoginShell
  }, [authIsLoaded, pathName])
  const token = useSelector(({ login }) => login.token)
  const [toasts, setToasts] = useState([])

  useEffect(() => {
    window.alert = val => {
      let dataToast = { msg: '', type: 'success', noAutomateClose: false, triggerClose: false }

      if (typeof val === 'string') dataToast.msg = val
      else dataToast = val

      setToasts(item => [...item, dataToast])
    }

    window.alert.error = msg => {
      setToasts(item => [...item, { type: 'failed', msg }])
    }
  }, [])

  return (
    <>
      <ModalAlert />
      <WrapperToast isPrivate={!!token}>
        <ArrayMap data={toasts}>{item => <Toast {...item} />}</ArrayMap>
      </WrapperToast>
      <Router>
        <ConditionWrapper path="/" component={component}>
          {publicRoute}
          {privateRoute}
          <Redirect from="*" to={t?.defaultPath || 'analytic'} noThrow />
        </ConditionWrapper>
      </Router>
    </>
  )
}

function ConditionWrapper({ component, children, ...props }) {
  const Comp = component
  return <Comp {...props}>{children}</Comp>
}

export default React.memo(App)
