import ProgressBar from '@qlue/react-component/dist/ProgressBar'
import { IconArrow, IconClose } from '@qlue/react-component/dist/QlueIcon'
import theme from '@qlue/react-component/dist/styles/theme'
import React, { useState } from 'react'
import { navigate } from '@reach/router'
import iconCheck from './media/check.png'
import Styled from './styles'
import { UNSHOW_REQUESTS_VALUE } from 'shells/AppShell/locals/constants'

function RequestProgress({ requests, dispatch }) {
  const [hide, setHide] = useState(false)

  const filteredRequests = requests.filter(
    ({ loading, finish, error, show }) => show && (loading || finish || error)
  )

  const finishedRequests = requests.filter(({ finish, show }) => show && finish)

  const loadingRequests = requests.filter(({ loading }) => loading)

  function onClickArrow() {
    setHide(prev => !prev)
  }

  function onClickClose() {
    dispatch({ type: UNSHOW_REQUESTS_VALUE })
  }

  function onClickItem({ link }) {
    navigate(link)
  }

  function getTitle() {
    if (loadingRequests.length) return `Uploading ${loadingRequests.length} item`
    return `${finishedRequests.length} uploads complete`
  }

  if (!filteredRequests.length) return ''

  return (
    <Styled.Wrapper hide={hide}>
      <div className="header">
        <div>{getTitle()}</div>
        <div className="icons">
          <div className="arrow-bottom" onClick={onClickArrow}>
            <IconArrow />
          </div>
          {!loadingRequests.length && (
            <div className="icon-close" onClick={onClickClose}>
              <IconClose />
            </div>
          )}
        </div>
      </div>
      {!hide && (
        <div className="list-request">
          {filteredRequests.map(
            ({ id, name, additionalName = '', progress, finish, error, link }) => (
              <Styled.Item
                key={id}
                onClick={() => onClickItem({ link })}
                isLoading={!finish || error}
              >
                <div className="item-name">{`${name} ${additionalName}`}</div>
                <div className="progress-container">
                  {!finish || error ? (
                    <ProgressBar
                      className="mb1"
                      height="9px"
                      percentage={progress}
                      color={error ? theme.waitStatusColor : theme.primaryColor}
                    />
                  ) : (
                    <div className="icon-check">
                      <img src={iconCheck} alt="check" />
                    </div>
                  )}
                </div>
              </Styled.Item>
            )
          )}
        </div>
      )}
    </Styled.Wrapper>
  )
}

export default RequestProgress
