import styled, { css } from 'styled-components'
import theme from '@qlue/react-component/dist/styles/theme'
import { BaseButton as Button } from '@qlue/react-component/dist/QlueButton'

export default {
  FirstHeader: styled.div`
    background-color: ${theme.primaryBackground};
    display: flex;
    align-items: center;
    ${props =>
      props.onlyIcon &&
      css`
        justify-content: center;
      `};

    > img {
      height: 24px;
      width: ${props => (props.onlyIcon ? '24px' : '151px')};
      ${props =>
        !props.onlyIcon &&
        css`
          margin-left: 26px;
        `};
    }
  `,
  MainHeader: styled.div`
    position: relative;
    background-color: ${theme.primaryBackground};
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > .right-side {
      display: grid;
      grid-template-columns: 1fr 60px 20px;
      gap: 28px;
      align-items: center;

      > .notification {
        position: relative;
        cursor: pointer;
        margin-right: 24px;

        > .dink {
          position: absolute;
          width: 10px;
          height: 10px;
          top: 0;
          right: -22px;
          background: ${theme.primaryColor};
          border: 1px solid ${theme.primaryBackground};
          border-radius: 100%;
        }
      }

      .icon-button-notif {
        width: 20px;
        height: 20px;
        cursor: pointer;
      }

      .icon-button-help {
        display: flex;
        align-items: center;
        cursor: pointer;

        > .text {
          margin-left: 4px;
        }
        > img {
          width: 24px;
          height: 24px;
        }

        ${props =>
          props.highlight &&
          css`
            position: relative;
            z-index: 999999;
            pointer-events: none;
          `};
      }
    }
  `,
  ExpiryWrapper: styled.div`
    display: flex;
    align-items: center;

    > div.ball {
      width: 16px;
      height: 16px;
      border-radius: 100%;
      background-color: ${theme.primaryTextColor};
    }

    > span.text-expiry {
      margin: 0 16px;
    }
  `,
  Button: styled(Button)`
    border-radius: 0;
    box-shadow: unset;
    z-index: 100;

    :hover {
      box-shadow: unset;
      border: 1px solid ${theme.primaryBackground};
    }

    ${props =>
      props.hide &&
      css`
        visibility: hidden;
      `}
  `
}
