/**
 * Initial loading of the reducer state.
 *
 * @param {Object} state Current state.
 * @return {Object} New state.
 */
export const getInitReducerState = state => ({
  ...state,
  isLoaded: false,
  isLoading: false,
  hasError: false,
  error: null
})

/**
 * Starts loading of the reducer state.
 *
 * @param {Object} state Current state.
 * @return {Object} New state.
 */
export const getRequestReducerState = state => ({
  ...state,
  isLoaded: false,
  isLoading: true,
  hasError: false,
  error: null
})

/**
 * Finishes loading of the reducer state.
 *
 * @param {Object} state Current state.
 * @return {Object} New state.
 */
export const getSuccessReducerState = state => ({
  ...state,
  isLoaded: true,
  isLoading: false,
  hasError: false,
  error: null
})

/**
 * Throws an error to the reducer state.
 *
 * @param {Object} state Current state.
 * @return {Object} New state.
 */
export const getFailedReducerState = error => ({
  isLoaded: false,
  isLoading: false,
  hasError: true,
  error
})
