import theme from '@qlue/react-component/dist/styles/theme'

const priorityColor = {
  none: '#a97a62',
  low: '#F5AD4E',
  medium: '#53A4E4',
  high: '#549E5B'
}

const statusColor = {
  waiting: theme.waitStatusColor,
  wait: theme.waitStatusColor,
  process: theme.processStatusColor,
  complete: theme.completeStatusColor,
  done: theme.completeStatusColor
}

export default { priorityColor, statusColor }
