import styled from 'styled-components'
import theme from '@qlue/react-component/dist/styles/theme'

const LayerContainer = styled.div`
  height: auto;
  background-color: ${props =>
    props.cardHovered ? theme.primaryBackgroundLightest : theme.primaryBackground};
  padding: 12px 17px;
  cursor: pointer;
  border-bottom: 2px solid ${theme.primaryBackgroundLightest};
`

const LayerContent = styled.div`
  display: flex;
  height: auto;

  .content-img {
    height: 80px;
    width: 80px;
    margin-top: 6px;
    border-radius: 4px;
  }

  .video-thumbnail {
    position: relative;
    display: inline-block;
    cursor: pointer;
    height: ${props => (props.notVcc ? '100px' : '80px')};
    ${props => props.imgHeight && `height: ${props.imgHeight}`};
    ${props => props.notVcc && 'width: 100px'};
    padding: 0px 6px 0px 0px;
    min-width: 80px;

    > img {
      object-fit: cover !important;
      cursor: pointer;
      border-radius: 4px;
    }
  }
`

export { LayerContainer, LayerContent }
