export const generateElementID = (...args) => {
  return args.join('-')
}

export const LOGIN_PAGE_ID_PREFIX = 'lgpage'
export const EVENT_PAGE_ID_PREFIX = 'evpage'
export const SMART_CCTV_PAGE_ID_PREFIX = 'evpage'
export const WORKGROUP_PAGE_ID_PREFIX = 'workgrouppage'
export const ANALYTICS_PAGE_ID_PREFIX = 'analytic'
export const VIEW_PROFILE_PAGE_ID_PREFIX = 'viewprofilepage'
export const MAP_PAGE_ID_PREFIX = 'mappage'
export const SETTING_PAGE_ID_PREFIX = 'settingpage'
export const MENU_PAGE_ID_PREFIX = 'menupage'

export const ALERT_RULES_ID_PREFIX = generateElementID(EVENT_PAGE_ID_PREFIX, 'ar')

export const CREATE_ALERT_ID_PREFIX = generateElementID(ALERT_RULES_ID_PREFIX, 'ca')
export const CREATE_ALERT_2_ID_PREFIX = generateElementID(ALERT_RULES_ID_PREFIX, 'ca2')

export const SETTING_CATEGORY_PREFIX = generateElementID(SETTING_PAGE_ID_PREFIX, 'category')
export const SETTING_USER_PREFIX = generateElementID(SETTING_PAGE_ID_PREFIX, 'user')
export const SETTING_SMART_CCTV_PREFIX = generateElementID(SETTING_PAGE_ID_PREFIX, 'smartcctv')
export const SETTING_ENROLLMENT_PREFIX = generateElementID(SETTING_PAGE_ID_PREFIX, 'enrollment')

export const SETTING_USER_POPUP_PREFIX = generateElementID(SETTING_USER_PREFIX, 'popup')
export const SETTING_USER_EDIT_PREFIX = generateElementID(SETTING_USER_PREFIX, 'edituser')
export const SETTING_CHOOSE_CATEGORY_PREFIX = generateElementID(SETTING_CATEGORY_PREFIX, 'choose')
export const SETTING_SMART_CCTV_ADD_NEW_PREFIX = generateElementID(
  SETTING_SMART_CCTV_PREFIX,
  'addnew'
)
export const SETTING_SMART_CCTV_ACTION_PREFIX = generateElementID(
  SETTING_SMART_CCTV_PREFIX,
  'action'
)
export const SETTING_ENROLLMENT_CARD_DETAIL_PREFIX = generateElementID(
  SETTING_ENROLLMENT_PREFIX,
  'carddetail'
)
export const SETTING_ENROLLMENT_ADD_NEW_PREFIX = generateElementID(
  SETTING_ENROLLMENT_PREFIX,
  'addnew'
)

export const SETTING_USER_POPUP_CREATENEW_PREFIX = generateElementID(
  SETTING_USER_POPUP_PREFIX,
  'createnew'
)
export const SETTING_SMART_CCTV_ACTION_EDIT_PAGE_PREFIX = generateElementID(
  SETTING_SMART_CCTV_ACTION_PREFIX,
  'editpage'
)
